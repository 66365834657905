import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { imgApp, imgNews, imgSearch, imgSocial, imgSport, imgStock, imgUtility, imgRemove, imgAddWhite } from './assets';
interface SelectionSetSetting {
  data: {
    id: string;
    type: string;
    attributes: {
      brightness: number;
      screen_time: number;
      dollar_difference: boolean;
      currency_type: string;
      weather_unit: string;
      selection_set_id: number;
    };
  };
}

interface SelectionSetSchedule {
    data:Array<{id: string;
    type: string;
    attributes: {
      day: string;
      start_time: string;
      end_time: string;
      selection_set_id: number;
      activated: boolean;
    }}>
}

interface ValidResponseType {
  message: object;
  data: object;
  errors: Array<string>;
  error: string;
}

export interface CountryData {
    id: number;
    emoji_flag: string;
    currency_code: string;
    text: string;
}

export interface SelectionSet {
  id: string;
  type: string;
  attributes: {
    name: string;
    panel_id: number;
    account_id: number;
    default_selection: boolean;
    selection_set_items: {
      data: SelectionSetItem[];
  }; 
  };
}

export type ButtonClickHandler = () => void;

interface CountryDataResponse {
  data: Array<CountryData>;
}

interface GlanceDetailsInterface{
    data: {
        id: string;
        type: string;
        attributes: {
            name: string;
            mac_address: string;
        }
    };
}

interface BeachDataResponse {
    beach_data: Array<string>;
}

interface LanguageDataResponse {
  lang_data: Array<string>;
}

export interface SelectionSetItem {
  id: string;
  type: string;
  attributes: {
      selection_type: string;
      key: string;
      name: string | null;
      status: string;
      image: string;
      selection_set_id: string | number;
  };
  selectionSetId?: number;
}

interface SelectionSetResponse {
  data: SelectionSet[];
}

interface SearchSelectionSetRes {
  data: Array<SelectionSetItem>;
}

export interface SearchedLanguage {
   language: string;
   languageIndex: number;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  meEmail: string;
  meAvatar: null | string;
  firstLogin: boolean;
  step: number;
  macAddressModalText: string;
  glancePanels: any;
  cardHolderName: string;
  creditCard: string;
  expiryDate: string;
  cvv: string;
  upgradeModal: boolean;
  paymentModal: boolean;
  glanceNameText: string;
  isExpanded: boolean;
  openDropdownUser: boolean;
  validationError: any;
  group: string;
  tab: string;
  stockModal: boolean;
  stockElements: any;
  cryptoElements: any;
  standingsElement: any;
  gamesElement: any;
  teamsElement: any;
  searchInput: any;
  filteredData: any;
  inputChars: any;
  settingsModal: any;
  glanceId: string;
  glanceName: any;
  glanceMac: any;
  selectionSetsForPanel: any;
  selectionSetModal: boolean,
  selectionSetModalID: any;
  selectionName: string;
  choosenElements: any;
  actions: number;
  choosenElementsOnDetailsPage: any;
  calendarModal: boolean;
  selectionSetBrightness: number;
  selectionSetScreenTime: number;
  selectionSetDolarDifference: boolean;
  selectionSetCurrencyType: string;
  selectionSetWeatherUnit: string;
  selectionSetSettingsModalID: string | number | undefined | null;
  selectionsSetsToApply: Array<number>;
  selectionsSetsAmount: number;
  imagesUrls: Array<string>;
  scheduleDropdown: boolean;
  scheduleType: string;
  timeDropdown: boolean;
  timeSelect: boolean;
  timeSelectEnd: boolean;
  startTimeHours: string;
  startTimeMinutes: string;
  endTimeHours: string;
  endTimeMinutes: string;
  fullTimeStart: string;
  fullTimeEnd: string;
  daysArray: any;
  scheduleID: any;
  socialData: any;
  freeText: string;
  socialDataYt: string;
  socialDataTt: string;
  socialDataIn: string;
  freeTextColor: string;
  countdownColor: string;
  countdownName: string;
  countdownDate: string;
  ledSettingModal: boolean;
  selectedUnit: string;
  sliderValueTimer: number;
  sliderBrightnessValue: number;
  isActiveCurrency: boolean;
  ledScheduleDropdown: boolean;
  ledScheduleType: string;
  ledScheduleDaysArray: Array<string>;
  ledTimeDropdown: boolean;
  ledTimeStartModal: boolean;
  ledTimeEndModal: boolean;
  ledTimeEndHours: string;
  ledTimeEndMinutes: string;
  ledTimeStartHours: string;
  ledTtimeStartMinutes: string;
  ledFullTimeStart: string;
  ledFullTimeEnd: string;
  ledSelectSelectionSet: string | unknown;
  selectedCurrencyType: string | unknown;
  currencyCodes: Array<CountryData>;
  selectedModalCurrency: string | unknown;
  applyToPanelModal: boolean;
  macAddressValidation: boolean;
  glanceNameEditable: boolean;
  editPanelNameSuccessModal: boolean;
  beaches: Array<string>;
  languages: Array<string>;
  selectionSetNews: Array<string>;
  editSelectionSetNameModal: boolean;
  selectedSelectionSetId: number;
  editableSelectionSetName: string;
  languageModal: boolean;
  selectedLanguageId: number;
  searchedLanguages: Array<SearchedLanguage>;
  searchLanguageValue: string;
  selectionSetId: number;
  selctionSetSearch: Array<SelectionSetItem>;
  selectionsetSearchVal: string;
  setSearchAction: number;
  showPausebtn:boolean;
  setPanelId:string | number
  responseErrormsg:any
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getLedSelectionSetSetting: string = "";
  getLedSelectionSetSchedule: string = "";
  putLedSelectionSetSetting: string = "";
  postLedSelectionSetSchedule: string = "";
  applyToPanelSelectionSetApiCallId: string = "";
  editGlanceNameApiCallId: string = "";
  // 0. Dodajemy id zapytania do rozrozniania, ktore zapytanie teraz
  // receive ma procesowac
  apiGetUserInfo: string = "";
  apiGetUserGlanceList: string = "";
  glnceNameInputRef: React.RefObject<HTMLInputElement>;
  getSelectionSetLanguageApiCallId: string = "";
  getSelectionSetBeachesApiCallId: string = "";
  updateSelectionSetNameApiCallId: string = "";
  setLearnLanguageApiCallId: string = "";
  getSelectionSetApiCallId: string = "";
  getSelectionSetItemsApiCallId: string = "";
  setSocialTikTokApiCallId: string = "";
  setSocialYoutubeApiCallId: string = "";
  setSocialInstaApiCallId: string = "";
  selctionSetSearchId: string = "";
  fetchChoosenElementId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.sendAddSelectionSetIdProps)
    ];
    this.setActions = this.setActions.bind(this);
    this.setScheduleDropdown = this.setScheduleDropdown.bind(this);
    this.setScheduleType = this.setScheduleType.bind(this);
    this.setTimeDropdown = this.setTimeDropdown.bind(this);
    this.setTimeSelect = this.setTimeSelect.bind(this);
    this.setTimeSelectEnd = this.setTimeSelectEnd.bind(this);
    this.setStartTimeHours = this.setStartTimeHours.bind(this);
    this.setStartTimeMinutes = this.setStartTimeMinutes.bind(this);
    this.setEndTimeHours = this.setEndTimeHours.bind(this);
    this.setEndTimeMinutes = this.setEndTimeMinutes.bind(this);
    this.setFullTimeStart = this.setFullTimeStart.bind(this);
    this.setFullTimeEnd = this.setFullTimeEnd.bind(this);
    this.setCalendarModal = this.setCalendarModal.bind(this)
    this.setDays = this.setDays.bind(this)
    this.setScheduleID = this.setScheduleID.bind(this)
    this.setFreeText = this.setFreeText.bind(this);
    this.setSocialYt = this.setSocialYt.bind(this)
    this.setSocialTt = this.setSocialTt.bind(this)
    this.setSocialIn = this.setSocialIn.bind(this)
    this.setFreeTextColor = this.setFreeTextColor.bind(this)

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      meEmail: "",
      meAvatar: null,
      firstLogin: false,
      step: 10,
      macAddressModalText: '',
      glanceNameText: '',
      glancePanels: [],
      cardHolderName: '',
      creditCard: '',
      expiryDate: '',
      cvv: '',
      upgradeModal: false,
      paymentModal: false,
      isExpanded: true,
      openDropdownUser: false,
      validationError: false,
      group: 'trading',
      tab: 'stock',
      stockModal: false,

      stockElements: [],
      cryptoElements: [],
      standingsElement: [],
      gamesElement: [],
      teamsElement: [],
      searchInput: [],
      filteredData: [],
      inputChars: 0,
      settingsModal: false,
      glanceId: '',
      glanceName: '',
      glanceMac: '',
      selectionSetsForPanel: [],
      selectionSetModal: false,
      selectionSetModalID: '',
      selectionName: '',
      choosenElements: [],
      actions: 0,
      choosenElementsOnDetailsPage: [],
      calendarModal: false,
      selectionSetBrightness: 7,
      selectionSetScreenTime: 3,
      selectionSetDolarDifference: false,
      selectionSetCurrencyType: 'USD',
      selectionSetWeatherUnit: 'fahrenheit',
      selectionSetSettingsModalID: '',
      selectionsSetsToApply: [],
      selectionsSetsAmount: 1,
      imagesUrls: [],
      scheduleDropdown: false,
      scheduleType: 'mon-fri',
      timeDropdown: true,
      timeSelect: false,
      timeSelectEnd: false,
      startTimeHours: '1',
      startTimeMinutes: '1',
      endTimeHours: '1',
      endTimeMinutes: '1',
      fullTimeStart: '01:01',
      fullTimeEnd: '01:01',
      daysArray: [],
      scheduleID: '',
      socialData: {yt: '', in: '', tt: ''},
      freeText: '',
      socialDataYt: '',
      socialDataTt: '',
      socialDataIn: '',
      freeTextColor: 'r',
      countdownColor: 'r',
      countdownName: '',
      countdownDate: '',
      ledSettingModal:false,
      selectedUnit:"fahrenheit",
      sliderValueTimer:3,
      sliderBrightnessValue:7,
      isActiveCurrency:false,
      ledScheduleDropdown: false,
      ledScheduleType:"mon-fri",
      ledScheduleDaysArray: [],
      ledTimeDropdown: false,
      ledTimeStartModal:false,
      ledTimeEndModal:false,
      ledTimeEndHours:"1",
      ledTimeEndMinutes:"1",
      ledTimeStartHours:"1",
      ledTtimeStartMinutes:"1",
      ledFullTimeStart: '01:01',
      ledFullTimeEnd: '01:01',
      ledSelectSelectionSet:"",
      selectedCurrencyType:"USD",
      currencyCodes:[
        {
          id:1,
          emoji_flag:"🇺🇸",
          currency_code:"USD",
          text:'USD-"Curr-USD"',
        },
        {
          id:2,
          emoji_flag:"🇨🇦",
          currency_code:"CAD",
          text:'CAD-"Curr-CAD"',
        },
        {
          id:3,
          emoji_flag:"🇩🇪",
          currency_code:"EUR",
          text:'EUR-"Curr-EUR"',
        },
        {
          id:4,
          emoji_flag:"🇬🇧",
          currency_code:"GBP",
          text:'GBP-"Curr-GBP"',
        },
        {
          id:5,
          emoji_flag:"🇦🇺",
          currency_code:"AUD",
          text:'AUD-"Curr-AUD"',
        },
        {
          id:6,
          emoji_flag:"🇳🇿",
          currency_code:"NZD",
          text:'NZD-"Curr-NZD"',
        },
      ],
      selectedModalCurrency:"USD",
      applyToPanelModal:false,
      macAddressValidation: false,
      glanceNameEditable: false,
      editPanelNameSuccessModal: false,
      beaches: [],
      languages: [],
      selectionSetNews : ["World News","Finance News","Sports News","IPO Calendar"],
      editSelectionSetNameModal: false,
      selectedSelectionSetId: 0,
      editableSelectionSetName: "",
      languageModal: false,
      selectedLanguageId: 0,
      searchedLanguages: [],
      searchLanguageValue: "",
      selectionSetId: 0,
      selctionSetSearch: [],
      selectionsetSearchVal: "",
      setSearchAction: 0,
      showPausebtn:false,
      setPanelId:"",
      responseErrormsg:""
    };
    this.glnceNameInputRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    setTimeout(() => {
      this.getUserInfo('userInfo');
    }, 2)

    this.getUserGlanceList();
    console.log(imgApp, imgNews, imgSearch, imgSocial, imgSport, imgStock, imgUtility, imgRemove, imgAddWhite)
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log('messagE: ' +JSON.stringify(message))
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if(JSON.stringify(webResponseJson).includes('Invalid token')) {
        localStorage.removeItem('authToken')
        this.props.navigation.navigate('EmailAccountLoginBlock')
      }

      if(message.properties.RestAPIResponceDataMessage === 'getUserGlanceList') {
        this.setState({
          glancePanels: webResponseJson.data,
        })
      }

      if(message.properties.RestAPIResponceDataMessage === 'updateFirstLogin') {
        this.setState({
          firstLogin: false
        })
      }
      
      if(JSON.stringify(webResponseJson).includes('email')) {
        this.setState({
          meEmail: webResponseJson.data.attributes.email,
          meAvatar: webResponseJson.data.attributes.user_image,
          step: this.initialStep()
        })
      }


      if(
        message.properties.RestAPIResponceDataMessage === 'addUserGlance' && 
        JSON.stringify(webResponseJson).includes('"type":"panel"') &&
        this.state.step === 1
      ) {
        this.setState({
          step: 2,
        })
        this.getUserGlanceList()
      }

      if(message.properties.RestAPIResponceDataMessage === 'addPageGlance') {
        this.props.navigation.navigate('Dashboard')
      }
      this.extendRecevieFunction(webApiRequestCallId, webResponseJson);
      this.extendRecevieTwoFunction(webApiRequestCallId, webResponseJson);
      this.extendRecevieThreeFunction(webApiRequestCallId, webResponseJson);
      this.extendRecevieFourFunction(webApiRequestCallId, webResponseJson);
      this.socialScuccessCallBack(webApiRequestCallId, webResponseJson);
      this.selectionSetSearchCallBak(webApiRequestCallId, webResponseJson);
      this.extendRecevieFiveFunction(webApiRequestCallId, webResponseJson);
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let navData = message.getData(getName(MessageEnum.sendAddSelectionSetIdProps));
      this.setState({
        glanceId: navData.glancePanelId,
        selectionSetId: navData.selectionSetId
      }, () => {
        this.fetchElements();
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start

  
  setMacModal(value: string) {
    let updatedValue: string = (value.replace(/[:-]/g, "") || "");
    if(updatedValue.length > 2) {
      updatedValue = updatedValue.match(/.{1,2}/g)!.join(":")!;
    }
    localStorage.setItem("fistLogin", "false")
    this.setState({
      macAddressModalText: updatedValue,
    },()=>{
      if(!this.state.macAddressModalText.match(webConfigJSON.macAddressRegEx)){
        this.setState({
          macAddressValidation : true,
          validationError:this.state.macAddressModalText.length === 0 ? webConfigJSON.pleaseEnterMacAddress : webConfigJSON.pleaseEnterValidMacAddressText
        });
      }else{
        this.setState({
          macAddressValidation : false,
          validationError:" "
        });
      }
    });
  }

  setGlanceName(value: string) {
    this.setState({
      glanceNameText: value
    })
  }

  closeModalUpgrade() {
    this.setState({step: 0})
    this.getUserInfo('firstLogin');
  }

  openUpgradeModal() {
    this.setState({step: 2})
  }

  openPaymentModal() {
    this.setState({step: 3})
    this.getUserInfo('firstLogin');
  }

  setCardHolderName(value: string) {
    this.setState({
      cardHolderName: value
    })
  }

  setCreditCard(value: string) {
    this.setState({
      creditCard: value
    })
  }

  setExpiryDate(value: string) {
    this.setState({
      expiryDate: value
    })
  }

  setCvv(value: string) {
    this.setState({
      cvv: value
    })
  }

  getUserInfo(type: string) {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiGetUserInfo = requestMessage.messageId;
    requestMessage.messageId = type === 'userInfo' ? 'getUserInfo' : 'updateFirstLogin'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type === 'userInfo' ? "account_block/me" : 'account_block/update_login'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getUserGlanceList() {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    
    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiGetUserGlanceList = requestMessageGlance.messageId;
    requestMessageGlance.messageId = 'getUserGlanceList'

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_glance/panels"
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);

    return true
  }

  addUserGlance(type: string = '') {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const body = {
      name: type === 'addPage' ? this.state.glanceNameText : "Initial Panel",
      mac_address: this.state.macAddressModalText
    }
    
    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiGetUserGlanceList = requestMessageGlance.messageId;
    requestMessageGlance.messageId = type === 'addPage' ? 'addPageGlance' : 'addUserGlance'

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      "bx_block_glance/panels"
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true
  }

  getPercentageFun = (percentage: number) => {
    return (percentage * 100)
  }
  getPercentageColor= (type: string) => {
   switch(type) {
    case 'step1':
      return '#D42CD'
    case 'step2':
      return '#F1E51'
    case 'step3':
      return '#F34D8'
    case 'step4':
      return '#36C95'
    }
  }
  getBorderColor(percentage: number) {
    if(percentage < 24) {
      return '#80202'
    }

    if(percentage < 39) {
      return '#db6f5'
    }

    if(percentage < 49) {
      return '#e87d5'
    }

    if(percentage < 74) {
      return '#54a85'
    }

    if(percentage < 81) {
      return '#6bb57'
    }

    if(percentage > 81) {
      return '#06780'
    }
  }

  validateCreditCard(credit: string) {
    const minChar = credit.length > 0
    const haveSeparators = credit.includes(' ')

    if(minChar && haveSeparators) {
      return true
    }
  }

  switchSteps() {
    if(this.state.glancePanels?.length === 0 && this.state.firstLogin === false && localStorage.getItem('fistLogin') === 'true') {  
      return 1
    }

    if(this.state.firstLogin === true && this.state.glancePanels?.length === 0) {
      return 0
    } 

    if(this.state.glancePanels?.length !== 0 && this.state.firstLogin === false) {
      return 2
    }

    if(this.state.glancePanels?.length !== 0 && this.state.firstLogin === true) {
      return 3
    }
  }

  initialStep() {
    if(this.state.glancePanels?.length === 0) { 
      if(localStorage.getItem('fistLogin') === 'true') {
        return 0;
      }
      return 1;
    }

    if(this.state.glancePanels?.length !== 0 && this.state.firstLogin) {
      return 2;
    }

    if(this.state.glancePanels?.length !== 0 && !this.state.firstLogin) { 
      return 0;
    }

    return 0;
  }

  showUpgradeModal = (type: string) => {
    if(type === 'upgrade') {
        this.setState({ 
            upgradeModal: true,
            paymentModal: false,
        })
    }

    if(type === 'payment') {
        this.setState({
            upgradeModal: false,
            paymentModal: true,
        })
    }
  }

  closeUpgradeModal = () => {
      this.setState({
          upgradeModal: false,
          paymentModal: false,
      })
  }

  switchDropdownSection() {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  openDropdownUser() {
    this.setState({
      openDropdownUser: true
    })
  }

  closeDropdownUser() {
    this.setState({
      openDropdownUser: false
    })
  }

  validationError(msg: string) {
    this.setState({
      validationError: msg
    })
  }

  changeGroup(type: string) {
    this.setState({
      group: type
    })
  }

  changeStockCrpyto(type: string) {
    this.setState({
      tab: type
    })
  }

  returnFormattedText(text: string) {
    let formattedText = '';
                      
    for (let i = 0; i < text.length; i++) {
        if (i > 0 && i % 2 === 0) {
            formattedText += ':';
        }
        formattedText += text[i];
    }

    return formattedText;
  }

  showStockModal() {
    this.setState({
      stockModal: true
    })
  }

  closeStockModal = () => {
    this.setState({
      stockModal: false
    })
  }

  setTradingStockElements(elements: any) {
    this.setState({
      stockElements: elements
    })
  }

  setTradingCryptoElements(elements: any) {
    this.setState({
      cryptoElements: elements
    })
  }

  setStandings(elements: any) {
    this.setState({
      standingsElement: elements
    })
  }

  setGames(elements: any) {
    this.setState({
      gamesElement: elements
    })
  }

  setTeams(elements: any) {
    this.setState({
      teamsElement: elements
    })
  }

  setSearchInput(value: string) {
    const dummyData = [
      {name: 'Dashboard', url: 'Dashboard', key: 0},
      {name: 'Add Glance', url: 'AddGlancePage', key: 1},
      {name: 'Settings', url: 'CustomizableUserProfile', key: 2},
      {name: 'User Profile', url: 'CustomizableUserProfile', key: 3},
      {name: 'Billing ', url: 'CustomizableUserProfileBilling', key: 4},
      {name: 'Manage your Glance-Led account', url: 'CustomizableUserProfile', key: 5},
      {name: 'Terms and conditions', url: 'TermsConditionsDetail', key: 6},
      {name: 'Privacy policy ', url: 'TermsConditionsDetail', key: 7},
      {name: 'Contact us', url: 'Contactus', key: 8}
    ]
   
    const searchQuery = value.toLowerCase();
    const result = dummyData.filter((element: any) => element.name.toLowerCase().includes(searchQuery));

    this.setState({
      searchInput: result,
      inputChars: value.length
    })
  }

  setFilteredData(data: any) {
    this.setState({
      filteredData: data
    })
  }

  setInputLength(value: any) {
    this.setState({
      inputChars: value
    })
  }

  setSettingsModal(value: any) {
    this.setState({
      settingsModal: value
    })
  }

  setCalendarModal(value: any) {
    this.setState({
      calendarModal: value
    })
  }

  setGlanceId(value:any) {
    this.setState({
      glanceId: value
    })
  }

  setGlanceNameDetails(value:any) {
    this.setState({
      glanceName: value
    })
  }

  setGlanceMac(value:any) {
    this.setState({
      glanceMac: value
    })
  }

  setSelectionSetsForPanel(value: any) {
    this.setState({
      selectionSetsForPanel: value
    })
  }

  setSelectionSetsModal(value: any) {
    this.setState({
      selectionSetModal: value
    })
  }

  setSelectionSetsModalID(value: any) {
    this.setState({
      selectionSetModalID: value
    })
  }

  setSelectionName(value: string) {
    this.setState({
      selectionName: value
    })
  }

  setChoosenElements(value: any) {
    this.setState({
      choosenElements: value,
      selectionSetId: value[0].attributes?.selection_set_id
    });
  }

  setActions(value: any) {
    this.setState({
      actions: value
    })
  }

  setChoosenElementsOnDetailsPage(value: any) {
    this.setState({
      choosenElementsOnDetailsPage: value
    })
  }

  setSelectionSetBrightness(value: number) {
    this.setState({
      selectionSetBrightness: value
    })
  }

  setSelectionSetScreenTime(value: number) {
    this.setState({
      selectionSetScreenTime: value
    })
  }

  setSelectionSetDollarDifference(value: boolean) {
    this.setState({
      selectionSetDolarDifference: value
    })
  }

  setSelectionSetCurrencyType(value: string) {
    this.setState({
      selectionSetCurrencyType: value,
      selectedModalCurrency:value
    })
  }

  setSelectionSetWeatherUnit(value: string) {
    this.setState({
      selectionSetWeatherUnit: value
    })
  }

  setSelectionSetSettingsModalID(value: string | number | undefined | null) {
    this.setState({
      selectionSetSettingsModalID: value
    })
  }

  setSelectionsToApply(value: Array<number>) {
    this.setState({
      selectionsSetsToApply: value
    })
  }

  setSelectionsSetsAmount(value: number) {
    this.setState({
      selectionsSetsAmount: value
    })
  }

  setImagesUrls(value: Array<string>) {
    this.setState({
      imagesUrls: value
    })
  }

  setScheduleDropdown(value: boolean) {
    console.log(this.state.scheduleDropdown);

    this.setState({
      scheduleDropdown: value
    })
  }

  setScheduleType(value: string) {
    this.setState({
      scheduleType: value
    })
  }

  setTimeDropdown(value: boolean) {
    this.setState({
      timeDropdown: value
    })
  }

  setTimeSelect(value: boolean) {
    this.setState({
      timeSelect: value
    })
  }

  setTimeSelectEnd(value: boolean) {
    this.setState({
      timeSelectEnd: value
    })
  }

  setStartTimeHours(value: string) {
    this.setState({
      startTimeHours: value
    })
  }

  setStartTimeMinutes(value: string) {
    this.setState({
      startTimeMinutes: value
    })
  }

  setEndTimeHours(value: string) {
    this.setState({
      endTimeHours: value
    })
  }

  setEndTimeMinutes(value: string) {
    this.setState({
      endTimeMinutes: value
    })
  }

  setFullTimeStart(value: any) {
    this.setState({
      fullTimeStart: value
    })
  }

  setFullTimeEnd(value: any) {
    this.setState({
      fullTimeEnd: value
    })
  }

  setDays(value: any) {
    this.setState({
      daysArray: value
    })
  }

  setScheduleID(value: any) {
    this.setState({
      scheduleID: value
    }) 
  }

  setFreeText(value: string) {
    this.setState({
      freeText: value
    })
  }

  setSocialYt(value: any) {
    this.setState({
      socialDataYt: value
    })
  }

  setSocialTt(value: any) {
    this.setState({
      socialDataTt: value
    })
  }

  setSocialIn(value: any) {
    this.setState({
      socialDataIn: value
    })
  }
  setFreeTextColor(value: string) {
    this.setState({
      freeTextColor: value
    })
  }

  setCountdownColor(value: string) {
    this.setState({
      countdownColor: value
    })
  }

  setCountdownName(value: string) {
    this.setState({
      countdownName: value
    })
  }

  setCountdownDate(value: string) {
    this.setState({
      countdownDate: value
    })
  }
  
  openLedSettingModal = () =>{
    this.setState({
      ledSettingModal: true
    });
  };

  closeLedSettingModal = () =>{
    this.setState({
      ledSettingModal: false,
      selectedUnit:"fahrenheit",
      sliderValueTimer:3,
      sliderBrightnessValue:7,
      isActiveCurrency:false,
      ledScheduleDropdown: false,
      ledScheduleType:"mon-fri",
      ledScheduleDaysArray: [],
      ledTimeDropdown: false,
      ledTimeStartModal:false,
      ledTimeEndModal:false,
      ledTimeEndHours:"1",
      ledTimeEndMinutes:"1",
      ledTimeStartHours:"1",
      ledTtimeStartMinutes:"1",
      ledFullTimeStart: '01:01',
      ledFullTimeEnd: '01:01',
      ledSelectSelectionSet:"",
      selectedCurrencyType:"USD"
    });
  };

  selectUnit = (value: string) => {
    this.setState({
      selectedUnit: value
    });
  };

  adjustValueToFixedPointsTime = (value: number) => {
    const points = [3, 6, 9, 12];
    const closestPoint = points.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    return closestPoint;
  };

  adjustValueToFixedPoints = (value: number) => {
    const points = [1, 4, 7];
    const closestPoint = points.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    return closestPoint;
  };

  changeScreenTimer = (value:number) => {
    const adjustedValue = this.adjustValueToFixedPointsTime(value);
    this.setState({sliderValueTimer: adjustedValue});
  };

  changeScreenBrightness = (value: number) => {
    const adjustedValue = this.adjustValueToFixedPoints(value);
    this.setState({sliderBrightnessValue:adjustedValue});
  };

  toggleSwitch = () => {
    this.setState({
      isActiveCurrency:!this.state.isActiveCurrency
    });
  };

  toggleScheduleDropdown = () => {
    this.setState({
      ledScheduleDropdown: !this.state.ledScheduleDropdown
    });
  };

  setLedScheduleTypeFunc = (value: string) => {
    this.setState({
      ledScheduleType: value,
      ledScheduleDropdown:false
    });
  };

  setLedScheduleDay = (value: string) => {
    if(this.state.ledScheduleDaysArray.includes(value)) {
      let newDays = this.state.ledScheduleDaysArray.filter((item: string) => item !== value);
      this.setLedDays(newDays);
    }
     else {
      this.setLedDays([...this.state.ledScheduleDaysArray, value]);
    }
  };

  setLedDays(value: Array<string>) {
    this.setState({
      ledScheduleDaysArray: value
    });
  };

  toggleLedTimeDropdown = () => {
    this.setState({
      ledTimeDropdown:!this.state.ledTimeDropdown
    });
  };

  ledTimeSelectStart = (value :boolean) => {
    this.setState({
      ledTimeStartHours:value?this.state.ledTimeStartHours:"",
      ledTtimeStartMinutes:value?this.state.ledTtimeStartMinutes:"",
      ledTimeStartModal: value
    });
  };

  selectModalLedCurrencyCode = (event: { target: { value: string | unknown } }) => {
    const selectedType = event?.target?.value;
    this.setState({
      selectedModalCurrency:selectedType,
    });
  };

  ledTimeSelectEnd = (value: boolean) => {
    this.setState({
      ledTimeEndHours:value?this.state.ledTimeEndHours:"",
      ledTimeEndMinutes:value?this.state.ledTimeEndMinutes:"",
      ledTimeEndModal:value
    });
  };
  
  setLedTimeStartHours = (value: string) => {
    this.setState({
      ledTimeStartHours:value
    });
  };

  setLedTimeStartMinutes = (value: string) => {
    this.setState({
      ledTtimeStartMinutes:value
    });
  };

  setLedTimeEndHours = (value: string) => {
    this.setState({
      ledTimeEndHours:value
    });
  };

  setLedTimeEndMinutes = (value: string) => {
    this.setState({
      ledTimeEndMinutes:value
    });
  };

  ledFormatStartTime = (startHour: string, startMinutes: string) => {
    if(+startHour > 23 || +startMinutes > 59 || +startHour < 0 || +startMinutes < 0 || startHour == "" || startMinutes == "" ){
      alert(webConfigJSON.pleaseFillValidTimeText);
      return
    }
    let startTime: string = "";
    if(startHour.length == 1) {
      startTime = '0'+startHour;
    }

    if(startHour.length == 2) {
      startTime = startHour;
    }

    if(startMinutes.length == 1) {
      startTime = startTime+':0'+startMinutes;
    }

    if(startMinutes.length == 2) {
      startTime = startTime+':'+startMinutes;
    }

    this.setState({
      ledFullTimeStart:startTime
    });
    this.ledTimeSelectStart(false)
  };

  ledFormatEndTime = (endHour: string, endMinutes: string) => {
    if(+endHour > 23 || +endMinutes > 59 || +endHour < 0 || +endMinutes < 0 || endHour == "" || endMinutes == ""){
      alert(webConfigJSON.pleaseFillValidTimeText);
      return
    }
    let endTime:string = "";
    if(endHour.length == 1) {
      endTime = '0'+endHour;
    }

    if(endHour.length == 2) {
      endTime = endHour;
    }

    if(endMinutes.length == 1) {
      endTime = endTime+':0'+endMinutes;
    }

    if(endMinutes.length == 2) {
      endTime = endTime+':'+endMinutes;
    }

    this.setState({
      ledFullTimeEnd:endTime
    });
    this.ledTimeSelectEnd(false);
  };

  selectLedSelectionSet = (event: { target: { value: string | unknown } }) => {
    const value = event.target.value;
    this.setState({
      ledSelectSelectionSet:value
    },()=>{
      this.fetchSelectionSet();
      this.fetchLedSelectionSetSchedule();
    });
  }

  selectLedCurrencyCode = (event: { target: { value: string | unknown } }) => {
    const selectedType = event.target.value;
    this.setState({
      selectedCurrencyType:selectedType,
    });
  };

  fetchSelectionSet = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLedSelectionSetSetting = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.selectionSetSettings}/${this.state.ledSelectSelectionSet}`
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true
  }

  fetchLedSelectionSetSchedule = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLedSelectionSetSchedule = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.selectionSetSchedules}/${this.state.ledSelectSelectionSet}`
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true
  }

  getLedSelectionSetSettingSuc = (responseJson:SelectionSetSetting) => {
    if(responseJson.data){
      const {brightness, screen_time, dollar_difference, currency_type, weather_unit} = responseJson.data.attributes;
      this.setState({
        sliderBrightnessValue: brightness,
        sliderValueTimer: screen_time,
        isActiveCurrency: dollar_difference,
        selectedCurrencyType: currency_type,
        selectedUnit: weather_unit
      });
    }
  };

  getLedScheduleSelectionSetSuc = (responseJson:SelectionSetSchedule) => {
    if(responseJson.data){
      let selectedDays = responseJson.data.map(dataItem => dataItem.attributes.day);
      this.setState({
        ledScheduleDaysArray: selectedDays
      });
    }
  };
  
  saveLedScheduleSelectionSet = () =>{
    if(this.state.ledSelectSelectionSet !== ""){
      this.postSelectionSet();
          this.state.ledScheduleDaysArray.forEach((ledScheduleday:string)=>{
        this.postLedSelectionSetScheduleFunction(ledScheduleday);
      })
    } else{
      alert(webConfigJSON.pleaseSelectSelectionSetName);
    }
  };

  postSelectionSet = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const body = {
        brightness: this.state.sliderBrightnessValue,
        screen_time: this.state.sliderValueTimer,
        dollar_difference:this.state.isActiveCurrency,
        currency_type: this.state.selectedCurrencyType,
        weather_unit: this.state.selectedUnit
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putLedSelectionSetSetting = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.selectionSetSettings}/${this.state.ledSelectSelectionSet}`
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  postLedSelectionSetScheduleFunction = (ledScheduleday: string) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const body = {
      day:ledScheduleday,
      start_time:this.state.ledFullTimeStart,
      end_time:this.state.ledFullTimeEnd,
      selection_set_id: this.state.ledSelectSelectionSet
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLedSelectionSetSchedule = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.selectionSetSchedules
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  extendRecevieFunction = (webApiRequestCallId: string, webResponseJson: SelectionSetSchedule & SelectionSetSetting & ValidResponseType & CountryDataResponse & GlanceDetailsInterface ) => {
    if(webApiRequestCallId === this.getLedSelectionSetSetting) {
      this.getLedSelectionSetSettingSuc(webResponseJson);
    }
    if(webApiRequestCallId === this.getLedSelectionSetSchedule) {
      this.getLedScheduleSelectionSetSuc(webResponseJson);
    }
    if(webApiRequestCallId === this.putLedSelectionSetSetting) {
     if(webResponseJson.data && !webResponseJson.errors){
       this.setState({ 
        ledSettingModal: false,
        ledTimeStartModal: false,
        ledTimeDropdown: false,
        ledScheduleDropdown: false,
        selectedUnit:"fahrenheit",
        sliderValueTimer:3,
        sliderBrightnessValue:7,
        isActiveCurrency:false,
        ledSelectSelectionSet:"",
        selectedCurrencyType:"USD"
      });
     }
    }
    if(webApiRequestCallId === this.postLedSelectionSetSchedule) {
      if(webResponseJson.data && !webResponseJson.errors){
        this.setState({ 
          ledSettingModal: false,
          ledTimeStartModal: false,
          ledTimeDropdown: false,
          ledScheduleDropdown: false,
          ledScheduleDaysArray:[],
          ledScheduleType:"mon-fri",
          ledTimeEndModal:false,
          ledTimeEndHours:"1",
          ledTimeEndMinutes:"1",
          ledTimeStartHours:"1",
          ledTtimeStartMinutes:"1",
          ledFullTimeStart: '01:01',
          ledFullTimeEnd: '01:01'
        });
      }
    }
  }

  handlePrivacyPolicyNavigation = () => {
    const navigationMsg: Message = new Message(getName(MessageEnum.NavigationPrivacyPolicyMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMsg);
  };

  applyToPanelSelectionSet = (panelId: number) => {
    this.setState({setPanelId:panelId})
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.applyToPanelSelectionSetApiCallId = requestMessageGlance.messageId;
     
    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.applyToPanelSelectionSetEndPoint + panelId
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  closeApplyToPanelModal = () => {
    this.setState({
      applyToPanelModal: false
    });
  };

  addNewGlancePanel = () => {
    if(this.state.glanceNameText !== '' && this.state.macAddressModalText !== '' && this.state.macAddressModalText.match(webConfigJSON.macAddressRegEx)) {
        this.addUserGlance('addPage');
    } else {
        if(this.state.glanceNameText === '' || this.state.macAddressModalText === '') {
            this.validationError('All fields must be fill to add new Mac address');
        }   
    } 
  };

  closeMacAddressModal = () => {
    this.setState({
      step:0,
    });
    this.getUserInfo('firstLogin');
  };

  autoPopolatedTextInput = () => {
    this.setState({
      glanceNameEditable:true,
    }, () => {
      if(this.glnceNameInputRef.current) {
        this.glnceNameInputRef.current.focus();
      }
    });
  };

  editGlanceNam = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const bodyData = {
      name:this.state.glanceName
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editGlanceNameApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.editGlanceNameEndPoint + this.state.glanceId
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  updateGlanceName = (valueText: string) => {
    this.setState({
      glanceName:valueText
    });
  };

  closeEditPanelNameModal = () => {
    this.setState({
      editPanelNameSuccessModal: false
    });
  };

  getLanguages = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectionSetLanguageApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getSelectionSetLanguageEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  getBeaches = () => {
    this.changeGroup('beachs');
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectionSetBeachesApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getSelectionSetBeachesEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  extendRecevieTwoFunction = (webApiRequestCallId: string, webResponseJson: SelectionSetResponse & ValidResponseType & GlanceDetailsInterface & BeachDataResponse & LanguageDataResponse) => {
    if (webApiRequestCallId === this.editGlanceNameApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        this.setState({
          glanceName: webResponseJson.data.attributes.name,
          glanceNameEditable: false,
          editPanelNameSuccessModal: true
        });
      }
    }
    if (webApiRequestCallId === this.getSelectionSetBeachesApiCallId) {
      if (webResponseJson.beach_data && !webResponseJson.errors) {
        this.setState({
          beaches: webResponseJson.beach_data,
        });
      }
    }
    if (webApiRequestCallId === this.getSelectionSetLanguageApiCallId) {
      if (webResponseJson.lang_data && !webResponseJson.errors) {
        this.setState({
          languages: webResponseJson.lang_data,
          languageModal: true
        });
      }
    }
  };

  extendRecevieThreeFunction = (webApiRequestCallId: string, webResponseJson: SelectionSetResponse & ValidResponseType ) => {
    if (webApiRequestCallId === this.setLearnLanguageApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        this.setState({
          languageModal: false,
          selectedLanguageId: 0,
          actions: this.state.actions + 1,
          searchLanguageValue: "",
          searchedLanguages: []
        });
      }
    }
    if (webApiRequestCallId === this.getSelectionSetApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        let arrayOfSelectionSets: number[] = [];
        this.setSelectionSetsForPanel(webResponseJson.data)
        webResponseJson.data.map((element: SelectionSet) => this.getChoosenElements(+element.id))
        webResponseJson.data.map((element: SelectionSet) => {
          arrayOfSelectionSets.push(+element.id);
        })
        this.setSelectionsToApply(arrayOfSelectionSets);
        this.setSelectionsSetsAmount(webResponseJson.data.length);
      }
    }
    if (webApiRequestCallId === this.getSelectionSetItemsApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        const newData = webResponseJson.data.map((element: SelectionSet) => ({...element, selectionSetId: element.id}))
        this.setChoosenElementsOnDetailsPage([...newData, ...this.state.choosenElementsOnDetailsPage])
      }
    }
  };

  extendRecevieFourFunction = (webApiRequestCallId: string, webResponseJson:  ValidResponseType & GlanceDetailsInterface ) => {
    if (webApiRequestCallId === this.updateSelectionSetNameApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        this.setState({
          editSelectionSetNameModal: false
        },()=>{
          this.getSelectionSet();
        });
      }
    }
    if( webApiRequestCallId === this.applyToPanelSelectionSetApiCallId){
      if(webResponseJson.data && !webResponseJson.errors) {
        this.setState({
          responseErrormsg:"",
          applyToPanelModal: true,
        });
        
      }

      if(webResponseJson.message) {
        this.setState({
          applyToPanelModal: true,
          responseErrormsg:webResponseJson?.message
        });
        
      }
      
    }
  };

  closeEditSelectionSetNameModal = () => {
    this.setState({
      editSelectionSetNameModal: false
    });
  };

  openEditSelectionSetNameModal = (selectionSetId: number, selectionSetName: string) => {
    this.setState({
      selectedSelectionSetId: selectionSetId,
      editableSelectionSetName: selectionSetName,
      editSelectionSetNameModal: true
    });
  };

  editSelectionSetName = (event: { target: { value: string } }) => {
    let onChangeText = event.target.value;
    this.setState({
      editableSelectionSetName: onChangeText
    });
  };

  saveEditSelectionSetName = () => {
    const bodyData = {
      name: this.state.editableSelectionSetName
    };

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSelectionSetNameApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.updateSelectionSetNameEndPoint + this.state.selectedSelectionSetId
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  closeLanguageModal = () => {
    this.setState({
      languageModal: false,
      selectedLanguageId:0,
      searchLanguageValue: "",
      searchedLanguages: []
    });
  };

  selectLanguageOnClick = (languageId: number) => {
    this.setState({
      selectedLanguageId: languageId 
    });
  };

  setLearnLanguage = () => {
    const bodyData = {
        selection_type:"learn new lang", 
        key: this.state.selectedLanguageId,
        name: null,
        selection_set_id: this.state.selectionSetId
    };

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setLearnLanguageApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.addLanguageSelectionSetEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  getSelectionSet = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectionSetApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getSelectionSetEndPoint + this.state.glanceId
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  getChoosenElements = (selectionSetItem: number) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectionSetItemsApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getSelectionSetItemsEndPoint + selectionSetItem
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  searchLanguage = (event: { target: { value: string }}) => {
    let searchValue = event.target.value;
    const regex = new RegExp(searchValue, 'gi');
    const searchedValue = this.state.languages
      .map((language, languageIndex) => {
        if (language.match(regex)) {
          return { language, languageIndex: languageIndex + 1 };
        }
        return null;
      })
      .filter((match): match is SearchedLanguage => match !== null);
      this.setState({
        searchLanguageValue: searchValue,
        searchedLanguages: searchedValue
      });
  };

  fetchedElementsForStockModal = () => {
    if (this.state.group === 'standings') {
      return this.state.standingsElement;
    } else if (this.state.group === 'games') {
      return this.state.gamesElement;
    } else if (this.state.group === 'teams') {
      return this.state.teamsElement;
    } else if (this.state.group === 'beachs') {
      return this.state.beaches;
    } else if (this.state.group === 'newss') {
      return this.state.selectionSetNews;
    } else if (this.state.group === 'trading' && this.state.tab === 'stock') {
      return this.state.stockElements;
    } else {
      return this.state.stockElements; 
    }
  };

  saveSocailButton = () => {
    if (this.state.socialDataIn !== "") {
      this.saveSocailInstaLinks();
    }
    if (this.state.socialDataTt !== "") {
      this.saveSocailTikTokLinks();
    }
    if (this.state.socialDataYt !== "") {
      this.saveSocailYoutubeLinks();
    }
  }

  saveSocailTikTokLinks = () => {
    const bodyData = {
      selection_type: 'social',
      key: 'instagram',
      name: this.state.socialDataTt,
      selection_set_id: this.state.selectionSetId
    };

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setSocialTikTokApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.addLanguageSelectionSetEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  saveSocailYoutubeLinks = () => {
    const bodyData = {
      selection_type: 'social',
      key: 'youtube',
      name: this.state.socialDataYt,
      selection_set_id: this.state.selectionSetId
    };

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setSocialYoutubeApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.addLanguageSelectionSetEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  saveSocailInstaLinks = () => {
    const bodyData = {
      selection_type: 'social',
      key: 'instagram',
      name: this.state.socialDataIn,
      selection_set_id: this.state.selectionSetId
    };

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setSocialInstaApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.addLanguageSelectionSetEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethodType
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  socialScuccessCallBack = (webApiRequestCallId: string, webResponseJson: ValidResponseType) => {
    if (webApiRequestCallId === this.setSocialInstaApiCallId || webApiRequestCallId === this.setSocialTikTokApiCallId || webApiRequestCallId === this.setSocialYoutubeApiCallId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        this.setState({
          actions: this.state.actions + 1,
          socialDataIn: "",
          socialDataTt: "",
          socialDataYt: ""
        });
      }
    }
  };

  checkActiveDay = () => {
    return this.state.ledScheduleDaysArray.includes('monday') ? '#232323' : 'white';
  }; 

  selectionSetSearchOnChange = (searchString: string) => {
    let newSearchString = searchString.toLowerCase();
    this.setState({
      selectionsetSearchVal: newSearchString
    }, () => {
      if (newSearchString.length > 2) {
        this.selectionSetSearch(newSearchString);
      }else{
        this.setState({selctionSetSearch:[]});
      }
    });
  };
 
  selectionSetSearch = (searchQuery: string) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selctionSetSearchId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.selectionSetSearchEndPoint}${searchQuery}&selection_type=${this.state.tab}&panel_id=${this.state.glanceId}`
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };


  selectionSetSearchCallBak = (webApiRequestCallId: string, webResponseJson: ValidResponseType & SearchSelectionSetRes) => {
    if (webApiRequestCallId === this.selctionSetSearchId) {
      if (webResponseJson && webResponseJson.data && webResponseJson.data.length > 0) {
        this.setState({
          selctionSetSearch: webResponseJson.data
        });
      } else {
        this.setState({
          selctionSetSearch: []
        });
      }
    }
  }

  checkRenderValues = () => {
    return this.state.selctionSetSearch.length > 0 && this.state.selectionsetSearchVal.length > 2;
  };

  renderStocks = () => {
    return (this.state.group === 'trading' && this.state.tab === 'stock' && this.state.selctionSetSearch.length === 0 && this.state.selectionsetSearchVal.length <= 2 )
  };

  renderCrypto = () => {
    return (this.state.group === 'trading' && this.state.tab === 'crypto' && this.state.selctionSetSearch.length === 0 && this.state.selectionsetSearchVal.length <= 2 )
  };

  addSelectionSetNav = (selectionSetId: string | number) => {
    const navigationMsg: Message = new Message(
      getName(MessageEnum.NavigationAddSelectionSetMessage)
    );
    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigationMsg.addData(getName(MessageEnum.sendAddSelectionSetIdProps), { selectionSetId: selectionSetId, glancePanelId: this.state.glanceId });
    this.send(navigationMsg);
  };

  fetchElements = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchChoosenElementId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getSelectionSetItemsEndPoint}${this.state.selectionSetId}`
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  };

  extendRecevieFiveFunction = (webApiRequestCallId: string, webResponseJson: ValidResponseType ) => {
    if (webApiRequestCallId === this.fetchChoosenElementId) {
      if (webResponseJson.data && !webResponseJson.errors) {
        this.setChoosenElements(webResponseJson.data)
        this.closeStockModal()
      }
    }
  };

  setSearchAction = () => {
    this.setState({
      setSearchAction: this.state.setSearchAction +1
    });
  };
  // Customizable Area End
}
