import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { returnDomain } from './apiURLutil';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement, 
  CardExpiryElement, 
  CardCvcElement
} from '@stripe/react-stripe-js';

// Załaduj Stripe.js i zapisz instancję
const stripePromise = loadStripe('pk_test_51O0ELzC8lZiDbPcfl0WUgBZwoEhRG9ULHiTcamAwvrp0nMp2sDKKmCoDuvdEd9ByZJrytJPDSkYOTaucaIggxPE300yiniYBEc');

const CARD_STYLE = {
  style: {
    base: {
      "::placeholder": {
        color: "#aab7c4"
      },
      borderRadius: '4px',
      border: '1px solid rgba(236, 236, 238, 0.10)',
      background: '#2B2B2E',
      color: 'white',
      width: '100%',
      height: '40px',
      paddingLeft: '12px',
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

interface CheckoutFormProps {
  showSaveButton: boolean;
}

const CheckoutForm = ({showSaveButton}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [cardholder, setCardHolder] = useState('')

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js jeszcze się nie załadowało
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // @ts-ignore
    const { error, token } = await stripe.createToken(cardNumberElement);

    console.log('token: ',token)

    if(cardholder === '') {
      setError("Cardholder's Name is required")
    }

    if (error) {
      // @ts-ignore
      setError(error.message);
      setLoading(false);
    } else {
      setLoading(false);
      setError(null)

      if(cardholder !== '') {
        setError(null)

        const domain = window.location.href.includes('localhost') 
          ? 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'
          : window.location.href.includes('.dev.') 
            ? 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'
            : window.location.href.includes('.stage.') 
              ? 'https://glancestartup-373059-ruby.b373059.stage.eastus.az.svc.builder.cafe/'
              : window.location.href.includes('.uat.') 
                ? 'https://glancestartup-373059-ruby.b373059.uat.eastus.az.svc.builder.cafe/'
                : ''

        fetch(
          `${domain}bx_block_settings/card_details`,
          {
              method: "POST",
              headers: {
                token: localStorage.getItem('authToken') as string,
              },
              body: JSON.stringify({
                cardholder_name: cardholder,
                stripe_card_token: token?.id
              })
          }
        )
      } else {
        setError("Cardholder's Name is required")
      }
      
      // Dodatkowe akcje po udanej płatności
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '8px',
      }}>Cardholder's Name</div>
      <input value={cardholder} onChange={(e) => setCardHolder(e.target.value)} style={{
        borderRadius: '4px',
        background: '#2B2B2E',
        color: 'white',
        width: '98%',
        height: '40px',
        paddingLeft: '12px',
        border: error === "Cardholder's Name is required" ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.10)',
      }}/>
      <div style={{
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '8px',
        marginTop: '16px'
      }}>Credit Card Number</div>
      <div style={{
        borderRadius: '4px',
        border: error === 'Your card number is incomplete.' ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.10)',
        background: '#2B2B2E',
        color: 'white',
        paddingLeft: '12px',
        width: '95%',
        paddingTop: '11px',
        paddingBottom: '11px',
      }}>
        <CardNumberElement options={CARD_STYLE}/>
      </div>
      
      <div style={{display: 'flex', flexDirection: 'row'}}>

        <div style={{display: 'flex', flexDirection: 'column', width: '46%'}}>
          <div style={{
            color: '#FFF',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: '8px',
            marginTop: '16px',
          }}>
            Expiry Date
          </div>
          <div style={{
            borderRadius: '4px',
            border: error === "Your card's expiration date is incomplete." ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.10)',
            background: '#2B2B2E',
            paddingLeft: '12px',
            width: '95%',
            paddingTop: '11px',
            paddingBottom: '11px',
            color: 'white',
          }}>
            <CardExpiryElement options={CARD_STYLE}/>
          </div>
        </div>
        
        <div style={{display: 'flex', flexDirection: 'column', width: '46%', marginLeft: '20px'}}>
          <div style={{
            color: '#FFF',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: '8px',
            marginTop: '16px',
          }}>
            CVV
          </div>
          <div style={{
            borderRadius: '4px',
            border: error === "Your card's security code is incomplete." ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.10)',
            background: '#2B2B2E',
            color: 'white',
            paddingLeft: '12px',
            width: '95%',
            paddingTop: '11px',
            paddingBottom: '11px',
          }}>
            <CardCvcElement  options={CARD_STYLE}/>
          </div>
        </div>
      </div>
      {showSaveButton &&
        <button type="submit" disabled={!stripe || loading} style={{
          borderRadius: '4px',
          background: '#38FF4C',
          fontFamily: 'Roboto Condensed',
          textAlign: 'center',
          padding: '10px 0',
          width: '98%',
          color: '#1F1F21',
          marginTop: '16px',
          border: 0,
          fontWeight: 500,
          fontSize: '16px', 
          lineHeight: '19px', 
          letterSpacing: 0.04
        }}>
          SAVE
        </button>
      }
      {error && <div style={{color: 'red', marginTop: '16px', fontFamily: 'Roboto' }}>{error}</div>}
    </form>
  );
};

export const StripeCard = ({showSaveButton}: CheckoutFormProps) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm showSaveButton={showSaveButton}/>
    </Elements>
  );
};
