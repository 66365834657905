export const domainSend = () => {
    return window.location.href.includes('localhost') 
        ? 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'
        : window.location.href.includes('.dev.') 
        ? 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'
        : window.location.href.includes('.stage.') 
            ? 'https://glancestartup-373059-ruby.b373059.stage.eastus.az.svc.builder.ai/'
            : window.location.href.includes('.uat.') 
            ? 'https://glancestartup-373059-ruby.b373059.uat.eastus.az.svc.builder.ai/'
            : ''
}