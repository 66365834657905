Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.fetchCurrencyDetailsEndPoint = "account/accounts/country_code_and_flag";
exports.fetchCurrencyDetailsMethod = "GET";
exports.selectionSetSettings = "bx_block_glance/selection_set_settings";
exports.selectionSetSchedules = "bx_block_glance/selection_set_schedules";
exports.putApiMethodType = "PUT";
exports.postApiMethodType = "POST";
exports.pleaseFillValidTimeText ="please fill valid time";
exports.pleaseSelectSelectionSetName = "Please select selection set name";
exports.applyToPanelSelectionSetEndPoint = "bx_block_glance/selection_sets/apply_to_panel/";
exports.macAddressRegEx = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
exports.pleaseEnterMacAddressText = "Please Enter mac address";
exports.pleaseEnterValidMacAddressText = "Please Enter a valid mac address";
exports.editGlanceNameEndPoint = "bx_block_glance/panels/";
exports.getSelectionSetLanguageEndPoint = "bx_block_cfintegrationdatabaseforglancepanelbackend2/lang_search";
exports.getSelectionSetBeachesEndPoint = "bx_block_cfintegrationdatabaseforglancepanelbackend2/beach_search";
exports.updateSelectionSetNameEndPoint = "bx_block_glance/selection_sets/";
exports.addLanguageSelectionSetEndPoint = "bx_block_glance/selection_set_items";
exports.getSelectionSetEndPoint = "bx_block_glance/selection_sets/";
exports.getSelectionSetItemsEndPoint = "bx_block_glance/selection_set_items/";
exports.selectionSetSearchEndPoint = "bx_block_advanced_search/search_selection_search?search_query=";
// Customizable Area End