import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import { 
    imgDecor, 
    imgDashboard,
    imgSettings, 
    imgUpgrade,
    imgSearch,
    imgAvatar,
    imgCloseWhite,
    imgDropdownTerms,
    imgDropdownLogout,
    imgDropdownManage,
    imgMail
} from '../../blocks/dashboard/src/assets'
import { settingsWhite } from '../../blocks/customisableuserprofiles/src/assets';
import styled from 'styled-components/native';

interface myProps {
  navigation: any;
  showUpgradeModal: (type: string) => void;
  backgroundUrl?: any;
  activeSettings?: boolean;
}


export default function NavigationApp(props: myProps) {
    const [loggedUserEmail, setLoggedUserEmail] = useState('');
    const [loggedUserAvatar, setLoggedUserAvatar] = useState(null);
    const [openDropdownUser, setOpenDropdownUser] = useState(false);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [inputLength, setInputLength] = useState(0);

    const filterQuery = (value: string) => {
        const searchQuery = value.toLowerCase();
        const result = dummyData.filter((element: any) => element.name.toLowerCase().includes(searchQuery));
        setFilteredData(result)
        setInputLength(value.length)
    }

    const dummyData = [
        {name: 'Dashboard', url: 'Dashboard', key: 0},
        {name: 'Add Glance', url: 'AddGlancePage', key: 1},
        {name: 'Settings', url: 'CustomizableUserProfile', key: 2},
        {name: 'User Profile', url: 'CustomizableUserProfile', key: 3},
        {name: 'Billing ', url: 'CustomizableUserProfileBilling', key: 4},
        {name: 'Manage your Glance-Led account', url: 'CustomizableUserProfile', key: 5},
        {name: 'Terms and conditions', url: 'TermsConditionsDetail', key: 6},
        {name: 'Privacy policy ', url: 'PrivacyPolicy', key: 7},
        {name: 'Contact us', url: 'Contactus', key: 8}
      ]

    const domain = window.location.href.includes('.uat.')
      ? 'https://glancestartup-373059-ruby.b373059.uat.eastus.az.svc.builder.ai/'
      : window.location.href.includes('.stage.') 
        ? 'https://glancestartup-373059-ruby.b373059.stage.eastus.az.svc.builder.ai/'
        : 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'

    useEffect(() => {

        fetch(
            `${domain}account_block/me`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    token: localStorage.getItem('authToken') as string
                }
            }
        )
        .then(response => {
            if(response.status >= 400) {
                localStorage.removeItem('authToken');
                return props.navigation.navigate('EmailAccountLoginBlock')
            }
            return response.json()
        })
        .then(data => {
            setLoggedUserEmail(data.data.attributes.email)
            setLoggedUserAvatar(data.data.attributes.user_image)
        })


  
    }, [props.backgroundUrl])



    return (
        <View style={styles.container2}>
            {(filteredData.length > 0 && inputLength > 0) &&
                <View style={{
                    width: 360, 
                    height: 500, 
                    backgroundColor: '#1F1F21', 
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'absolute',
                    right: '204px',
                    zIndex: 4,
                    marginLeft: '60px',
                    //@ts-ignore
                    overflowY: 'auto',
                    borderWidth: 1,
                    borderColor: '#232323',
                    marginTop: '561px'
                }}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        {filteredData.map((element: any) => (
                                <TouchableOpacity 
                                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}} 
                                    onPress={() => props.navigation.navigate(element.url)}
                                    key={element.key}
                                >
                                    <StyledText style={{textAlign: 'left'}}>{element.name}</StyledText>
                                </TouchableOpacity>
                            ))
                        }
                    </View>
                </View>
            }

            {openDropdownUser &&
            <View style={{
                width: 360, 
                height: 236, 
                backgroundColor: '#1F1F21', 
                paddingTop: 16,
                paddingLeft: 16,
                paddingBottom: 16,
                paddingRight: 16,
                position: 'absolute',
                right: '40px',
                zIndex: 4,
                top: '66px',
            }}>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Image source={loggedUserAvatar ? loggedUserAvatar : imgAvatar} style={{width: 45, height: 45, borderRadius: 50}}/>
                    <View style={{marginLeft: 16, display: 'flex', flexDirection: 'column', marginRight: 'auto'}}>
                        <Text style={{
                            color: 'rgba(255, 255, 255, 0.75)',
                            fontFamily: 'Roboto Condensed',
                            fontSize: 20,
                            fontWeight: '400',
                        }}>Hi User!</Text>
                        <Text
                        style={{
                            color: 'rgba(255, 255, 255, 0.60)',
                            fontFamily: 'Roboto Condensed',
                            fontSize: 14,
                            fontWeight: '400',
                        }}
                        >{loggedUserEmail}</Text>
                    </View>
                    <TouchableOpacity onPress={() => setOpenDropdownUser(false)}>
                        <Image source={imgCloseWhite} style={{width: 20, height: 20}}/> 
                    </TouchableOpacity>
                </View>
                <View style={{display: 'flex', flexDirection: 'column'}}>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}} 
                        onPress={() => props.navigation.navigate('CustomizableUserProfile')} 
                    >
                        <Image source={imgDropdownManage} style={{width: 24, height: 24}}/>
                        <StyledText>Manage your Glance-led Account</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => props.navigation.navigate('TermsConditionsDetail')}                         
                    >
                        <Image source={imgDropdownTerms} style={{width: 24, height: 24}}/>
                        <StyledText>Terms and Conditions</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => props.navigation.navigate('PrivacyPolicy')}                         
                    >
                        <Image source={imgDropdownTerms} style={{width: 24, height: 24}}/>
                        <StyledText>Privacy Policy</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => props.navigation.navigate('Contactus')}                         
                    >
                        <Image source={imgMail} style={{width: 24, height: 24}}/>
                        <StyledText>Contact Us</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => {
                            localStorage.removeItem('authToken')
                            props.navigation.navigate('EmailAccountLoginBlock')
                        }}
                        
                    >
                        <Image source={imgDropdownLogout} style={{width: 24, height: 24}}/>
                        <StyledText>LOG OUT</StyledText>
                    </TouchableOpacity>
                </View>
            </View>
            }
            
            <TouchableOpacity onPress={() => props.navigation.navigate('Dashboard')}>
                <Image
                    testID={"imgLogo"}
                    source={imgDecor}
                    style={{width: 156, height: 24}}
                />
            </TouchableOpacity>
            
            <View style={styles.controls}>
                <TouchableOpacity style={styles.control} onPress={() => props.navigation.navigate('Dashboard')} data-testid="gotodashboard">
                    <Image
                        testID={"imgDashboard"}
                        source={imgDashboard}
                        style={{width: 20, height: 20}}
                    />
                    <Text style={[styles.Text, {color: '#717172'}]}>
                        Dashbaord
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.control} 
                    onPress={() => props.navigation.navigate('CustomizableUserProfile')} 
                    data-testid="gotosettings"
                >
                    <Image
                        testID={"imgDashboard"}
                        source={props.activeSettings ? settingsWhite : imgSettings}
                        style={{
                            width: props.activeSettings ? 16 : 20, 
                            height: props.activeSettings ? 16 : 20, 
                            marginTop: props.activeSettings ? 2 : 0
                        }}
                    />
                    <Text style={[styles.Text, {color: props.activeSettings ? 'white' : '#717172'}]}>
                        Settings
                    </Text>
                </TouchableOpacity>
            </View>
            <View style={styles.rightSide}>
                <View style={styles.upgrade}>
                    <TouchableOpacity 
                        data-testid='upgrade-modal' 
                        onPress={() => props.showUpgradeModal('upgrade')} 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                    >
                    <Image
                        source={imgUpgrade}
                        style={{width: 20, height: 20}}
                    />
                    <Text style={styles.textUpgrade}>Upgrade to GLANCE PREMIUM</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.search}>
                    <Image
                        source={imgSearch}
                        style={{width: 14, height: 14, marginRight: 9}}
                    />
                    <TextInput
                        testID="txtInputSearch"

                        style={[styles.input2]}
                        placeholder="Quick Search..."
                        onChangeText={(value) => filterQuery(value)}
                    />
                </View>
                <TouchableOpacity style={styles.user} onPress={() => setOpenDropdownUser(true)}>
                    <Image
                        source={loggedUserAvatar ? loggedUserAvatar : imgAvatar}
                        // @ts-ignore
                        style={{
                            width: 32, 
                            height: 32, 
                            marginRight: 9,
                            borderRadius: loggedUserAvatar ? '50%' : '0'
                        }}
                    />
                    <Text style={styles.emailText}>
                        {loggedUserEmail}
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const StyledText = styled.Text`
color: #FFF;
text-align: center;
font-family: Roboto Condensed;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.64px;
margin-left: 10px;
`

const styles = StyleSheet.create({
    modal: {
        width: 486,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: -100,
    },
    title: {
        fontSize: 24,
        fontFamily: 'Roboto Condensed',
        marginBottom: 12,
    },
    colorTitle: {
        color: '#38FF4C',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 16
    },
    textLink: {
        color: 'rgba(196, 237, 200, 1)',
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    label: {
        fontSize: 16,
        color: 'white',
        marginTop: 24,
        marginBottom: 8,
    },
    input: {
        borderColor: '#3f3f41',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#2b2b2e',
        color: '#808082',
        height: 40,
        fontSize: 16,
        paddingLeft: 12,
        paddingRight: 12,
    },
    button: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: '#38FF4C',
        width: 'fit-content',
        marginTop: 24,
        marginLeft: 'auto',
        borderRadius: 4
    },
    buttonText: {
        color: '#1F1F21',
        fontFamily: 'Roboto Condensed',
    },
    point: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12
    },
    buttons: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginTop: 10,
    },

    // navigation
    container2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 64,
        backgroundColor: '#131314',
        paddingLeft: 40,
        paddingRight: 40,
        zIndex: 4,
      },
      controls: {
        display: 'flex',
        flexDirection: 'row',
      },
      control: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
      },
      Text: {
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        fontWeight: '400',
        marginLeft: 5
      },
      textUpgrade: {
        color: 'rgba(56, 255, 76, 1)',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto Condensed',
      },
      rightSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      upgrade: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      search: {
        borderColor: '#343436',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: 13,
        paddingRight: 13,
        marginLeft: 24,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      input2: {
        color: '#79797a',
      },
      user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 24,
      },
      emailText: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 14,
        fontWeight: '500'
      },
})