import React from 'react'
import {
    Text,
    View,
    TouchableOpacity
} from "react-native";
import { 
    ContainerContent, 
    Title, 
    AddGlance, 
    Box, 
    Header, 
    Dot, 
    Form, 
    Img, 
    Logo,  
    FormText, 
    Input, 
    FormTextSecond, 
    // ButtonSubmit, 
    // TextBtn,
    CreateSelectionSetBtn,
    CreateSelectionSetBtnText,
    ContentSelectionSection,
    BtnImg,
    DefaultSelectionSet,
    DefaultSelectionSetText,
    Icons,
    EditIcon,
    DropdownIcon,
    DropdownIconRotate,
    BoxStyle,
    BoxStyleSection,
    BoxStyleAdd,
} from './StyleAddGlance';
import { imgAdd, imgEdit, imgCollapse, imgAddGroup, imgSettingsGreen } from '../../blocks/dashboard/src/assets'
import { selectionSetSettingsCurrency, selectionSetSettingsTimer, selectionSetSettingsUnits, selectionSetSettingsLight, imgExpand, playIcon } from './assets';
import { imgCalendar } from './assets'
import NavigationApp from './NavigationApp';
import DashboardController, { ButtonClickHandler, CountryData, Props, SelectionSet, SelectionSetItem } from '../../blocks/dashboard/src/DashboardController.web';
import UpgradeModal from './UpgradeModal';
import PaymentModal from './PaymentModal';
import { domainSend } from "./domain";
import { imgThumbnail } from '../../blocks/dashboard/src/assets';
import { Box as BoxMaterial, MenuItem, Modal, Select, Typography, styled} from "@material-ui/core";
import { SelectionSetScheduleModal } from './SelectionSetScheduleModal';
import Slider from '@react-native-community/slider';
import { closeWhite, successSend } from "../../blocks/contactus/src/assets";
export default class GlanceDetails extends DashboardController {
    async componentDidMount(): Promise<void> {
        this.fetchSelections()
    }

    async fetchSelections() {
        const url = await window.location.href;
        const lastSegment = await url?.split("/").pop();
        this.setGlanceId(lastSegment)

        const domain = domainSend();

        fetch(
            `${domain}bx_block_glance/panels`,
            {
                method: "GET",
                headers: {
                  token: localStorage.getItem('authToken') as string
                },
            }
          )
          .then(response => response.json())
          .then(data => {
            // this.setState({
            //   backgroundUrl: data.data.attributes.user_image
            // })
            const glanceID = data.data.filter((element: any) => {
                return element.id === lastSegment as string
            })
            this.setGlanceId(glanceID[0].id)
            this.setGlanceNameDetails(glanceID[0].attributes.name)
            this.setGlanceMac(glanceID[0].attributes.mac_address)
          })

          fetch(
            `${domain}bx_block_glance/selection_sets/${lastSegment}`,
            {
                method: "GET",
                headers: {
                  token: localStorage.getItem('authToken') as string
                },
            }
          )
          .then(response => response.json())
          .then(data => {
            let arrayOfSelectionSets: any[] = []
            // this.setState({
            //   backgroundUrl: data.data.attributes.user_image
            // })
            console.log('ilosc selection set: '+JSON.stringify(data.data.length))
            console.log('selections sets: '+JSON.stringify(data.data))
            console.log("DATA.DATA::::",data.data)
            this.setSelectionSetsForPanel(data.data)
            data.data.map((element: any) => this.fetchChoosenElements(element.id))
            data.data.map((element: any) => {
                arrayOfSelectionSets.push(element.id)
                
            })
            this.setSelectionsToApply(arrayOfSelectionSets)
            this.setSelectionsSetsAmount(data.data.length)
          })
          
    }
    

    addNewSelection() {
        const domain = domainSend();

        // let result = '';
        // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // const charactersLength = 8;
        // for (let i = 0; i < 8; i++) {
        //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
        // }

        // const selectionSetName = this.state.glanceName + result

        const body = {
            name: this.state.selectionName,
            panel_id: this.state.glanceId
        }
        
    
        fetch(
            `${domain}bx_block_glance/selection_sets`,
            {
                method: "POST",
                headers: {
                  token: localStorage.getItem('authToken') as string,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
                
            }
          )
          .then(response => response.json())
          .then(data => {
            this.setSelectionSetsModal(false)
            this.fetchSelections()
          })
    }

    addCardForSelection() {
        const domain = domainSend();

        const body = {
            name: this.state.selectionName,
            panel_id: this.state.glanceId
        }
        
    
        fetch(
            `${domain}bx_block_glance/selection_sets`,
            {
                method: "POST",
                headers: {
                  token: localStorage.getItem('authToken') as string,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
                
            }
          )
          .then(response => response.json())
          .then(data => {
            this.props.navigation.navigate('AddSelectionSet')
          })
    }

    fetchChoosenElements(id: any) {
        console.log('fetchCoosen elements')
        const domain = domainSend();

        const url = window.location.href;
        const lastSegment = url?.split("/").pop();
        
        fetch(
            `${domain}bx_block_glance/selection_set_items/${id}`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string,
                    "Content-Type": "application/json",
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            const newData = data.data.map((element: any) => ({...element, selectionSetId: id}))
            console.log('New Data: '+JSON.stringify(newData))
            this.setChoosenElementsOnDetailsPage([...newData, ...this.state.choosenElementsOnDetailsPage])
        })
    }

 selecteddays =""
    fetchSelectionSetSettings(id: any) {
        const domain = domainSend();

        fetch(
            `${domain}bx_block_glance/selection_set_settings/${id}`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string,
                    "Content-Type": "application/json",
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            this.setSelectionSetBrightness(data.data?.attributes?.brightness)
            this.setSelectionSetCurrencyType(data.data?.attributes?.currency_type)

            this.setSelectionSetDollarDifference(data.data?.attributes?.dollar_difference)
            this.setSelectionSetScreenTime(data.data?.attributes?.screen_time)
            this.setSelectionSetWeatherUnit(data.data?.attributes?.weather_unit)
            this.setSelectionSetSettingsModalID(id)
            
            setTimeout(() => {
                this.setSettingsModal(true)
            }, 100)
            
        })
    }

    fetchSelectionSetSchedule(id: any) {
        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_glance/selection_set_schedules/${id}`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string,
                    "Content-Type": "application/json",
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log('schedule data::::: '+JSON.stringify(data))
            this. selecteddays = data.data.map((dataItem:any) => dataItem.attributes.day);
      this.setState({
        daysArray: this.selecteddays
      });

            const startHour = data.data[0].attributes.start_time.substring(0, 2);
            const startMinutes = data.data[0].attributes.start_time.substring(data.data[0].attributes.start_time.indexOf(":") + 1)

            const endHour = data.data[0].attributes.end_time.substring(0, 2);
            const endMinutes = data.data[0].attributes.end_time.substring(data.data[0].attributes.end_time.indexOf(":") + 1)

            this.setStartTimeHours(startHour)
            this.setStartTimeMinutes(startMinutes)
            this.setEndTimeHours(endHour)
            this.setEndTimeMinutes(endMinutes)

            setTimeout(() => {
                console.log('open modal')
                this.setCalendarModal(true)
            }, 100)
            
        })
    }

    addSchedule(scheduleType: string, fullTimeStart: string, fullTimeEnd: string, days: any, scheduleID: any) {
        const domain = domainSend();
     
        days.map((element: any) => {
            fetch(
                `${domain}bx_block_glance/selection_set_schedules`,
                {
                    method: "POST",
                    headers: {
                        token: localStorage.getItem('authToken') as string,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        day: element,
                        start_time: fullTimeStart,
                        end_time: fullTimeEnd,
                        selection_set_id: scheduleID
                    })
                }
            )
            .then(response => response.json())
        })

        this.setCalendarModal(false)
        
    }

    onCalendarIconClick(id: any) {
        this.setScheduleID(id);
        this.fetchSelectionSetSchedule(id)

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState:any) {
        
    }
    render() {
        return (
            <View style={{
                display: "flex",
                flexDirection: 'column',
                width: '100%',
                minHeight: '900px'
            }}>
               
                {
                    this.state.upgradeModal && 
                        <UpgradeModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }

                {
                    this.state.paymentModal && 
                        <PaymentModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }

                {
                    this.state.calendarModal &&
                    <SelectionSetScheduleModal 
                        open={this.state.calendarModal} 
                        closeModal={() => this.setCalendarModal(false)}
                        scheduleDropdown={this.state.scheduleDropdown}
                        setScheduleDropdown={this.setScheduleDropdown}
                        scheduleType={this.state.scheduleType}
                        setScheduleType={this.setScheduleType}
                        timeDropdown={this.state.timeDropdown}
                        setTimeDropdown={this.setTimeDropdown}
                        timeSelect={this.state.timeSelect}
                        setTimeSelect={this.setTimeSelect}
                        timeSelectEnd={this.state.timeSelectEnd}
                        setTimeSelectEnd={this.setTimeSelectEnd}
                        setTimeStartHours={this.setStartTimeHours}
                        setTimeStartMinutes={this.setStartTimeMinutes}
                        setTimeEndHours={this.setEndTimeHours}
                        setTimeEndMinutes={this.setEndTimeMinutes}
                        timeStartHours={this.state.startTimeHours}
                        timeStartMinutes={this.state.startTimeMinutes}
                        timeEndHours={this.state.endTimeHours}
                        timeEndMinutes={this.state.endTimeMinutes}
                        setFullTimeStart={this.setFullTimeStart}
                        setFullTimeEnd={this.setFullTimeEnd}
                        fullTimeStart={this.state.fullTimeStart}
                        fullTimeEnd={this.state.fullTimeEnd}
                        addSchedule={this.addSchedule}
                        days={this.state.daysArray}
                        setDays={this.setDays}
                        scheduleID={this.state.scheduleID}
                        selecteddays={this.selecteddays}
                    />
                }

                {
                    this.state.selectionSetModal &&
                    <View style={{
                        position: 'absolute',
                        width: '100vw',
                        height: '100vh',
                        left: '0',
                        top: '0',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 99
                    }}>
                        <View style={{width: '700px', height: 200, borderRadius: 4,left: 'calc(50% - 432px/2)',
                            top: '200px',
                            backgroundColor: '#1f1f21',
                            borderWidth: 3,
                            borderColor: 'transparent',
                            paddingTop: '20px',
                            paddingLeft: '20px',
                            paddingRight: '20px'
                        }}
                        >   
                            <TouchableOpacity onPress={() => this.setSelectionSetsModal(false)}>
                                <Text style={{color: '#7d7d7f', fontSize: 30, textAlign: 'right', marginBottom: 20}} >x</Text>
                            </TouchableOpacity>
                            
                            <Input placeholder='Provide name of selection set' onChangeText={(value: any) => this.setSelectionName(value)}/>
                            <CreateSelectionSetButton disabled={this.state.selectionName.length === 0} onClick={() => {this.addNewSelection()}}  data-testid="save-selection">
                                SAVE
                            </CreateSelectionSetButton>
                        </View>
                    </View>
                    
                }
                <ContainerContent>
                    <NavigationApp navigation={this.props.navigation} showUpgradeModal={this.showUpgradeModal} />
                    <Title>
                        GLANCE LED Details
                        <CreateSelectionSetBtn data-test-id="ledSettingButton" onPress={() => this.openLedSettingModal()}>
                            <CreateSelectionSetBtnText data-test-id="ledSettingButtonText">
                                <BtnImg
                                    source={imgSettingsGreen}
                                    style={{marginRight: 6}}
                                />
                                LED Settings
                            </CreateSelectionSetBtnText>
                        </CreateSelectionSetBtn>
                    </Title>
                    <AddGlance>
                        <Box>
                            <Header>
                                <Dot />
                                <GlanceNameText data-test-id="glanceNameText">{this.state.glanceName}</GlanceNameText>
                            </Header>
                            <Img>
                                <Logo></Logo>
                            </Img>                            
                        </Box>
                        <Form>
                            <FormText>GLANCE Name</FormText>
                            <PanleNameTextBox>
                                <Input placeholder='Your GLANCE name' value={this.state.glanceName} disabled={!this.state.glanceNameEditable}
                                    onChangeText={(value: string) => this.updateGlanceName(value)} ref={this.glnceNameInputRef}
                                    data-test-id="panelNameInputBox"
                                />
                                {
                                    this.state.glanceNameEditable ?
                                        <PanelNameSaveButton onClick={this.editGlanceNam} data-test-id="saveEditName">
                                            SAVE
                                        </PanelNameSaveButton>
                                        :
                                        <PanelEditButton onClick={this.autoPopolatedTextInput} data-test-id="editPanelNameButton">
                                            <PanelEditIcon src={imgEdit} />
                                        </PanelEditButton>
                                }
                            </PanleNameTextBox>
                            <FormTextSecond>MAC address</FormTextSecond>
                            <Input placeholder='e.g. 11:22:33:44:55:66' value={this.state.glanceMac} disabled={true}/>
                            {this.state.validationError && <Text style={{color: 'red'}}>All fields must be fill to add new Mac address</Text>}
                          
                        </Form>
                    </AddGlance>
                    <ContentSelectionSection>
                        <Title style={{
                            fontSize: 24, 
                            paddingBottom: 16, 
                            marginTop: 40, 
                            width: '96%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'space-between',
                            borderBottomColor: 'rgba(255, 255, 255, 0.08)',
                            borderBottomWidth: 1
                        }}>
                            GLANCE Content Selection
                            {this.state.selectionsSetsAmount < 5 &&
                            <CreateSelectionSetBtn style={{marginRight: 0}} onPress={() => this.setSelectionSetsModal(true)}>
                                {/* // if(this.state.glanceNameText !== '' && this.state.macAddressModalText !== '') {
                                //     this.addUserGlance('addPage')
                                // } else {
                                //     this.validationError()
                                // } */}

                                <CreateSelectionSetBtnText>
                                <BtnImg
                                    source={imgAdd}
                                />
                                Create Selection Set
                                </CreateSelectionSetBtnText>
                            </CreateSelectionSetBtn>
                            }
                        </Title>
                    </ContentSelectionSection>
                   
                    <>
           
                    {this.state.selectionSetsForPanel.map((element: any) => 
                    <>
                        <DefaultSelectionSet>
                            <DefaultSelectionSetText data-test-id="selectionSetNameText">
                                {element.attributes.name}
                            </DefaultSelectionSetText>
                            <Icons>
                                {/* ### Calendar */}
                                <PlayButton onClick={() => this.applyToPanelSelectionSet(element.id)} data-test-id="playButton">
                                 <PlayButtonIcon src={playIcon}/>
                                  
                                      
                                </PlayButton>
                                    
                                <TouchableOpacity onPress={() => this.onCalendarIconClick(element.id)}  data-testid="calendar">
                                    <EditIcon
                                        source={imgCalendar}
                                        style={{marginRight: 16}}
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.openEditSelectionSetNameModal(element.id, element.attributes.name)}  testID={"editButton"}>
                                    <EditIcon
                                        source={imgEdit}
                                    />
                                </TouchableOpacity>
                               
                                {this.state.isExpanded 
                                    ? 
                                    <TouchableOpacity onPress={() => this.switchDropdownSection()} data-testid='collapse'>
                                        <DropdownIcon
                                            source={imgCollapse}
                                        />
                                    </TouchableOpacity>
                                    : <TouchableOpacity onPress={() => this.switchDropdownSection()} data-testid='hide'>
                                        <DropdownIconRotate
                                            source={imgCollapse}
                                        />
                                    </TouchableOpacity>
                                }
                            </Icons>                       
                        </DefaultSelectionSet>
                        <View style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                        
                            <BoxStyleSection>
                                {this.state.choosenElementsOnDetailsPage?.map((selectionItem: SelectionSetItem) => {
                                    if(selectionItem.selectionSetId === element.id) {
                                        return (
                                            <TouchableOpacity 
                                                onPress={() => this.addSelectionSetNav(element.id)}                                               
                                            >
                                                <BoxStyle style={{display: 'flex', flexDirection: 'column'}}>
                                                    <SelectionSetNameText>
                                                        {selectionItem?.attributes?.name ? selectionItem?.attributes?.name : selectionItem?.attributes?.key}
                                                    </SelectionSetNameText>
                                                    <SelectionSetImageBox style={{
                                                        backgroundImage: selectionItem.attributes.image?.length > 0 ?  `url(${selectionItem.attributes.image})` : `url(${imgThumbnail})`
                                                    }}/>
                                                </BoxStyle>
                                            </TouchableOpacity>   
                                        )
                                    }
                                })}
                                <TouchableOpacity onPress={() => this.addSelectionSetNav(element.id)} data-test-id="navigateButtonId">
                                    <BoxStyle>
                                        <BoxStyleAdd
                                            source={imgAddGroup}
                                        />
                                        <AddSelectionText data-test-id="addSelectionText">
                                            ADD SELECTION
                                        </AddSelectionText>
                                    </BoxStyle>
                                </TouchableOpacity>                               
                            </BoxStyleSection>
                           
                        </View>
                        
                    </>
                    )}
                    </>
                    
                    {
                        this.state.ledSettingModal &&
                        <LedBoxContainer>
                            <LedModal>
                                <LedModalHeader>
                                    <LedHeaderText data-test-id="scheduleSelectionSetModalHeading">Schedule Selection Set</LedHeaderText>
                                    <LedModalCloseBox>
                                        <LedModalCloseButton data-test-id="closeLedSettingButton" onClick={() => this.closeLedSettingModal()}>
                                            <LedModalCloseButtonText>x</LedModalCloseButtonText>
                                        </LedModalCloseButton>
                                    </LedModalCloseBox>
                                </LedModalHeader>
                                <LedModalHeaderDivider />
                                <LedModalBox>
                                    <BoxMaterial style={{ marginLeft: "10px" }}>
                                        <ModalSubHeadingBox>
                                            <LedModalHeadingText>Selection Set Name</LedModalHeadingText>
                                        </ModalSubHeadingBox>
                                        <StyledSelect
                                            value={this.state.ledSelectSelectionSet}
                                            onChange={(event: { target: { value: string | unknown} }) => this.selectLedSelectionSet(event)}
                                            data-test-id="scheduleSelectionSetSelectBox"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                      backgroundColor: '#1f1f21',
                                                      color: "#fff" 
                                                    },
                                                  },
                                            }}
                                            displayEmpty
                                        >   
                                            {this.state.selectionSetsForPanel.map((panel:SelectionSet) => {
                                                return(
                                                    <StyledMenuItem key={panel.id} value={panel.id}>
                                                    {panel.attributes.name}
                                                    </StyledMenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                    </BoxMaterial>
                                    <BoxMaterial>
                                        <ModalSubHeadingBox>
                                            <LedModalHeaderIconBox>
                                                <LedModalHeaderIcon
                                                    src={selectionSetSettingsLight}
                                                />
                                            </LedModalHeaderIconBox>
                                            <LedModalHeadingText data-test-id="LEDBrightnessTestId">LED Brightness</LedModalHeadingText>
                                        </ModalSubHeadingBox>
                                    </BoxMaterial>
                                    <LedModalSliderContainer>
                                        <Slider
                                            data-test-id="ledSelectionSetBrightnessSlider"
                                            style={{
                                                marginLeft: "8px",
                                                width: 390,
                                                height: 40,
                                            }}
                                            minimumValue={0}
                                            maximumValue={7}
                                            minimumTrackTintColor="#c4edc8"
                                            maximumTrackTintColor="#36343b"
                                            thumbTintColor="#c4edc8"
                                            onSlidingComplete={this.changeScreenBrightness}
                                            value={this.state.sliderBrightnessValue}
                                        />
                                    </LedModalSliderContainer>
                                    <ModalSubHeadingBox>
                                        <LedModalHeaderIconBox>
                                            <LedModalHeaderIcon
                                                src={selectionSetSettingsTimer}
                                            />
                                        </LedModalHeaderIconBox>
                                        <LedModalHeadingText data-test-id="screenDisplayTimeHeading">Screen Display Time</LedModalHeadingText>
                                    </ModalSubHeadingBox>
                                    <LedModalSliderContainer>
                                        <Slider
                                            data-test-id="ledSelectionSetTimerSlider"
                                            style={{
                                                marginLeft: "8px",
                                                width: 390,
                                                height: 40,
                                            }}
                                            minimumValue={0}
                                            maximumValue={12}
                                            minimumTrackTintColor="#c4edc8"
                                            maximumTrackTintColor="#36343b"
                                            thumbTintColor="#c4edc8"
                                            onSlidingComplete={this.changeScreenTimer}
                                            value={this.state.sliderValueTimer}
                                        />
                                    </LedModalSliderContainer>
                                    <ModalSubHeadingBox>
                                        <LedModalHeaderIconBox>
                                            <LedModalHeaderIcon
                                                src={selectionSetSettingsCurrency}
                                            />
                                        </LedModalHeaderIconBox>
                                        <LedModalHeadingText data-test-id="currencyHeadingTestId">Currency</LedModalHeadingText>
                                    </ModalSubHeadingBox>
                                    <CurrencyBox>
                                        <BoxContainerSelect>
                                            <CountryStyledSelect
                                                value={this.state.selectedCurrencyType}
                                                onChange={(event: { target: { value: string | unknown} }) => this.selectLedCurrencyCode(event)}
                                                data-test-id="selectCurrencyCodeBox"
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    },
                                                    getContentAnchorEl: null,
                                                    PaperProps: {
                                                        style: {
                                                        backgroundColor: '#1f1f21',
                                                        color: "#fff" 
                                                        },
                                                    },
                                                }}
                                            >
                                                {this.state.currencyCodes.map((country:CountryData) => {
                                                    return(
                                                        <CountryStyledMenuItem key={country.id} value={country.currency_code}>
                                                        <CountryFlag>{country.emoji_flag}</CountryFlag>
                                                        <CurrencyText>{country.text}</CurrencyText>
                                                        </CountryStyledMenuItem>
                                                    )
                                                })}
                                        </CountryStyledSelect>
                                        </BoxContainerSelect>
                                        <ShowDifferenceBox>
                                        <BoxToggleWrapper>
                                            <ContainerSwitchBox style={this.state.isActiveCurrency ? { backgroundColor: '#c4edc8', justifyContent: "flex-end" } : { backgroundColor: 'grey', justifyContent: "flex-start" }}
                                                onClick={() => this.toggleSwitch()}
                                                data-test-id="ledSettingShowDollerDiffrenceButton"
                                            >
                                                <ContainerSwitchCircle />
                                            </ContainerSwitchBox>
                                        </BoxToggleWrapper>
                                        <ShowDifferenceText>Show $ Difference</ShowDifferenceText>
                                        </ShowDifferenceBox>
                                    </CurrencyBox>
                                    <ModalSubHeadingBox>
                                        <LedModalHeaderIconBox>
                                            <LedModalHeaderIcon
                                                src={selectionSetSettingsUnits}
                                            />
                                        </LedModalHeaderIconBox>
                                        <LedModalHeadingText data-test-id="weatherUnitsTestId">Weather Units</LedModalHeadingText>
                                    </ModalSubHeadingBox>
                                    <ContainerCF2>
                                        <RadioButton
                                            isActive={this.state.selectedUnit !== 'fahrenheit'}
                                            onPress={() => this.selectUnit('celsius')}
                                        />
                                        <CelsiusText>°C - Celsius</CelsiusText>
                                        <RadioButton
                                            isActive={this.state.selectedUnit === 'fahrenheit'}
                                            onPress={() => this.selectUnit('fahrenheit')}
                                        />
                                        <FahrenheitText>°F - Fahrenheit</FahrenheitText>
                                    </ContainerCF2>
                                    <BoxMaterial>
                                        <ModalSubHeadingBox>
                                            <LedModalHeaderIconBox>
                                                <LedModalHeaderIcon
                                                    src={imgCalendar}
                                                />
                                            </LedModalHeaderIconBox>
                                            <LedModalHeadingText>Schedule Type</LedModalHeadingText>
                                        </ModalSubHeadingBox>
                                    </BoxMaterial>
                                    <ToggleScheduleDropdownBox>
                                        <ScheduleTypeDropDown>
                                            <ToggleScheduleDropdown
                                                data-test-id="ledSettingScheduleTypeButton"
                                                onClick={this.toggleScheduleDropdown}
                                            >
                                                <ScheduleTypeDropDownText data-test-id="ledSettingScheduleTypeButtonText">{this.state.ledScheduleType === 'custom' ? 'Custom' : 'Monday - Friday'}</ScheduleTypeDropDownText>
                                            </ToggleScheduleDropdown>
                                        </ScheduleTypeDropDown>

                                        {this.state.ledScheduleDropdown &&
                                            <LedScheduleDropdown>
                                                <LedDropDownItemBox>
                                                    <LedScheduleDropdownButton data-test-id="ledScheduleDropdownCustombutton" onClick={() => this.setLedScheduleTypeFunc('custom')}>
                                                        <LedDropDownItemText data-test-id="ledScheduleDropdownCustomHeading">Custom</LedDropDownItemText>
                                                    </LedScheduleDropdownButton>
                                                </LedDropDownItemBox>
                                                <LedDropDownItemDivider />
                                                <LedDropDownItemBox>
                                                    <LedScheduleDropdownButton onClick={() => this.setLedScheduleTypeFunc('mon-fri')}>
                                                        <LedDropDownItemText>Monday - Friday</LedDropDownItemText>
                                                    </LedScheduleDropdownButton>
                                                </LedDropDownItemBox>
                                            </LedScheduleDropdown>}
                                    </ToggleScheduleDropdownBox>

                                    {this.state.ledScheduleType === 'custom' && <BoxMaterial><BoxMaterial>
                                        <ModalSubHeadingBox>
                                            <ModalDayHeadingText>Days</ModalDayHeadingText>
                                        </ModalSubHeadingBox>
                                    </BoxMaterial>
                                        <LedModalDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('monday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('monday')} data-test-id="ledScheduleCustomMondaybutton">
                                                    <SelectDaysText data-test-id="mondayText" style={{ color: this.checkActiveDay() }}>Mo</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('tuesday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('tuesday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('tuesday') ? '#232323' : 'white' }}>Tu</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('wednesday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('wednesday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('wednesday') ? '#232323' : 'white' }}>We</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('thursday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('thursday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('thursday') ? '#232323' : 'white' }}>Th</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('friday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('friday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('friday') ? '#232323' : 'white' }}>Fr</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('saturday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('saturday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('saturday') ? '#232323' : 'white' }}>Sa</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                            <SelectDaysBox style={{
                                                backgroundColor: this.state.ledScheduleDaysArray.includes('sunday') ? '#38ff43' : '#313132'
                                            }}>
                                                <SelectDayButton onClick={() => this.setLedScheduleDay('sunday')}>
                                                    <SelectDaysText style={{ color: this.state.ledScheduleDaysArray.includes('sunday') ? '#232323' : 'white' }}>Su</SelectDaysText>
                                                </SelectDayButton>
                                            </SelectDaysBox>
                                        </LedModalDaysBox></BoxMaterial>}
                                    <LedModalTimeBox>
                                        <LedTimeDropDownBox>
                                            <TimeHeadingText>Time</TimeHeadingText>
                                            <ToggleLedTimeDropdown data-test-id="ledSettingTimeDropDownButton" onClick={() => this.toggleLedTimeDropdown()}>
                                                <IsExpandedIcon
                                                    src={imgExpand}
                                                    style={{ transform: this.state.ledTimeDropdown === false ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                />
                                            </ToggleLedTimeDropdown>
                                        </LedTimeDropDownBox>
                                        {this.state.ledTimeEndModal &&
                                            <TimeSchedulModal>
                                                <TimeSchedulModalHeadning data-test-id="ledSettingEndTimeModalHeading">End Time</TimeSchedulModalHeadning>
                                                <TimeSchedulModalCloseBtn onClick={() => this.ledTimeSelectEnd(false)}>
                                                    X
                                                </TimeSchedulModalCloseBtn>
                                                <form style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                                    <ProvidehoursText>Provide hours 0-23</ProvidehoursText>
                                                    <br />
                                                    <ProvidehoursInput data-test-id="ledSettingEndTimeMinInput" type='number' min='0' max='23' onChange={(event) => this.setLedTimeEndHours(event.target.value)} placeholder={'Provide start hour...'} value={this.state.ledTimeEndHours} />
                                                    <ProvidehoursText>Provide minutes 0-59</ProvidehoursText>
                                                    <br />
                                                    <ProvidehoursInput data-test-id="ledSettingEndTimeHourInput" type='number' min='0' max='59' onChange={(event) => this.setLedTimeEndMinutes(event.target.value)} placeholder={'Provide start minutes...'} value={this.state.ledTimeEndMinutes} />
                                                </form>
                                                <TimeSchedulModalSaveButton data-test-id="ledSettingEndTimeSaveButton" onClick={() => this.ledFormatEndTime(this.state.ledTimeEndHours, this.state.ledTimeEndMinutes)}>
                                                    SAVE
                                                </TimeSchedulModalSaveButton>
                                            </TimeSchedulModal>
                                        }
                                        {this.state.ledTimeStartModal &&
                                            <TimeSchedulModal>
                                                <TimeSchedulModalHeadning data-test-id="ledSettingStartTimeModalHeading">Start Time</TimeSchedulModalHeadning>
                                                <TimeSchedulModalCloseBtn onClick={() => this.ledTimeSelectStart(false)}>
                                                    X
                                                </TimeSchedulModalCloseBtn>
                                                <form style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                                    <ProvidehoursText>Provide hours 0-23</ProvidehoursText>
                                                    <br />
                                                    <ProvidehoursInput data-test-id="ledSettingStartTimeMinInput" type='number' min='0' max='23' onChange={(event) => this.setLedTimeStartHours(event.target.value)} placeholder={'Provide start hour...'} value={this.state.ledTimeStartHours} />
                                                    <ProvidehoursText>Provide minutes 0-59</ProvidehoursText>
                                                    <br />
                                                    <ProvidehoursInput data-test-id="ledSettingStartTimeHourInput" type='number' min='0' max='59' onChange={(event) => this.setLedTimeStartMinutes(event.target.value)} placeholder={'Provide start minutes...'} value={this.state.ledTtimeStartMinutes} />
                                                </form>
                                                <TimeSchedulModalSaveButton data-test-id="ledSettingStartTimeSaveButton" onClick={() => this.ledFormatStartTime(this.state.ledTimeStartHours, this.state.ledTtimeStartMinutes)}>
                                                    SAVE
                                                </TimeSchedulModalSaveButton>
                                            </TimeSchedulModal>
                                        }
                                        {this.state.ledTimeDropdown && <><SelectTimeBox>
                                            <SelectTimeBoxHeadingText data-test-id="ledSettingTimeDropDownStartHeading">Start</SelectTimeBoxHeadingText>
                                            <SelectedTimeBox>
                                            <SelectedTime>{this.state.ledFullTimeStart}</SelectedTime>
                                            <SelectTimeButton data-test-id="ledSettingStartTimeModalButton" onClick={() => this.ledTimeSelectStart(true)}>
                                                Select time
                                            </SelectTimeButton></SelectedTimeBox>
                                        </SelectTimeBox>
                                            <SelectTimeBox>
                                                <SelectTimeBoxHeadingText>End</SelectTimeBoxHeadingText>
                                                <SelectedTimeBox>
                                                <SelectedTime>{this.state.ledFullTimeEnd}</SelectedTime>
                                                <SelectTimeButton data-test-id="ledSettingEndTimeModalButton" onClick={() => this.ledTimeSelectEnd(true)}>
                                                    Select time
                                                </SelectTimeButton>
                                                </SelectedTimeBox>
                                            </SelectTimeBox></>}
                                    </LedModalTimeBox>
                                </LedModalBox>
                                <SaveChangesButtonBox>
                                    <SaveChangesButton data-test-id="saveLedScheduleSelectionSetButton" onClick={() => this.saveLedScheduleSelectionSet()}>
                                        SAVE CHANGES
                                    </SaveChangesButton>
                                </SaveChangesButtonBox>
                            </LedModal>
                        </LedBoxContainer>
                    }
                </ContainerContent>
                <ModalSuccessStyled
                open={this.state.applyToPanelModal}
                onClose={this.closeApplyToPanelModal}
                data-test-id="applyToPanelModal"
                >
                    <ModalContaint>
                        <ModalCloseIconButton onClick={() => this.closeApplyToPanelModal()} data-test-id="modalCloseIconButton">
                            <ModalCloseIcon src={closeWhite} />
                        </ModalCloseIconButton>
                        <SuccessSendImage src={successSend} />
                        <SuccessText data-test-id="modalSuccessText">{this.state.responseErrormsg?this.state.responseErrormsg:"Selection set was successfully applied to the panel"}</SuccessText>
                      

                    </ModalContaint>
                </ModalSuccessStyled>
                <ModalSuccessStyled
                    open={this.state.editPanelNameSuccessModal}
                    onClose={this.closeEditPanelNameModal}
                    data-test-id="applyToPanelModal"
                >
                    <ModalContaint>
                        <ModalCloseIconButton onClick={() => this.closeEditPanelNameModal()} data-test-id="editModalCloseIconButton">
                            <ModalCloseIcon src={closeWhite} />
                        </ModalCloseIconButton>

                        <SuccessSendImage src={successSend} />
                        <SuccessText data-test-id="editPanelNameModalSuccessText">Glance name updated successfully</SuccessText>
                    </ModalContaint>
                </ModalSuccessStyled>
                <ModalSuccessStyled
                    open={this.state.editSelectionSetNameModal}
                    onClose={this.closeEditSelectionSetNameModal}
                    data-test-id="applyToPanelModal"
                >
                    <SelectionSetModalContaint>
                        <ModalCloseIconButton onClick={() => this.closeEditSelectionSetNameModal()} data-test-id="closeEditSelectionSetNameModalButton">
                            <ModalCloseIcon src={closeWhite} />
                        </ModalCloseIconButton>
                        <CustomTextLabel data-test-id="selectionSetNameModalLabel">Selection set name</CustomTextLabel>
                        <CustomTextIput data-test-id="editSelectionSetNameInputBox" placeholder="Enter selection set name" value={this.state.editableSelectionSetName} onChange={this.editSelectionSetName}/>
                        <SaveChangesButton disabled={this.state.editableSelectionSetName.length == 0} onClick={() => this.saveEditSelectionSetName()} data-test-id="editableSelectionSetNameSaveButton">SAVE</SaveChangesButton>
                    </SelectionSetModalContaint>
                </ModalSuccessStyled>
            </View>
        )
    }
}

const RadioButton = ({isActive, onPress}: {isActive:boolean,onPress:ButtonClickHandler}) => {
    return (
     <RadioButtonStyle onClick={()=> onPress()} 
     style={isActive ? {backgroundColor:"#c4edc8",outline:"2px solid #c4edc8",border:"2px solid #1f1f21"}:{backgroundColor:"#1f1f21",outline:"2px solid #808080"}}
     ></RadioButtonStyle>
    );
  };


const LedBoxContainer = styled(BoxMaterial)({
    position: "fixed",
    width: '100vw',
    height: '100vh',
    left: '0',
    top: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
});

const LedModal = styled(BoxMaterial)({
    width: '432px',
    height: '557px',
    backgroundColor: '#1f1f21',
    borderWidth: 3,
    borderColor: 'transparent'
});

const LedModalHeader = styled(BoxMaterial)({
    width: '396px',
    height: '28px',
    marginLeft: '16px',
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const LedHeaderText = styled(Typography)({
    width: '224px',
    height: '28px',
    left: '16px',
    top: 'calc(50% - 28px/2 - 194px)',
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    color: '#C4EDC8'
});

const LedModalBox = styled(BoxMaterial)({
    width: '432px',
    height: '420px',
    backgroundColor: '#1f1f21',
    borderWidth: 3,
    borderColor: 'transparent',
    overflowY:"scroll"
});

const LedModalCloseBox = styled(BoxMaterial)({
    fontSize: 40,
    marginLeft: 'auto',
    marginTop: 'calc(50% - 20px/2 - 196px)',
    color: 'grey'
});

const LedModalHeaderDivider = styled(BoxMaterial)({
    width: '400px',
    height: 1,
    marginLeft: '16px',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.08)',
    marginTop: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.08)'
});

const LedModalHeaderIconBox = styled(BoxMaterial)({
    width: 16,
    height: 16,
    marginLeft: 16
});

const LedModalHeaderIcon = styled("img")({
    width:"100%",
    height:"100%",
    objectFit:"contain"
});

const LedModalHeadingText = styled(Typography)({
    width: 210,
    marginLeft: '8px',
    fontFamily: 'Roboto',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF'
});

const LedModalSliderContainer = styled(BoxMaterial)({
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    marginLeft: '8px',
    borderColor: 'transparent'
});

const SaveChangesButtonBox = styled(BoxMaterial)({
    display: 'flex',
    flexDirection: 'row',
    padding:15, 
    justifyContent: 'flex-end'
});

const ScheduleTypeDropDown = styled(BoxMaterial)({
    width: 390,
    borderWidth: 1,
    borderColor: 'rgba(236, 236, 238, 0.1)',
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 4,
    backgroundColor: '#2b2b2e'
});

const ScheduleTypeDropDownText = styled(Typography)({
    color: 'white',
    marginLeft: 16,
    fontSize: 16
});

const BoxToggleWrapper = styled(BoxMaterial)({
    height: '24px',
    marginLeft: '16px',
    marginTop: '-4px'
});

const BoxContainerSelect = styled(BoxMaterial)({
    width: '194px',
    height: '40px',
    backgroundColor: 'transparent',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(236, 236, 238, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});

const LedModalCloseButton = styled('button')({
    backgroundColor:"transparent",
    border:"none"
});

const LedModalCloseButtonText = styled(Typography)({
    fontSize: 25, 
    color: '#79797a'
});

const ModalSubHeadingBox = styled(BoxMaterial)({
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center', 
    marginTop: 16, 
    height:30
});

const SaveChangesButton = styled('button')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: '165px',
    height: '35px',
    backgroundColor: '#38FF4C',
    borderRadius: 4,
    fontSize:"16px",
    fontFamily:"Roboto",
    flexGrow: 0,
    border:"none",
    color: '#232323',
    fontWeight:500,
    cursor:"pointer",
    '&:disabled': {
        cursor:"no-drop",
        backgroundColor: '#38FF4C80'
    }
});

const LedModalDaysBox = styled(BoxMaterial)({
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: 3, 
    height: 40, 
    width: 390, 
    marginLeft: 'auto', 
    marginRight: 'auto',
    borderRadius: 4,
    justifyContent: 'space-between',
    backgroundColor: '#1f1f21'
});

const LedModalTimeBox = styled(BoxMaterial)({
    display: 'flex', 
    flexDirection: "column", 
    alignItems: 'center', 
    height: 40, 
    width: 390, 
    marginLeft: 'auto', 
    marginRight: 'auto',
    justifyContent: 'space-between',
    marginTop: 20
});

const LedTimeDropDownBox = styled(BoxMaterial)({
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    height: 40, 
    width: 390, 
    justifyContent: 'space-between',
    margin: "10px auto"
});


const SelectTimeBox = styled(BoxMaterial)({
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    height: 40, 
    width: 390, 
    marginLeft: 'auto', 
    marginRight: 'auto',
    justifyContent: 'space-between',
    margin:"10px 0"
});

const SelectDaysBox = styled(BoxMaterial)({
    width: 41, 
    height: 40, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    borderRadius: 4
});

const SelectDayButton = styled('button')({
    border:"none",
    height:"100%",
    width:"100%",
    backgroundColor:"transparent"
});

const SelectDaysText = styled(Typography)({
    fontSize: 16,
});

const LedScheduleDropdown = styled(BoxMaterial)({
    width: 390, 
    height: 100, 
    position: 'absolute', 
    top: '50px', 
    left: '20px', 
    zIndex: 99,
    borderRadius:5,
    overflow:"hidden"
});

const CurrencyBox = styled(BoxMaterial)({
    display: 'flex',
    flexDirection: 'row',
    marginTop:16, 
    justifyContent: 'flex-start',
    alignItems: 'center'
});


const CurrencyText = styled(Typography)({
    color:"#ffffff",
    fontWeight:600,
});

const CountryFlag = styled(Typography)({
    fontSize:"16px",
});

const ContainerSwitchBox = styled(BoxMaterial)({
    width: 50, 
    height: "20px", 
    borderRadius: 15, 
    display:"flex",
    padding: 5,
});

const ContainerSwitchCircle = styled(BoxMaterial)({
    width: 20, 
    height: 20,
    borderRadius: 10, 
    backgroundColor: '#2b2b2e' 
});

const TimeHeadingText = styled(Typography)({
    fontSize: 14, 
    color: 'white',
    fontWeight:500,
    fontFamily:"Roboto"
});

const IsExpandedIcon = styled('img')({
    width: 16, 
    height: 16
});

const ModalDayHeadingText = styled(Typography)({
    color:"#ffffff",
    marginLeft:"15px"
});

const ToggleScheduleDropdown = styled('button')({
    border:"none",
    height:"100%",
    width:"100%",
    backgroundColor:"transparent",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start"
});

const ToggleScheduleDropdownBox = styled(BoxMaterial)({
    position:"relative",
    marginTop:10
});

const LedScheduleDropdownButton = styled('button')({
    border:"none",
    height:"50px",
    width:"100%",
    backgroundColor:"transparent",
    display:"flex"
});

const LedDropDownItemBox = styled(BoxMaterial)({
    width:390, 
    backgroundColor: '#2b2b2e',
    marginLeft: 'auto', 
    marginRight: 'auto'
});

const LedDropDownItemDivider = styled(BoxMaterial)({
    height:1,
    backgroundColor:"#808080"
});

const SelectTimeBoxHeadingText = styled(Typography)({
    fontSize: 14, 
    color: 'white',
    fontWeight:500,
    fontFamily:"Roboto"
});

const SelectTimeButton = styled('button')({
    border:"none",
    color: 'rgba(56, 255, 76, 1)',
    backgroundColor:"transparent",
    fontWeight:500,
    fontSize:"14px",
    fontFamily:"Roboto",
});

const RadioButtonStyle = styled('button')({
    height:"20px",
    width:"20px",
    backgroundColor: '#000',
    borderRadius: "50%",
    display:"flex",
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    boxSizing:"border-box",
    border:"none"
});

const TimeSchedulModal = styled(BoxMaterial)({
    borderRadius: 4,
    border:"1px solid #000",
    marginLeft: '0px', 
    marginRight: 'auto', 
    position: 'absolute', 
    zIndex: 90, 
    width: 300, 
    height: 322, 
    backgroundColor: '#1f1f21', 
    top: '190px'
});

const TimeSchedulModalHeadning = styled(Typography)({
    fontSize: 20,
    color: 'white', 
    position: 'absolute', 
    top: '20px', 
    left: '20px'
});

const ProvidehoursText = styled(Typography)({
    color: 'white',
    fontSize: 14,
    fontWeight:400,
    fontFamily:"Roboto", 
    marginTop: 16, 
    paddingLeft: 20, 
    paddingBottom: 8
});

const ProvidehoursInput = styled('input')({
    border: 0, 
    paddingLeft: 16, 
    paddingRight: 10, 
    borderWidth: 1, 
    color: 'white', 
    width: 200, 
    height: 40, 
    borderRadius: 4, 
    marginLeft: 20, 
    backgroundColor: '#232323'
});

const ContainerCF2 = styled(BoxMaterial)({
    display:"flex",
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginLeft: 16,
});

const ToggleLedTimeDropdown = styled("button")({
    border:"none",
    backgroundColor:"transparent"
});

const TimeSchedulModalCloseBtn = styled("button")({
    marginRight: 20, 
    marginTop: 20, 
    marginLeft: 'auto',
    border:"none",
    fontSize: 20,
    color: 'grey',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor:"transparent"
});

const TimeSchedulModalSaveButton = styled('button')({
    width: 80,
    height: 40, 
    borderRadius: 4, 
    backgroundColor: '#38ff4c', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginTop: 20, 
    marginLeft: 'auto', 
    marginRight: 20,
    fontSize: 16,
    color: 'black',
    border:"none"
});

const CelsiusText = styled(Typography)({
    marginRight: '100px',
    fontSize:"14px",
    fontWeight:500,
    color:"rgba(255,255,255,0.75)"
});

const FahrenheitText = styled(Typography)({
    fontSize:"14px",
    fontWeight:500,
    color:"rgba(255,255,255,0.75)"
});

const LedDropDownItemText = styled(Typography)({
    marginTop: 13,
    marginLeft: 15, 
    color: 'white', 
    fontSize: 16
});

const ShowDifferenceText = styled(Typography)({
    color: 'white',
    marginLeft: 10,
    fontSize:"14px",
    fontWeight:500
});

const StyledSelect = styled(Select)({
    width: "390px",
    color: "#ffffff",
    backgroundColor: "#1f1f21",
    marginLeft: "10px",
    border: "1px solid #808080",
    borderRadius: "5px",
    padding: "5px 10px",
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    },
    "&&::before":{
        content:"none"
    },
    "&&::after":{
        content:"none"
    },
    "& .MuiSelect-select": {
      backgroundColor: "#1f1f21",
    }
});
  
const StyledMenuItem = styled(MenuItem)({
    color: "#ffffff",
    backgroundColor: "#1f1f21 !important",
    "&. MuiListItem-root.Mui-selected": {
        backgroundColor: "#1f1f21",
    },
    "&. MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#1f1f21",
    }
});

const CountryStyledSelect = styled(Select)({
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#1f1f21",
    marginLeft: "10px",
    border: "1px solid #808080",
    borderRadius: "5px",
    padding: "5px 10px",
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    },
    "&&::before":{
        content:"none"
    },
    "&&::after":{
        content:"none"
    },
    "& .MuiSelect-select": {
      backgroundColor: "#1f1f21",
      display:"flex",
      columnGap:"10px",
    }
});
  
const CountryStyledMenuItem = styled(MenuItem)({
    display:"flex",
    alignItems:"center",
    columnGap:"10px",
    color: "#ffffff",
    backgroundColor: "#1f1f21 !important",
    "&. MuiListItem-root.Mui-selected": {
        backgroundColor: "#1f1f21",
        display:"flex",
        columnGap:"10px",
    },
    "&. MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#1f1f21",
    }
});

const ShowDifferenceBox = styled(BoxMaterial)({
    display:"flex",
    alignItems:'center',
    height:"40px"
}); 

const PlayButton = styled("button")({
    backgroundColor:"transparent",
    border:"none",
    height:24,
    width:24,
    marginRight:16
});

const PlayButtonIcon = styled("img")({
    height:15,
    width:24,
   objectFit:"contain"
});

const ModalSuccessStyled = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
});

const ModalContaint = styled(BoxMaterial)({
    width:500,
    height:200,
    backgroundColor: "#1f1f21",
    padding: 24,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"space-between"
});

const ModalCloseIcon = styled("img")({
    marginLeft: "auto",
    marginBottom: 40,
    width: 24, 
    height: 24
});

const ModalCloseIconButton = styled("button")({
    marginLeft: "auto",
    width: 24, 
    height: 24,
    marginTop:10,
    backgroundColor:"transparent",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border:"none"
});

const SuccessSendImage = styled("img")({
    width: 107,
    height: 102, 
    marginLeft: "auto",
    marginRight: "auto"
});

const SuccessText = styled(Typography)({
    color: "#FFF",
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign:"center"
});

const AddSelectionText = styled(Typography)({
    fontSize: 20,
    color: "#FFFFFF66",
    fontFamily: "Roboto Condensed"
});

const CreateSelectionSetButton = styled("button")({
    borderRadius: 4,
    backgroundColor: '#38FF4c',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    height: 30,
    border:"none",
    color:"#232323",
    cursor:"pointer",
    '&:disabled': {
        cursor:"no-drop",
        backgroundColor: '#38FF4C80'
    }
});

const PanleNameTextBox = styled(BoxMaterial)({
    display:"flex",
    alignItems:"center",
    border: "1px solid #3f3f41",
    borderRadius: "4px",
    backgroundColor: "#2b2b2e"
});

const PanelEditButton = styled(BoxMaterial)({
    border:"none",
    backgroundColor:"transparent",
    justifyContent:"center",
    alignItems:"center",
    marginLeft:"20px",
    cursor:"pointer",
    width:"40px"
});
                          
const PanelEditIcon = styled("img")({
    height:"20px",
    width:"20px"
});

const PanelNameSaveButton = styled("button")({
    color:"#ffffff",
    fontSize:"16px",
    fontWeight:400,
    backgroundColor:"transparent",
    height:"100%",
    width:"100%",
    border:"none",
    cursor:"pointer"
});

const GlanceNameText = styled(Typography)({
    fontSize: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#fff"
});

const SelectedTimeBox = styled(BoxMaterial)({
    display:"flex",
    columnGap:"10px"
});

const SelectedTime = styled(Typography)({
    color:"#fff",
    ffontSize: 14,
    fontWeight:500,
    fontFamily:"Roboto"
});

const CustomTextIput = styled("input")({
    width:"100%",
    border: "1px solid #3f3f41",
    borderRadius: "4px",
    backgroundColor: "#2b2b2e",
    color: "#fff",
    height: "40px",
    fontSize: "16px",
    padding: "10px",
    "& :placeholder":{
        color: "rgba(255, 255, 255, 0.4)"
    }
});

const CustomTextLabel = styled(Typography)({
    color:"#fff",
    fontSize:"16",
    fontWeight:500,
    fontStyle:"Roboto"
});

const SelectionSetModalContaint =  styled(BoxMaterial)({
    width:500,
    height:200,
    backgroundColor: "#1f1f21",
    padding: 24,
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between"
});

const SelectionSetImageBox = styled(BoxMaterial)({
    width: 292, 
    height: 146, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center'
});

const SelectionSetNameText = styled(Typography)({
    color: 'white', 
    fontSize: 18, 
    width: '100%',
    padding:"5px 10px",
    boxSizing:"border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
});