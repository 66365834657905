import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import styled from 'styled-components';
import { 
    imgDecor, 
    imgDashboardActive, 
    imgSettings, 
    imgUpgrade,
    imgSearch,
    imgAvatar,
    imgCheckedCircle,
    imgClose
} from '../../blocks/dashboard/src/assets'
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styledNative from 'styled-components/native';
import { StripeCard } from "./StripeCard";

interface UpgradeModalProps {
    showUpgradeModal: (type: string) => void;
    closeUpgradeModal: () => void;
}

const CARD_STYLE = {
    style: {
      base: {
        "::placeholder": {
          color: "#aab7c4"
        },
        borderRadius: '4px',
        border: '1px solid rgba(236, 236, 238, 0.10)',
        background: '#2B2B2E',
        color: 'white',
        width: '100%',
        height: '40px',
        paddingLeft: '12px',
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

export default function PaymentModal({
  showUpgradeModal, 
  closeUpgradeModal, 
}: UpgradeModalProps) {
    const [cardHolderName, setCardHolderName] = useState('')
    const [creditCard, setCreditCard] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [cvv, setCvv] = useState('')
    const [error, setError] = useState<null | string>(null);

    // const stripePromise = loadStripe('pk_test_51O0ELzC8lZiDbPcfl0WUgBZwoEhRG9ULHiTcamAwvrp0nMp2sDKKmCoDuvdEd9ByZJrytJPDSkYOTaucaIggxPE300yiniYBEc');

    const makePayment = async () => {
        const domain = window.location.href.includes('.uat.')
        ? 'https://glancestartup-373059-ruby.b373059.uat.eastus.az.svc.builder.ai/'
        : window.location.href.includes('.stage.') 
            ? 'https://glancestartup-373059-ruby.b373059.stage.eastus.az.svc.builder.ai/'
            : 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'

        // fetch(
        //     `${domain}account_block/me`,
        //     {
        //         method: "POST",
        //         headers: {
        //             Accept: 'application/json',
        //             token: localStorage.getItem('authToken') as string
        //         }
        //     }
        // )
        // .then(response => response.json())
        // .then(data => setLoggedUserEmail(data.data.attributes.email))
    }
 
    return (
        <ModalStyled data-modal='modal'>
            {/* Modal popup */}
            <View style={styles.modal}>
                    <>
                        <View style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                            <Text style={[styles.text, styles.title]}>
                                Upgrade to <Text style={[styles.text, styles.title, styles.colorTitle]}>GLANCE Premium</Text>
                            </Text>
                            <TouchableOpacity onPress={closeUpgradeModal}>
                                <Image source={imgClose} style={{width: 20, height: 20}} />
                            </TouchableOpacity>                               
                        </View>
                        <Text style={[styles.text, {marginTop: 10, marginBottom: 10}]}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Etiam odio magna, tempus mollis porta vel.
                        </Text>
                        
                      
                        <BlockContent id='payment-data'>

                          <BlockLabel>
                              Cardholder's Name
                          </BlockLabel>
                          <BlockInput 
                            placeholder="John Doe"
                            value={cardHolderName}
                            onChange={(event: any) => setCardHolderName(event.target.value && event.target.value.replace(/[0-9]/g, ''))}  
                            // style={{
                            //   border: billingCardholderNameError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            // }}
                          />

                          <BlockLabel>
                            Credit Card Number
                          </BlockLabel>
                          <BlockInput 
                            placeholder="XXXX XXXX XXXX XXXX"
                            value={creditCard && creditCard
                              .replace(/\D/g, '') 
                              .replace(/(.{4})/g, '$1 ').trim()
                            }
                            onChange={(event: any) => {
                              let formattedValue = event.target.value
                              // Ograniczamy do 16 znakow plus 3 spacje
                              if (formattedValue.length > 19) {
                                formattedValue = formattedValue.substring(0, 19);
                              }
                              
                              setCreditCard(formattedValue)
                            }}      
                            // style={{
                            //   border: this.state.billingCreditError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            // }}
                          />

                          <RowTwoInputs>

                            <WrapperOnInput>
                              <BlockLabel>
                                Expiry Date
                              </BlockLabel>
                              <BlockInput 
                                placeholder="MM / YY" 
                                value={expiryDate && expiryDate.replace(
                                  /[^0-9]/g, '' // Usuwamy wszystko co nie jest cyfra
                                ).replace(
                                  /^([1-9]\/|[2-9])$/g, '0$1 / ' // Dodajemy 0 na pocztek je[li jest to pojedyncza cyfra nie bedaca 1
                                ).replace(
                                  /^(1{1})([3-9]{1})$/g, '0$1 / $2' // Dodajemy 0 je[li pierwsza cyfra jest 1 a druga jest wieksza ni| 2
                                ).replace(
                                  /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1 / $2' // Formatujemy MM / YY
                                )} 
                                onChange={(event: any) => {
                                  let formattedValue = event.target.value
                                  // Ograniczamy do MM / YY
                                  if (formattedValue.length > 4) {
                                    formattedValue = formattedValue.substring(0, 7);
                                  }
                              
                                  setExpiryDate(formattedValue)
                                }}
                                // style={{
                                //   border: this.state.billingExpiryError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                                // }}
                              />
                            </WrapperOnInput>

                            <WrapperOnInput>
                              <BlockLabel>
                                CVV
                              </BlockLabel>
                              {/* <BlockInput 
                                placeholder="XXX"
                                value={this.state.billingCvv}
                                onChange={(event: any) => {
                                  let formattedValue = event.target.value && event.target.value.replace(/[a-zA-Z]/g, '');
                                  // Ograniczamy do 3 znakow
                                  if (formattedValue.length > 3) {
                                    formattedValue = formattedValue.substring(0, 3)
                                  }

                                  this.setBillingCvv(formattedValue)
                                }}
                                style={{
                                  border: this.state.billingCvvError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                                }}
                              /> */}
                            </WrapperOnInput>
                          </RowTwoInputs>




                        </BlockContent>



                        <View style={styles.buttons}>
                            <TouchableOpacity 
                                style={[styles.button, {backgroundColor: '#313132', marginRight: 12}]}
                                onPress={closeUpgradeModal}
                            >
                                <Text style={[styles.text, styles.buttonText, {color: '#838384'}]}>NO THANKS</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button} onPress={makePayment}>
                                <Text style={[styles.text, styles.buttonText]}>UPGRADE</Text>
                            </TouchableOpacity>
                        </View>
                    </>
            </View>
        </ModalStyled>
        


    )
}

const ModalStyled = styledNative.View`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`

const styles = StyleSheet.create({
    modal: {
        width: 486,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: -100,
    },
    title: {
        fontSize: 24,
        fontFamily: 'Roboto Condensed',
        marginBottom: 12,
    },
    colorTitle: {
        color: '#38FF4C',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 16
    },
    textLink: {
        color: 'rgba(196, 237, 200, 1)',
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    label: {
        fontSize: 16,
        color: 'white',
        marginTop: 24,
        marginBottom: 8,
    },
    input: {
        borderColor: '#3f3f41',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#2b2b2e',
        color: 'white',
        height: 40,
        fontSize: 16,
        paddingLeft: 12,
        paddingRight: 12,
       
    },
    button: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: '#38FF4C',
        width: 'fit-content',
        marginTop: 24,
        marginLeft: 'auto',
        borderRadius: 4
    },
    buttonText: {
        color: '#1F1F21',
        fontFamily: 'Roboto Condensed',
    },
    point: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12
    },
    buttons: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginTop: 10,
    },

    // navigation
    container2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 64,
        backgroundColor: '#131314',
        paddingLeft: 40,
        paddingRight: 40,
      },
      controls: {
        display: 'flex',
        flexDirection: 'row',
      },
      control: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
      },
      Text: {
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        fontWeight: '400',
        marginLeft: 5
      },
      textUpgrade: {
        color: 'rgba(56, 255, 76, 1)',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto Condensed',
      },
      rightSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      upgrade: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      search: {
        borderColor: '#343436',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: 13,
        paddingRight: 13,
        marginLeft: 24,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      input2: {
        color: '#79797a',
      },
      user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 24,
      },
      emailText: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 14,
        fontWeight: '500'
      },
})

const BlockContent = styled.div`
  width: 90%;
  margin-top: 24px;
`

const BlockLabel = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 8px;
`

const BlockInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(236, 236, 238, 0.1);
  border-radius: 4px;
  background-color: #2b2b2e;
  color: white;;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px; 
  :placeholder {
    color: #808082;
  }
`

const RowTwoInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const WrapperOnInput = styled.div`
  width: 48%;
`

const SubscriptionCard = styled.div`
  width: 100%;
  height: 233px;
  border-radius: 8px;
  background: #28282A;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SubscriptionCardHeader = styled.div`
  height: 64px;
  width: 93%;
  background-color: rgba(56, 255, 76, 1);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(31, 31, 33, 1);
  padding: 0 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`