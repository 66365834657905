import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {Dimensions} from 'react-native';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  windowWidth: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  navigation: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      windowWidth: Dimensions.get('window').width
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  goToSendEmailPage(accountType: string) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "forgot_password/otp"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    const data = {
      type: accountType,
      attributes: {
        email: sessionStorage.getItem('emailForgotPassword')
      },
    };

    // it match
    //   {
    //     "data": {
    //         "attributes": {
    //             "email": "firoz@yopmail.com",
    //             "redirect_to": "https://localhost:3000/NewPassword"
    //         }
    //     },
    //     "redirect_to": "https://localhost:3000/NewPassword"
    // }
    const httpBody = {
      data: data,
      redirect_to: "https://localhost:3000/NewPassword"
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToSignin() {
    this.props.navigation.navigate('EmailAccountLoginBlock');
  }
  // Customizable Area End
}
