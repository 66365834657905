import React, {useEffect, useState} from "react";
import { View, Button, StyleSheet, Text, TouchableOpacity } from "react-native";
import { withRouter } from "react-router-dom";

const TopNav = ({ history }) => {
  const [showModal, setShowModal] = useState(true)

  const grantPermission = () => {
    if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          localStorage.setItem('push', 'true')
          var notification = new Notification("You grant a permission for push notifications");
          setShowModal(false)
        }
      });
    }
  }

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      setShowModal(false)
    }
  
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted" || localStorage.getItem('push') === 'false' ) {
      setShowModal(false)
    }
  })

  return (
    <>
    { showModal && <View style={styles.main}>
      <Text style={{
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 500,
      }}>Do you give permission to show notifications?</Text>
      <View style={{paddingBottom: '24px', paddingTop: '24px', display: 'flex', flexDirection: 'row'}}>
        <TouchableOpacity style={{
          width: '54px',
          height: '40px',
          borderRadius: '4px',
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          padding: '8px 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }} onPress={() => {
            setShowModal(false)
            localStorage.setItem('push', 'false')
          }}>
          <Text style={{color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>NO</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{
          width: '54px',
          height: '40px',
          borderRadius: '4px',
          backgroundColor: '#38FF4C',
          marginLeft: '24px',
          padding: '8px 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }} onPress={() => grantPermission()}>
          <Text style={{color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>YES</Text>
        </TouchableOpacity>
      </View>
    </View>}
    </>
  );
};

const styles = StyleSheet.create({
  main: {
    position: 'absolute',
    backgroundColor: '#1F1F21',
    width: '500px',
    borderWidth: '3px',
    zIndex: 10000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '24px',
    borderRadius: '4px'
  }
});

export default withRouter(TopNav);
