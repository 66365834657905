
import React, {useState, useEffect} from 'react'
import { StyleSheet, Text, View, TouchableWithoutFeedback, TouchableOpacity, Image } from 'react-native'
import {imgCalendar } from './assets';
import {usFlag} from '../../blocks/customisableuserprofiles/src/assets';
import { imgExpand } from './assets';
import { domainSend } from './domain';
import { Modal, styled } from '@material-ui/core';

interface IProps {
    open: boolean;
    scheduleDropdown: boolean;
    setScheduleDropdown: (value: boolean) => void;
    scheduleType: string;
    setScheduleType: (value: string) => void;
    timeDropdown: boolean; 
    setTimeDropdown: (value: boolean) => void;
    timeSelect: boolean;
    setTimeSelect: (value: boolean) => void;
    timeSelectEnd: boolean;
    setTimeSelectEnd: (value: boolean) => void;
    setTimeStartHours: (value: string) => void;
    setTimeStartMinutes: (value: string) => void;
    setTimeEndHours: (value: string) => void;
    setTimeEndMinutes: (value: string) => void;
    timeStartHours: string;
    timeStartMinutes: string;
    timeEndHours: string;
    timeEndMinutes: string;
    setFullTimeStart: (value: any) => void;
    setFullTimeEnd: (value: any) => void;
    fullTimeStart: string;
    fullTimeEnd: string;
    closeModal: any;
    addSchedule: any;
    setDays: any;
    days: any;
    scheduleID: any;
    selecteddays:any
}

export function SelectionSetScheduleModal({
    open, 
    scheduleDropdown,
    setScheduleDropdown,
    scheduleType,
    setScheduleType,
    timeDropdown,
    setTimeDropdown,
    timeSelect,
    setTimeSelect,
    timeSelectEnd,
    setTimeSelectEnd,
    setTimeStartHours,
    setTimeStartMinutes,
    setTimeEndHours,
    setTimeEndMinutes,
    timeStartHours,
    timeStartMinutes,
    timeEndHours,
    timeEndMinutes,
    fullTimeStart,
    fullTimeEnd,
    setFullTimeEnd,
    setFullTimeStart,
    closeModal,
    addSchedule,
    setDays,
    days,
    scheduleID,
    selecteddays
  }: IProps) {
  
    const [showModal, setShowModal] = useState(false);
     
    const setScheduleTypeFunc = (value: string) => {
      setScheduleDropdown(false)
      setScheduleType(value)

      if(value === 'custom') {
        setDays(selecteddays)
      } else {
        setDays(["monday", "tuesday", "wednesday", "thursday", "friday"])
      }
    }

    const setDay = (value: string) => {
      if(days.includes(value)) {
        let newDays = days.filter((item: string) => item !== value);
        setDays(newDays)
      } else {
        setDays([...days, value])
      }
    }

    const formatStartTime = (hour: string, minutes: string) => {
      let time;
      if(hour.length == 1) {
        time = '0'+hour
      }

      if(hour.length == 2) {
        time = hour
      }

      if(minutes.length == 1) {
        time = time+':0'+minutes
      }

      if(minutes.length == 2) {
        time = time+':'+minutes
      }

      setFullTimeStart(time)
      setTimeSelect(false)
    }

    const formatEndTime = (hour: string, minutes: string) => {
      let time;
      if(hour.length == 1) {
        time = '0'+hour
      }

      if(hour.length == 2) {
        time = hour
      }

      if(minutes.length == 1) {
        time = time+':0'+minutes
      }

      if(minutes.length == 2) {
        time = time+':'+minutes
      }

      setFullTimeEnd(time)
      setTimeSelectEnd(false)
    }

    useEffect(() => {
        setShowModal(open)
        if(scheduleType === 'custom') {
          setDays(selecteddays)
        } else {
          setDays(["monday", "tuesday", "wednesday", "thursday", "friday"])
        }
    }, [scheduleType])

  return (
   
   <CustomModal open={showModal}>
      <View style={styles.modal}>
        <View style={styles.header}>
            <Text style={styles.headerText}>Schedule Selection Set</Text>
            <View style={styles.close}>
                <TouchableOpacity onPress={() => {
                    setShowModal(false)
                    closeModal(false)
                    setScheduleType('mon-fri')
                }}>
                    <Text style={{fontSize: 25, color: '#79797a'}}>x</Text>
                </TouchableOpacity>   
            </View>
        </View>
        <View style={styles.divider}></View>
        <View style={{}}>
            <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16, height: 40}}>
                <View style={styles.lightIcon}>
                    <Image
                        style={{width: 16, height: 16}}
                        source={imgCalendar}
                    />
                </View>
                <Text style={styles.lightText}>Schedule Type</Text>
            </View>
        </View>
        <TouchableOpacity onPress={() => setScheduleDropdown(!scheduleDropdown)}>
          <View style={{
            width: 400,
            borderWidth: 1,
            borderColor: 'rgba(236, 236, 238, 0.1)',
            height: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 4,
            backgroundColor: '#2b2b2e',
          }}>
              <Text style={{color: 'white', marginTop: 9, marginLeft: 16, fontSize: 16}}>{scheduleType === 'custom' ? 'Custom' : 'Monday - Friday'}</Text>
          </View>
        </TouchableOpacity>
        
        {/* Dropdown */}
        {scheduleDropdown && <View style={{width: 400, height: 90, marginLeft: 'auto', marginRight: 'auto', position: 'absolute', top: '170px', left: '13px', zIndex: 99}}>
            <TouchableOpacity onPress={() => setScheduleTypeFunc('custom')}>
              <View style={{width: 400, height: 60, backgroundColor: '#2b2b2e', marginLeft: 'auto', marginRight: 'auto', borderBottomColor: 'grey'}}>
                <Text style={{marginTop: 13, marginLeft: 15, color: 'white', fontSize: 16}}>Custom</Text>
              </View>
            </TouchableOpacity>
            
            <TouchableOpacity onPress={() => setScheduleTypeFunc('mon-fri')}>
              <View style={{width: 400, height: 60, backgroundColor: '#2b2b2e', marginBottom: 10, marginLeft: 'auto', marginRight: 'auto'}}>
                <Text style={{marginTop: 13, marginLeft: 15, color: 'white', fontSize: 16}}>Monday - Friday</Text>
              </View>
            </TouchableOpacity>
        </View>}

        {scheduleType === 'custom' && <><View style={{}}>
            <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16, height: 40}}>
                <Text style={[styles.lightText, {marginLeft: 15}]}>Days</Text>
            </View>
        </View>

        <View style={{
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          marginTop: 3, 
          height: 40, 
          width: 400, 
          marginLeft: 'auto', 
          marginRight: 'auto',
          borderRadius: 4,
          justifyContent: 'space-between'
        }}>
          <TouchableOpacity onPress={() => setDay('monday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('monday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('monday') ? '#232323' : 'white'}}>Mo</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('tuesday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('tuesday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('tuesday') ? '#232323' : 'white'}}>Tu</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('wednesday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('wednesday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('wednesday') ? '#232323' : 'white'}}>We</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('thursday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('thursday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('thursday') ? '#232323' : 'white'}}>Th</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('friday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('friday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('friday') ? '#232323' : 'white'}}>Fr</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('saturday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('saturday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('saturday') ? '#232323' : 'white'}}>Sa</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setDay('sunday')}>
            <View style={{
              width: 54, 
              height: 40, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: 4, 
              backgroundColor: days.includes('sunday') ? '#38ff43' : '#313132'
            }}>
              <Text style={{fontSize: 16, color: days.includes('sunday') ? '#232323' : 'white'}}>Su</Text>
            </View>
          </TouchableOpacity>
        </View></>}

        <View style={{
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          height: 40, 
          width: 400, 
          marginLeft: 'auto', 
          marginRight: 'auto',
          justifyContent: 'space-between',
          marginTop: 20
        }}>
          <Text style={{fontSize: 16, color: 'white'}}>Time</Text>
          <TouchableOpacity onPress={() => setTimeDropdown(!timeDropdown)}>
            <Image
              testID={"imgExpand"}
              source={imgExpand}
              // @ts-ignore
              style={{width: 16, height: 16, transform: timeDropdown === false ? 'rotate(180deg)' : 'rotate(0deg)'}}
            />
          </TouchableOpacity>
        </View>


        {timeDropdown && <><View style={{
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          height: 40, 
          width: 400, 
          marginLeft: 'auto', 
          marginRight: 'auto',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          <Text style={{fontSize: 16, color: 'white'}}>Start</Text>
          <TouchableOpacity onPress={() => setTimeSelect(true)}>
            <Text style={{color: 'rgba(56, 255, 76, 1)'}}>Select time</Text>
          </TouchableOpacity>
        </View>

        <View style={{
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          height: 40, 
          width: 400, 
          marginLeft: 'auto', 
          marginRight: 'auto',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          <Text style={{fontSize: 16, color: 'white'}}>End</Text>
          <TouchableOpacity onPress={() => setTimeSelectEnd(true)}>
            <Text style={{color: 'rgba(56, 255, 76, 1)'}}>Select time</Text>
          </TouchableOpacity>
        </View></>}

        {timeSelect && 
          <View style={{borderRadius: 4, borderWidth: 1, borderColor: 'black',marginLeft: '57px', marginRight: 'auto', position: 'absolute', zIndex: 90, width: 300, height: 322, backgroundColor: '#1f1f21', top: '150px'}}>
            <Text style={{fontSize: 20, color: 'white', position: 'absolute', top: '20px', left: '20px'}}>Start Time</Text>
            <TouchableOpacity style={{ marginRight: 20, marginTop: 20, marginLeft: 'auto'}}>
              <Text onPress={() => setTimeSelect(false)} style={{fontSize: 20, color: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</Text>
            </TouchableOpacity>   
            <form style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>   
              <Text style={{color: 'white', fontSize: 12, marginTop: 16, paddingLeft: 20, paddingBottom: 8}}>Provide hours 1-23</Text> 
              <br />
              <input type='number' min='1' max='23' onChange={(event) => setTimeStartHours(event.target.value)} placeholder={'Provide start hour...'} value={timeStartHours} style={{border: 0,paddingLeft: 16, paddingRight: 10, borderWidth: 1, color: 'white', width: 200, height: 40, borderRadius: 4, marginLeft: 20, backgroundColor: '#232323'}}/>
              <Text style={{color: 'white', fontSize: 12, marginTop: 16, paddingLeft: 20, paddingBottom: 8}}>Provide minutes 1-59</Text> 
              <br />
              <input type='number' min='1' max='59' onChange={(event) => setTimeStartMinutes(event.target.value)} placeholder={'Provide start minutes...'} value={timeStartMinutes} style={{border: 0, paddingLeft: 16, paddingRight: 10, borderWidth: 1, color: 'white', width: 200, height: 40, borderRadius: 4, marginLeft: 20, backgroundColor: '#232323'}}/>
            </form>  
            <TouchableOpacity onPress={() => formatStartTime(timeStartHours, timeStartMinutes)} style={{width: 80, height: 40, borderRadius: 4, backgroundColor: '#38ff4c', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginLeft: 'auto', marginRight: 20}}>
              <Text style={{fontSize: 16, color: 'black'}}>Save</Text>
            </TouchableOpacity>
          </View>
        }

        {timeSelectEnd && 
          <View style={{borderRadius: 4, borderWidth: 1, borderColor: 'black',marginLeft: '57px', marginRight: 'auto', position: 'absolute', zIndex: 90, width: 300, height: 322, backgroundColor: '#1f1f21', top: '150px'}}>
            <Text style={{fontSize: 20, color: 'white', position: 'absolute', top: '20px', left: '20px'}}>End Time</Text>
            <TouchableOpacity style={{ marginRight: 20, marginTop: 20, marginLeft: 'auto'}}>
              <Text onPress={() => setTimeSelectEnd(false)} style={{fontSize: 20, color: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</Text>
            </TouchableOpacity>   
            <form style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>   
              <Text style={{color: 'white', fontSize: 12, marginTop: 16, paddingLeft: 20, paddingBottom: 8}}>Provide hours 1-23</Text> 
              <br />
              <input type='number' min='1' max='23' onChange={(event) => setTimeEndHours(event.target.value)} placeholder={'Provide start hour...'} value={timeEndHours} style={{border: 0,paddingLeft: 16, paddingRight: 10, borderWidth: 1, color: 'white', width: 200, height: 40, borderRadius: 4, marginLeft: 20, backgroundColor: '#232323'}}/>
              <Text style={{color: 'white', fontSize: 12, marginTop: 16, paddingLeft: 20, paddingBottom: 8}}>Provide minutes 1-59</Text> 
              <br />
              <input type='number' min='1' max='59' onChange={(event) => setTimeEndMinutes(event.target.value)} placeholder={'Provide start minutes...'} value={timeEndMinutes} style={{border: 0, paddingLeft: 16, paddingRight: 10, borderWidth: 1, color: 'white', width: 200, height: 40, borderRadius: 4, marginLeft: 20, backgroundColor: '#232323'}}/>
            </form>  
            <TouchableOpacity onPress={() => formatEndTime(timeEndHours, timeEndMinutes)} style={{width: 80, height: 40, borderRadius: 4, backgroundColor: '#38ff4c', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginLeft: 'auto', marginRight: 20}}>
              <Text style={{fontSize: 16, color: 'black'}}>Save</Text>
            </TouchableOpacity>
          </View>
        }


        <View style={{display: 'flex', flexDirection: 'row', marginTop: '40px', paddingLeft: 16, paddingRight: 16, justifyContent: 'space-between' }}>
            
            <TouchableOpacity onPress={() => addSchedule(scheduleType, fullTimeStart, fullTimeEnd, days, scheduleID )} style={[styles.applyBtn, {marginLeft: 'auto'}]}>
                <Text style={{color: '#232323'}}>
                    SAVE CHANGES
                </Text> 
            </TouchableOpacity>
            
        </View>
        

      </View>
    </CustomModal>)
}

const CustomModal = styled(Modal)({
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: '0',
    top: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
})
const styles = StyleSheet.create({
  modal: {
    width: '432px',
    height: '557px',
    left: 'calc(50% - 432px/2)',
    top: '100px',
    backgroundColor: '#1f1f21',
    borderWidth: 3,
    borderColor: 'transparent'
  },
  header: {
    width: '396px',
    height: '28px',
    marginLeft: '16px',
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    width: '224px',
    height: '28px',
    left: '16px',
    top: 'calc(50% - 28px/2 - 194px)',
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    color: '#C4EDC8',
  },
  close: {
    fontSize: 40,
    marginLeft: 'auto',
    marginTop: 'calc(50% - 20px/2 - 196px)',
    color: 'grey',
  },
  divider: {
    width: '400px',
    height: 1,
    marginLeft: '16px',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.08)',
    marginTop: 20,

    /* White 8% */
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  lightIcon: {
    /* group_icon */
    width: 16,
    height: 16,
    marginLeft: 16,
  },
  lightText: {
    width: 210,
    marginLeft: '8px',
    fontFamily: 'Roboto',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
  },
  toggleWrapper: {
    height: '24px',
    marginLeft: '16px',
    marginTop: '-4px'
  },
  radioElement: {
    marginLeft: '52.31%',
    marginRight: '37.96%',
    marginBottom: '38.36%',
  },
  sliderWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorDisplay: {
    width: 300,
    height: 50,
  },
  containerSwitch: {
    width: 50, // Szerokość całego przełącznika
    height: 30, // Wysokość całego przełącznika
    borderRadius: 15, // Promień zaokrąglenia, aby uzyskać kształt podobny do przełącznika
    justifyContent: 'center',
    padding: 5, // Padding wewnątrz przełącznika
  },
  activeBackground: {
    backgroundColor: '#c4edc8', // Tło aktywne
  },
  inactiveBackground: {
    backgroundColor: 'grey', // Tło nieaktywne
  },
  circle: {
    width: 20, // Średnica kółka
    height: 20, // Wysokość kółka
    borderRadius: 10, // Promień zaokrąglenia kółka
    backgroundColor: '#2b2b2e', // Kolor kółka
  },
  circleRight: {
    alignSelf: 'flex-end', // Pozycjonowanie kółka po prawej
  },
  circleLeft: {
    alignSelf: 'flex-start', // Pozycjonowanie kółka po lewej
  },
  containerCF: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  option: {
    marginHorizontal: 10,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleCF: {
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: 'green',
  },
  unselected: {
    backgroundColor: 'grey',
  },
  selectedCircle: {
    borderColor: 'black',
  },
  unselectedCircle: {
    borderColor: 'transparent',
  },
  text: {
    marginTop: 5,
    textAlign: 'center',
  },
  cancelBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',

    width: '222px',
    height: '35px',

    backgroundColor: '#313132',
    borderRadius: 4,

    flexGrow: 0,
    borderWidth: 1,
    borderColor: '#313132',
    color: '#88888a'
  },
  applyBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: '159px',
    height: '35px',

    backgroundColor: '#38FF4C',
    borderRadius: 4,
    flexGrow: 0,
  },

containerSlider: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'transparent'
  },
  slider: {
    width: 400,
    height: 40, // Zwiększ jeśli potrzebujesz więcej miejsca na pasek
  },
  thumb: {
    width: 20,
    height: 20,
    borderRadius: 10, // Sprawia, że kształt jest okrągły
  },
  containerSelect: {
    width: '194px',
    height: '40px',
    backgroundColor: '#2b2b2e',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(236, 236, 238, 0.1)',
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  containerCF2: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginLeft: 16,
  },
  radioButton: {
    height: 24,
    width: 24,
    backgroundColor: '#000',
    borderRadius: 12,
    borderWidth: 2,
    borderColor: '#79797a',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20
  },
  innerCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
  },
  activeInnerCircle: {
    backgroundColor: '#c4edc8',
    borderColor: '#c4edc8',
  },

})

