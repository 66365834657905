import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface Paragraph {
  id: string;
  type: string;
  attributes: {
    content: string;
  };
}

export interface PrivacyPolicyInterface {
  id: string;
  type: string;
  attributes: {
    content: string;
    paragraphs: {
      data: Paragraph[];
    };
  };
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  privacyPolicies: Array<PrivacyPolicyInterface>;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  subScribedMessages: string[];
  // Customizable Area Start
  getPrivacyPolicyCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      privacyPolicies:[{
        id: "",
        type: "",
        attributes: {
          content: "",
          paragraphs: {
            data: [
              {
                id: "",
                type: "",
                attributes: {
                  content: ""
                }
              }
            ]
          }
        }
      }]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.getPrivacyPolicyCallId === apiRequestCallId){
        if(responseJson.data.length > 0){
          this.setState({privacyPolicies : responseJson.data});
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPrivacyPolicy();
  };
  
  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
