import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native';
const flagsJson = require("./countries.json");

const FlagsBilling = ({setCountryBilling, countryBilling}: any) => {
    const [countries, setCountries] = useState<any[]>();
    const [selectedCountryBilling, setSelectedCountryBilling] = useState(countryBilling);
    const [expand, setExpand] = useState(false)
    const [show, setShow] = useState(false);

    useEffect(() => {
        let array: any[] = []
        flagsJson.map((country: any) => {
            array = [...array, {flag: country.flag, name: country.name.common }]
        });

        setCountries(array)

        if(countryBilling === 'United States' || countryBilling === '') {
            setSelectedCountryBilling({flag: "\ud83c\uddfa\ud83c\uddf8", name: "United States" })
        } else {
            const selected = array.filter((element: any) => element.name === countryBilling)
            setSelectedCountryBilling(selected)
        }
    }, [countryBilling])

    const selectCountryBilling = (element: any) => {
      setExpand(false)
      setSelectedCountryBilling({flag: element.flag, name: element.name})
      setCountryBilling(element.name)
    }

    

  return (
    <ScrollView>
      <View style={styles.container}>
        <TouchableOpacity style={{
            display: 'flex', 
            flexDirection: 'row',
            height: 40,
            backgroundColor: 'rgba(43, 43, 46, 1)',
            borderWidth: 1,
            borderColor: 'rgba(236, 236, 238, 0.1)',
            borderRadius: 4,
            paddingLeft: 12,
            alignItems: 'center',
            width: '100%'
        }}
            onPress={() => setExpand(!expand)}
        >
          <Text style={{fontSize: 24, marginRight: 5}}>{selectedCountryBilling?.flag || selectedCountryBilling[0]?.flag}</Text>
          <Text style={{color: 'white', fontSize: 16}}>{selectedCountryBilling?.name || selectedCountryBilling[0]?.name}</Text>
        </TouchableOpacity>
        {expand && <Overflow style={{height: 300, width: '202px'}}>
            {countries?.map((element: any) => 
                <TouchableOpacity onPress={() => selectCountryBilling(element) }>
                    <BorderElement 
                        style={{
                            width: '185px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', minHeight: '30px',
                            alignItems: 'center', paddingLeft: 10, paddingTop: 10, paddingBottom: 10
                        }} 
                        key={element.flag}
                    >
                        <Text style={{fontSize: 24, marginRight: 5}}>{element.flag}</Text>
                        <Text style={{color: 'white', fontSize: 16}}>{element.name}</Text>
                    </BorderElement>
                </TouchableOpacity>
            )}
        </Overflow>}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  flagContainer: {
    margin: 5,
    alignItems: 'center',
  },
});

const BorderElement = styled.View`
  border-bottom: 1px solid grey;
`

const Overflow = styled.View`
  overflowY: auto
`



export default FlagsBilling;