import React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import { 
    imgDecor, 
    imgDashboardActive, 
    imgSettings, 
    imgUpgrade,
    imgSearch,
    imgAvatar,
    imgCheckedCircle,
    imgClose
} from '../../blocks/dashboard/src/assets'
import styled from 'styled-components/native';

interface UpgradeModalProps {
    showUpgradeModal: (type: string) => void;
    closeUpgradeModal: () => void;
}

export default function UpgradeModal({showUpgradeModal, closeUpgradeModal}: UpgradeModalProps) {
 
    return (
        <ModalStyled data-modal='modal'>
            {/* Modal popup */}
            <View style={styles.modal}>
            
                    <View style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                        <Text style={[styles.text, styles.title]}>
                            Upgrade to <Text style={[styles.text, styles.title, styles.colorTitle]}>GLANCE Premium</Text>
                        </Text>
                        <TouchableOpacity onPress={closeUpgradeModal}>
                            <Image source={imgClose} style={{width: 20, height: 20}} />
                        </TouchableOpacity>
                    </View>
                
        
               
                    <>
                        <Text style={[styles.text, {marginTop: 10, marginBottom: 10}]}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Suspendisse nisi sem, viverra ut sodales eu, lacinia eu lorem. 
                            Phasellus vel vehicula velit. 
                            Etiam eu nunc et augue vehicula venenatis.
                        </Text>
                        <View style={{marginTop: 16}}>
                            <View style={styles.point}>
                                <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                <Text style={styles.text}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing.
                                </Text>
                            </View>
                            <View style={styles.point}>
                                <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                <Text style={styles.text}>
                                    Lorem ipsum dolor sit amet.
                                </Text>
                            </View>
                            <View style={styles.point}>
                                <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                <Text style={styles.text}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing.
                                </Text>
                            </View>
                            <View style={styles.point}>
                                <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                <Text style={styles.text}>
                                    Lorem ipsum dolor sit amet.
                                </Text>
                            </View>
                        </View>
                    </>
                    

                    <View style={styles.buttons}>
                        <TouchableOpacity 
                            style={[styles.button, {backgroundColor: '#313132', marginRight: 12}]}
                            onPress={closeUpgradeModal}
                        >
                            <Text style={[styles.text, styles.buttonText, {color: '#838384'}]}>NO THANKS</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={() => showUpgradeModal('payment')} data-testid='open-payment'>
                            <Text style={[styles.text, styles.buttonText]}>UPGRADE</Text>
                        </TouchableOpacity>
                    </View>
            </View>
        </ModalStyled>
        


    )
}

const ModalStyled = styled.View`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`

const styles = StyleSheet.create({
    modal: {
        width: 486,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: -100,
    },
    title: {
        fontSize: 24,
        fontFamily: 'Roboto Condensed',
        marginBottom: 12,
    },
    colorTitle: {
        color: '#38FF4C',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 16
    },
    textLink: {
        color: 'rgba(196, 237, 200, 1)',
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    label: {
        fontSize: 16,
        color: 'white',
        marginTop: 24,
        marginBottom: 8,
    },
    input: {
        borderColor: '#3f3f41',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#2b2b2e',
        color: '#808082',
        height: 40,
        fontSize: 16,
        paddingLeft: 12,
        paddingRight: 12,
    },
    button: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: '#38FF4C',
        width: 'fit-content',
        marginTop: 24,
        marginLeft: 'auto',
        borderRadius: 4
    },
    buttonText: {
        color: '#1F1F21',
        fontFamily: 'Roboto Condensed',
    },
    point: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12
    },
    buttons: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginTop: 10,
    },

    // navigation
    container2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 64,
        backgroundColor: '#131314',
        paddingLeft: 40,
        paddingRight: 40,
      },
      controls: {
        display: 'flex',
        flexDirection: 'row',
      },
      control: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
      },
      Text: {
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        fontWeight: '400',
        marginLeft: 5
      },
      textUpgrade: {
        color: 'rgba(56, 255, 76, 1)',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto Condensed',
      },
      rightSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      upgrade: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      search: {
        borderColor: '#343436',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: 13,
        paddingRight: 13,
        marginLeft: 24,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      input2: {
        color: '#79797a',
      },
      user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 24,
      },
      emailText: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 14,
        fontWeight: '500'
      },
})