import styled from 'styled-components/native';
import { imageDecor } from '../../blocks/forgot-password/src/assets';

export const ContainerContent = styled.View`
  min-height: 900px; 
  hieght: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #1F1F21;
`

export const LeftSide = styled.View`
  display: flex;
  flexDirection: column;
  alignItems: center;
  justifyContent: flex-start;
  width: 50%;
  paddingTop: 64px;
  paddingBottom: 40px;

  @media screen and (max-width: 1279px) {
    display: flex;
    flexDirection: column;
    alignItems: center;
    justifyContent: flex-start;
    paddingTop: 64px;
    paddingBottom: 40px;
    width: 100%;
    paddingLeft: 20px;
    paddingRight: 20px;
  }
`

export const ContentLeftSide = styled.View`
  maxWidth: 360px;
  marginRight: 100px;
  display: flex;
  flexDirection: column;
  justifyContent: space-between;
  height: 100%;

  @media screen and (max-width: 1279px) {
    maxWidth: 337px;
    marginRight: 0;
    display: flex;
    flexDirection: column;
    justifyContent: space-between;
    height: 100%;
  }
`

export const Logo = styled.Image `
  width: 261px;
  height: 40px;
  marginBottom: 187px;
`

export const LogoNewPassword = styled(Logo)`
  marginBottom: 56px;
`

export const Title = styled.Text`
  color: #FFF;
  fontFamily: Roboto Condensed;
  fontSize: 48px;
  fontWeight: 400;
  width: 370px;

  @media screen and (max-width: 1279px) {
    color: #FFF;
    fontFamily: Roboto Condensed;
    fontSize: 44px;
    fontWeight: 400;
    width: 370px;
  }
`

export const Subtitle = styled.Text `
  color: rgba(255, 255, 255, 0.75);
  fontSize: 16px;
  fontWeight: 400;
  marginTop: 12px;
  letterSpacing: -1px;
  marginBottom: 60px;
`

export const SubtitleNewPassword = styled(Subtitle) `
  color: rgba(255, 255, 255, 0.75);
  fontSize: 16px;
  fontWeight: 400;
  marginTop: 12px;
  letterSpacing: -1px;
  marginBottom: 48px;
`

export const PasswordShowHide = styled.TouchableOpacity`
  alignSelf: flex-end;
  marginRight: 16px;
  marginTop: -65px;
`

export const ImgPasswordShowhide = styled.Image`
  height: 20px;
  width: 20px;
`

export const InputLabel = styled.Text`
  color: #FFF;
  fontSize: 16px;
  fontWeight: 500;
`

export const InputWrapper = styled.View``


export const InputStyle = styled.TextInput`
  color: rgba(255, 255, 255, 0.40);
  fontSize: 16px;
  fontWeight: 400;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(236, 236, 238, 0.10);
  backgroundColor: #2B2B2E;
  marginTop: 8px;
  marginBottom: 32px;
  paddingTop: 12px;
  paddingBottom: 12px;
  paddingLeft: 8px;
  paddingRight: 8px;
`

export const BackToSignIn = styled.TouchableOpacity`
  marginTop: 24px;
  display: flex;
  flexDirection: row;
  justifyContent: center;
  alignItems: center;
  fontSize: 16px;
  fontWeight: 500;
  marginBottom: 150px;
`

export const BackToSignInNewPassword = styled(BackToSignIn)`
  marginBottom: 210px;
`

export const Arrow = styled.Image`
  width: 16px;
  height: 16px;
`

export const Copyright = styled.Text`
  color: rgba(255, 255, 255, 0.40);
  fontSize: 16px;
  fontWeight: 400;
  letterSpacing: -1.5px;
  marginBottom: 32px;
`

export const ButtonGreen = styled.TouchableOpacity`
  border-radius: 4px;
  height: 48px;
  backgroundColor: #38FF4C; 
  display: flex;
  justifyContent: center;
  alignItems: center;
  marginTop: 32px;
`

export const ButtonGreenNewPassword = styled(ButtonGreen)`
  marginTop: 0px;
`

export const ButtonGreenText = styled.Text`
  color: #1F1F21; 
  fontFamily: Roboto Condensed;
  fontSize: 20px;
  fontWeight: 500;
`

export const DecorImage = styled.View`
  backgroundImage: url(${imageDecor}); 
  backgroundPosition: center;
  backgroundSize: cover;
  width: 50%;
  height: 100%;

  @media screen and (max-width: 1279px) {
    width: 0%;
  }
`