import React, {useState, useRef, useEffect, ChangeEvent} from "react";
import { View, Button, StyleSheet, Text, TouchableOpacity, Image } from "react-native";
import { withRouter } from "react-router-dom";
import { imgClose } from "../../blocks/dashboard/src/assets";
import DocumentPicker from 'react-native-document-picker';
import { imageUpload, imageDeleteFile } from "./assets";
import { domainSend } from "./domain";
import styled from 'styled-components/native';
import { Box, Modal, styled as materialStyled } from "@material-ui/core";

export function BulkUploadModal({fetchApps}: any) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [done, setDone] = useState(false);
  const [errorSend, setErrorSend] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Filtrujemy pliki, aby mieć pewność, że są to JPG lub PNG
    if (event.target.files) {
      const files = Array.from(event.target.files).filter((file: File) => 
        file.type === "image/jpeg" || file.type === "image/png"
      );

      console.log("files: "+files)
      setSelectedFiles(files);
    }
  };

  const handleRemoveFile = (indexToRemove: number | string) => {
    setSelectedFiles(selectedFiles.filter((_, index) => index !== indexToRemove));

    console.log(selectedFiles)
  };

  const sendFiles = async () => {
    const formData = new FormData();
    const domain = domainSend();

    // Dodaj każdy plik do obiektu FormData
    selectedFiles.forEach(file => {
      formData.append('files[]', file);
    });

    try {
      const response = await fetch(`${domain}bx_block_bulk_uploading/attachments`, {
        method: "POST",
        headers: {
          token: localStorage.getItem('authToken') as string
        },
        body: formData
      });
  
      if (response.status.toString().startsWith('2')) {
        setDone(true);
        setShowModal(false);
        fetchApps()
        
        
      } else {
        setErrorSend(true)
      }
    } catch (error) {

      setErrorSend(true)
    }
  }

  useEffect(() => {
    console.log('mounted!!!!!')
  })

  return (
   
    <>
      <CustomBulkModal
        open={showModal}
      >
    <CustomBulkModalContent>
      <Text style={{
        color: 'rgba(255, 255, 255, 0.75)',
        fontFamily: 'Roboto',
        fontSize: 24,
        fontWeight: '500',
        borderBottomWidth: 2,
        borderColor: 'rgba(255, 255, 255, 0.08)',
        paddingBottom: 16,
        width: '100%',
      }}>Image Upload 
        <TouchableOpacity style={{marginLeft: '63%'}} onPress={() => setShowModal(false)}>
          <Image
            style={{
              width: 20,
              height: 20,
            }}
            source={{
              uri: imgClose,
            }}
            
          />
        </TouchableOpacity>
          </Text>
      <View>

      <View style={{
        marginTop: 16, 
        width: '460px',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <input
          type="file"
          multiple
          accept="image/jpeg, image/png"
          onChange={handleFileChange}
          style={{display: 'none'}}
          ref={fileInputRef}
        />
        <TouchableOpacity 
          style={{
            width: '460px', borderWidth: 3, borderStyle: 'dashed', borderColor: 'rgba(255, 255, 255, 0.04)', height: '160px',
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent'
          }} 
          onPress={handleButtonClick}
        >
          <Image
            style={{
              width: 40,
              height: 40,
            }}
            source={{
              uri: imageUpload,
            }}
          />
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={{color: 'white', fontSize: 16, textDecorationLine: 'underline', marginTop: '12px'}}>Click to browse
            </Text>
         
            <Text style={{marginLeft: 5, color: '#7e7e7f', textDecorationLine: 'none', fontSize: 16, marginTop: '12px' }}>or drag and drop</Text>
          </View>
        </TouchableOpacity>
      </View> 

      </View>
      <View style={{paddingBottom: '24px', paddingTop: '24px', display: 'flex', flexDirection: 'row', marginLeft: '36%'}}>

        <TouchableOpacity style={{
            width: 105,
            height: 40,
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            padding: '8px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto'
          }}
          onPress={() => setShowModal(false)}
        >
          <Text style={{color: 'rgba(255, 255, 255, 0.4)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Cancel</Text>
        </TouchableOpacity>
        <TouchableOpacity 
          disabled={selectedFiles.length === 0}
          style={{
            width: 165,
            height: 40,
            borderRadius: 4,
            backgroundColor: '#38FF4C',
            opacity: selectedFiles.length === 0 ? 0.5 : 1,
            marginLeft: '24px',
            padding: '8px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onPress={sendFiles}
        >
          <Text style={{color: '#1F1F21', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >Upload images</Text>
        </TouchableOpacity>
      </View>
      <StyledOverflow style={{maxHeight: '200px', width: '500px', overflowY: 'auto'}}>
        {selectedFiles.length > 0 && (
          <div>
            <ul>
              {selectedFiles.map((file, index) => (
                <li 
                  style={{
                    display: 'flex', justifyContent: 'space-between', color: 'rgba(255, 255, 255, 0.75)', fontFamily: "Roboto", marginLeft: '-33px', width: '460px',
                    marginBottom: '20px'
                  }} 
                  key={index}
                >
                  <span>
                    {file.name} 
                    {done && <span style={{color: '#38FF4C', marginLeft: '20px'}}>Done</span>} 
                    {errorSend && <span style={{color: '#ED1C24', marginLeft: '20px'}}>Upload Failed</span>}
                  </span> 
                  <img src={imageDeleteFile} style={{width: '20px', height: '20px'}} onClick={() => handleRemoveFile(index)}/>
                </li>
              ))}
            </ul>
          </div>
        )}
      </StyledOverflow>
       </CustomBulkModalContent>
    </CustomBulkModal>
    

    {/* Bottom Bar */}
      <StyledFixed onPress={() => setShowModal(true)} style={{
          backgroundColor: 'rgba(56, 255, 76, 1)',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
          paddingTop: 10,
          paddingLeft: 24,
          paddingRight: 24,
          position: 'fixed',
          bottom: 10
        }}>
        <Text style={{color: 'rgba(31, 31, 33, 1)', fontFamily: 'Roboto Condensed'}}>Upload images</Text>
      </StyledFixed>
    </>
    
  );
};

const StyledOverflow = styled.View`
    overflowY: auto;
`

const StyledFixed = styled.TouchableOpacity`
    position: fixed;
`
const CustomBulkModal = materialStyled(Modal)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const CustomBulkModalContent = materialStyled(Box)({
  backgroundColor: '#1F1F21',
  width: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '24px',
  borderRadius: 4,
  paddingRight: '16px',
  paddingLeft: '16px',
  borderWidth: 1,
  borderColor: 'black'
})