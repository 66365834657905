import React from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import styled from 'styled-components/native';

interface IProps {
    searchResult: Array<Object>;
    navigation: any
}

export function DropdownSearch({searchResult, navigation}: IProps) {
    return(
    <View style={{
        width: 360, 
        height: 500, 
        backgroundColor: '#1F1F21', 
        paddingLeft: 16,
        paddingRight: 16,
        position: 'absolute',
        right: '204px',
        zIndex: 4,
        marginLeft: '60px',
        //@ts-ignore
        overflowY: 'auto',
        borderWidth: 1,
        borderColor: '#232323'
    }}>
        <View style={{display: 'flex', flexDirection: 'column'}}>
            {searchResult?.map((element: any) => (
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}} 
                        onPress={() => navigation.navigate(element.url)}
                        key={element.key}
                    >
                        <StyledText style={{textAlign: 'left'}}>{element.name}</StyledText>
                    </TouchableOpacity>
                ))
            }
        </View>
    </View>
    )
}


const StyledText = styled.Text`
color: #FFF;
text-align: center;
font-family: Roboto Condensed;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.64px;
margin-left: 10px;
`


const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    backgroundColor: '#f3f3f3',
  },
  text: {
   fontSize: 36,
   fontWeight: '600',
  }
})
