import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TouchableWithoutFeedback, 
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import {imageDecor, imageLogo, arrowLeftIcon} from './assets'
import styled from 'styled-components/native';
// Customizable Area End

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";

export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ScrollView
        keyboardShouldPersistTaps="always"
        style={Platform.OS === "web" ? styles.containerWeb : styles.container}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
          testID="hideKeyboard"
        >
          {/* Customizable Area Start */}
          <View style={styles.containerContent}>
              <View style={this.state.windowWidth <= 1279 ? styles.leftSideMobileOrTablet : styles.leftSide}>
                <View style={this.state.windowWidth <= 1279 ? styles.contentLeftSideMobileOrTablet : styles.contentLeftSide}>
                  <Image source={imageLogo} style={styles.logo} />
                  <View>
                    <Text style={this.state.windowWidth <= 1279 ? styles.titleTabletOrMobile : styles.title}>
                      Forgot Password?
                    </Text>
                    <Text style={styles.subtitle}>
                      No worries, we will send you reset instructions.
                    </Text>
                  
                    <>
                      <Text style={[styles.subtitle, styles.infoEmail, {marginBottom: 0, marginTop: -30}]}>
                        We have sent a password reset link to 
                      </Text>
                      <Text style={[styles.subtitle, styles.emailName, {fontSize: 18, marginTop: 8}]}>
                        {sessionStorage.getItem('emailForgotPassword')}
                      </Text>
                      <TouchableOpacity
                        testID={"btnGetOtpForEmailButtonSuccess"}
                        onPress={() =>  this.goToSendEmailPage('email_account')}
                        style={styles.buttonGreen}
                      >
                        <Text style={styles.buttonGreenText}>
                          RESEND EMAIL
                        </Text>
                      </TouchableOpacity>
                    </>
                  
                    <TouchableOpacity 
                      style={styles.backToSignIn} 
                      onPress={() => this.goToSignin()}
                      data-testid='gotosignin'
                    > 
                      <Image source={arrowLeftIcon} style={styles.arrow}/>
                      <Text style={{color: 'rgba(255, 255, 255, 0.75)', marginLeft: 5}}>Back to</Text>
                      <Text style={{color: '#38FF4C', marginLeft: 5}}>Sign in</Text>
                    </TouchableOpacity>
                  </View>          
                  <Text style={styles.copyright}>Copyright © 2023 GLANCE LED. All rights reserved</Text>
                </View>
              </View>
              <DecorImage></DecorImage>
            </View>
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  containerWeb: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  containerContent: {
    width: '100%',
    minHeight: 900, 
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#1F1F21',
  },

  leftSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: '50%',
    paddingTop: 64,
    paddingBottom: 40,
  },

  leftSideMobileOrTablet: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 64,
    paddingBottom: 40,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20
  },

  contentLeftSide: {
    maxWidth: 360,
    marginRight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  contentLeftSideMobileOrTablet: {
    maxWidth: 337,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  logo: {
    width: 261,
    height: 40,
    marginBottom: 187
  },

  title: {
    color: '#FFF',
    fontFamily: 'Roboto Condensed',
    fontSize: 48,
    fontWeight: '400',
    width: 370
  },

  titleTabletOrMobile: {
    color: '#FFF',
    fontFamily: 'Roboto Condensed',
    fontSize: 44,
    fontWeight: '400',
    width: 370
  },

  subtitle: {
    color: 'rgba(255, 255, 255, 0.75)',
    fontSize: 16,
    fontWeight: '400',
    marginTop: 12,
    letterSpacing: -1,
    marginBottom: 60
  },

  inputLabel: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: '500',
  },

  inputWrapper: {
    
  },

  inputStyle: {
    color: 'rgba(255, 255, 255, 0.40)', 
    fontSize: 16,
    fontWeight: '400',
    width: '100%',
    borderColor: 'rgba(236, 236, 238, 0.10)',
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: '#2B2B2E',
    marginTop: 8,
    marginBottom: 32,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8
  },

  backToSignIn: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 210
  },

  arrow: {
    width: 16,
    height: 16
  },

  webInput: {
    width: '100%',
  },

  copyright: {
    color: 'rgba(255, 255, 255, 0.40)',
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: -1.5,
    marginBottom: 32,
  },

  buttonGreen: {
    borderRadius: 4, 
    height: 48, 
    backgroundColor: '#38FF4C', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32
  },

  buttonGreenText: {
    color: '#1F1F21', 
    fontFamily: 'Roboto Condensed', 
    fontSize: 20, 
    fontWeight: '500',
  },

  infoEmail: {
    fontSize: 18,
    fontWeight: '400',
  },

  emailName: {
    fontWeight: '500',
    color: '#fff'
  },


});

const DecorImage = styled.View`
width: 50%;
height: 100%;
background-image: url(${imageDecor}); 
background-position: center;
background-size: cover;
@media screen and (max-width: 1280px) {
  width: 0;
}
`
// Customizable Area End
