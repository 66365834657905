import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { domainSend } from "../../../components/src/domain";
import { da } from "date-fns/locale";
import { CountryCode } from "libphonenumber-js/types";
import * as YupPhone from "yup";
import "yup-phone-lite";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

interface IUploadData extends Blob {
  file: string;
}

interface AccountData {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    phone_number: string;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    first_time_login: boolean;
    zip_code: string | null;
    country: string;
    user_image: string;
    password_digest: string;
  };
}

interface AccountResponseType {
  message: object;
  data: AccountData;
  errors:string;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface Country {
  id: string;
  type: string;
  attributes:{
    name: string;
    emoji_flag: string;
    country_code: string;
    currency_code: string;
  };
}

interface CountryCodeRes {
  data: Array<Country>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  me: AccountData;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  upgradeModal: boolean;
  paymentModal: boolean;
  tab: string;
  firstName: string;
  countryCode: string;
  country: string;
  stylesType: string;
  showRemoveModal: boolean;
  file: File | null | string;
  backgroundUrl: string;
  billingExpiry: string;
  billingCreditCard: string;
  billingCardholderName: string;
  billingCvv: string;
  billingName: string,
  billingLastName: string,
  billingPhone: string,
  billingAddress: string,
  billingExpiryError: boolean,
  billingCreditError: boolean,
  billingCardholderNameError: boolean,
  billingCvvError: boolean,
  billingNameError: boolean,
  billingLastNameError: boolean,
  billingPhoneError: boolean,
  billingAddressError: boolean,
  successModal: boolean;
  countryBilling: string;
  showFlag: boolean;
  phoneCountryCode: CountryCode;
  phoneNumError: boolean;
  countryCodeArray: Array<Country>;
  countryCodeDigits: string;
  passwordDigest: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  token: string = "";
  intervalId: number = 0;
  addCardBillingApiCallId: string = "";
  addAddressBillingApiCallId: string = "";

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  apiGetUserInfo: string = "";
  apiUpdateUserProfile: string = "";
  editProfileApiCallId: string = "";
  editProfilePhotoApiCallId: string = "";
  fetchingCountryCodesCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],
      me:{
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: "",
          email: "",
          first_name: "",
          full_phone_number: "",
          last_name: "",
          phone_number: "",
          type: "",
          created_at: "",
          updated_at: "",
          device_id: "",
          unique_auth_id: "",
          first_time_login: false,
          zip_code: "",
          country: "",
          user_image: "",
          password_digest: "",
        }
      },

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      upgradeModal: false,
      paymentModal: false,
      tab: 'edit',
      firstName: '',
      countryCode: '',
      country: '',
      stylesType: 'text',
      showRemoveModal: false,
      file: null,
      backgroundUrl: '',
      billingExpiry: '',
      billingCreditCard: '',
      billingCardholderName: '',
      billingCvv: '',
      billingName: '',
      billingLastName: '',
      billingPhone: '',
      billingAddress: '',
      billingExpiryError: false,
      billingCreditError: false,
      billingCardholderNameError: false,
      billingCvvError: false,
      billingNameError: false,
      billingLastNameError: false,
      billingPhoneError: false,
      billingAddressError: false,
      successModal: false,
      countryBilling: '',
      showFlag: false,
      phoneCountryCode: {} as CountryCode,
      phoneNumError: true,
      countryCodeArray: [],
      countryCodeDigits: "",
      passwordDigest: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.setCountry = this.setCountry.bind(this);
    this.setCountryBilling = this.setCountryBilling.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getProfileInfo()
    this.getCard()
    this.getBilling()
   
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };


  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } 
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
      }

      
      if(message.properties.RestAPIResponceDataMessage === 'getUserInfo') {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
        this.setState({
          me: responseJson.data,
          firstName: responseJson.data.attributes.first_name,
          lastName: responseJson.data.attributes.last_name,
          email: responseJson.data.attributes.email,
          countryCode: responseJson.data.attributes.zip_code,
          phoneNumber: responseJson.data.attributes.full_phone_number,
          file: responseJson.data.attributes.user_image,
          backgroundUrl: responseJson.data.attributes.user_image
        })
      }

      if(message.properties.RestAPIResponceDataMessage === 'removeAccount') {
        this.props.navigation.navigate("EmailAccountLoginBlock")
        localStorage.removeItem('authToken')
      }

      if(message.properties.RestAPIResponceDataMessage === 'getCard') {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
        this.setState({
          billingCardholderName: responseJson.data.attributes.cardholder_name,
          billingCreditCard: responseJson.data.attributes.card_number,
          billingExpiry: responseJson.data.attributes.expiry_date,
          billingCvv: responseJson.data.attributes.cvv.toString()
        })
      }

      if(message.properties.RestAPIResponceDataMessage === 'getBilling') {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
        this.setState({
          billingName: responseJson.data.attributes.first_name,
          billingLastName: responseJson.data.attributes.last_name,
          billingPhone: responseJson.data.attributes.full_phone_number,
          billingAddress: responseJson.data.attributes.address,
        })

      }
      this.editProfileSucCallBack(apiRequestCallId,responseJson);
      this.editProfilePhotoSucCallBack(apiRequestCallId,responseJson);
      this.extendedReceiver(apiRequestCallId,responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setFirstName(value: string) {
    this.setState({
      firstName: value
    })
  }

  setLastName(value: string) {
    this.setState({
      lastName: value
    })
  }

  setEmail(value: string) {
    this.setState({
      email: value
    })
  }

  setPhone(value: string) {
    this.setState({
      phoneNumber: value
    })
  }

  setCountryCode(value: string) {
    this.setState({
      countryCode: value
    })
  }

  async setFile(value: File) {
    this.setState({
      file: value
    }, () => this.updateUserAvatar())

  }
  
  setActiveTab(tabName: string) {
    this.setState({
      tab:tabName
    })
  }

  showUpgradeModal(type: string) {
    if(type === 'upgrade') {
        this.setState({ 
          upgradeModal: true,
          paymentModal: false,
        })
    }

    if(type === 'payment') {
      this.setState({
        upgradeModal: false,
        paymentModal: true,
      })
    }
  } 

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } 
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfileInfo = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken') as string
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiGetUserInfo = requestMessage.messageId;
    requestMessage.messageId = 'getUserInfo'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/me"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async updateUserProfile() {
    const formData = new FormData();
    formData.append('account[first_name]', this.state.firstName);
    formData.append('account[last_name]', this.state.lastName);
    formData.append('account[zip_code]', this.state.countryCode);
    formData.append('account[country]', this.state.country);
    if (this.state.phoneNumber !== "") {
      if (!await this.validatePhoneNumber()) {
        return
      } else {
        formData.append('account[full_phone_number]', this.state.phoneNumber);
      }
    } else {
      formData.append('account[full_phone_number]', "");
    }
    const header = {
      token: localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProfileApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editProfileEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;
  }

  updateUserAvatar() {
    const formData = new FormData();
    formData.append('account[user_image]', this.state.file as unknown as IUploadData );

    const header = {
      token: localStorage.getItem("authToken")
    };

    const requestMessageGlance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProfilePhotoApiCallId = requestMessageGlance.messageId;

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editProfileEndPoint
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageGlance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessageGlance.id, requestMessageGlance);
    return true;

  }

  showRemoveModal() {
    this.setState({
      showRemoveModal: true
    })
  }

  hideRemoveModal() {
    this.setState({
      showRemoveModal: false
    })
  }

  returnAvatarImg() {
    return this.state.file
  }

  deleteAccount() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken') as string
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiUpdateUserProfile = requestMessage.messageId;
    requestMessage.messageId = 'removeAccount'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/deactivate_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setBillingExpiryDate(value: string) {
    this.setState({
      billingExpiry: value
    })
  }

  setCreditCardNumber(value: string) {
    const numbersOnly = value.replace(/\s+/g, '')
    // Dodajemy spację co 4 cyfry
    const spaced = numbersOnly.replace(/(.{4})/g, '$1 ').trim()
    this.setState({
      billingCreditCard: spaced
    })
  }

  setBillingCardholderName(value: string) {
    this.setState({
      billingCardholderName: value
    })
  }

  setBillingCvv(value: string) {
    this.setState({
      billingCvv: value
    })
  }

  setBillingName(value: string) {
    this.setState({
      billingName: value
    })
  }

  setBillingLastName(value: string) {
    this.setState({
      billingLastName: value
    })
  }

  setBillingPhone(value: string) {
    this.setState({
      billingPhone: value
    })
  }

  setBillingAddress(value: string) {
    this.setState({
      billingAddress: value
    })
  }

  setBillingExpiryError(value: boolean) {
    this.setState({
      billingExpiryError: value
    })
  }

  setBillingAddressError(value: boolean) {
    this.setState({
      billingAddressError: value
    })
  }

  setBillingCardHolderError(value: boolean) {
    this.setState({
      billingCardholderNameError: value
    })
  }

  setBillingCreditCardError(value: boolean) {
    this.setState({
      billingCreditError: value
    })
  }

  setBillingCvvError(value: boolean) {
    this.setState({
      billingCvvError: value
    })
  }

  setBillingLastNameError(value: boolean) {
    this.setState({
      billingLastNameError: value
    })
  }

  setBillingNameError(value: boolean) {
    this.setState({
      billingNameError: value
    })
  }

  setBillingPhoneError(value: boolean) {
    this.setState({
      billingPhoneError: value
    })
  }

  async saveCard() {
    let bodyData = {
      cardholder_name: this.state.billingCardholderName,
      card_number: this.state.billingCreditCard.replace(/\s+/g, ''),
      expiry_date: this.state.billingExpiry.replace(/\s+/g, ''),
      cvv: this.state.billingCvv
    };

      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem('authToken') as string,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addCardBillingApiCallId = requestMessage.messageId;
      requestMessage.messageId = 'saveCard'
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/card_details"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  saveBilling() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken') as string,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressBillingApiCallId = requestMessage.messageId;
	  requestMessage.messageId = 'saveBilling'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/billing_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let bodyData = {
      first_name: this.state.billingName,
      last_name: this.state.billingLastName,
      country: this.state.countryBilling,
      full_phone_number: this.state.billingPhone,
      address: this.state.billingAddress
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getBilling() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken') as string,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressBillingApiCallId = requestMessage.messageId;
	  requestMessage.messageId = 'getBilling'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/billing_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCard() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken') as string,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCardBillingApiCallId = requestMessage.messageId;
	  requestMessage.messageId = 'getCard'

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/card_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setSuccessModal(value: boolean) {
    this.setState({
      successModal: value
    })
  }

  setAvatar(value: string) {
    this.setState({
      backgroundUrl: value
    })
  }

  setCountry(value: string) {
    this.setState({
      country: value
    })
  }

  setCountryBilling(value: string) {
    this.setState({
      countryBilling: value
    })
  }

  setShowFlags() {
    this.setState({
      showFlag: true
    })
  }

  handleContactNumberChange = (event: string, country: CountryCodeObj) => {
    this.setState({ phoneNumber: event, phoneCountryCode: country?.countryCode?.toUpperCase() as CountryCode })
  };

  validatePhoneNumber = (): Promise<boolean> => {
    const phoneSchema = YupPhone.string().phone(this.state.phoneCountryCode).required();
    return phoneSchema.isValid(this.state.phoneNumber)
      .then(isValid => {
        this.setState({ phoneNumError: isValid });
        return isValid;
      })
      .catch(() => {
        return false;
      });
  }

  editProfileSucCallBack = (resApiCallId: string, apiResponse: AccountResponseType) => {
    if (resApiCallId === this.editProfileApiCallId) {
      if (apiResponse.data) {
        this.setState({
          backgroundUrl: apiResponse.data.attributes.user_image,
          successModal: true
        });
      }
    }
  };

  editProfilePhotoSucCallBack = (resApiCallId: string, apiResponse: AccountResponseType) => {
    if (resApiCallId === this.editProfilePhotoApiCallId) {
      if (apiResponse.data) {
        this.setState({
          backgroundUrl: apiResponse.data.attributes.user_image,
          successModal: true
        });
      }
    }
  };

  extendedReceiver = (resApiCallId: string, apiResponse: CountryCodeRes) => {
    if(resApiCallId === this.fetchingCountryCodesCallId){
      if(apiResponse.data){
        this.setState({
          countryCodeArray:apiResponse.data
        },()=>{
          this.sortCountryCode()
        });
      }
    }
  };

  sortCountryCode = () => {
    const sortedData = this.state.countryCodeArray.filter((country: Country) => {
      return this.state.countryCodeDigits === country.attributes.country_code
    });
    this.setState({
      phoneCountryCode: sortedData.length > 0 ? sortedData[0].id  as CountryCode : "US"
    });
  };

  fetchingCountryCodes = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('authToken') as string,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchingCountryCodesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryCodeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true; 
  };

  setCountryCodeDigits = (codeDigits: string) => {
      this.setState({
        countryCodeDigits: codeDigits
      });
  };

  setPasswordDigits = (passDigits: string) => {
    this.setState({
      passwordDigest: passDigits
    });
  };
  // Customizable Area End
}
