import React from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import DashboardController, { Props } from "./DashboardController.web";
import { 
    imgDecor, 
    imgDashboardActive, 
    imgSettings, 
    imgUpgrade,
    imgSearch,
    imgAvatar,
    imgCheckedCircle,
    imgClose,
    imgCloseWhite,
    imgDropdownLogout,
    imgDropdownManage,
    imgDropdownTerms,
    imgMail
} from './assets'
import styled from 'styled-components/native';
import { StripeCard } from '../../../components/src/StripeCard';
import { DropdownSearch } from '../../../components/src/DropdownSearch';
import { Box, styled as MaterialStyled, Typography} from '@material-ui/core';

export class Modal extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        
        return (
            // Modal background for whole page
            <>
             <View style={styles.container2}>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('Dashboard')}>
                    <Image
                        testID={"imgLogo"}
                        source={imgDecor}
                        style={{width: 156, height: 24}}
                    />
                </TouchableOpacity>
                
                <View style={styles.controls}>
                    <TouchableOpacity style={styles.control} onPress={() => this.props.navigation.navigate('Dashboard')} data-testid="gotodashboard">
                        <Image
                            testID={"imgDashboard"}
                            source={imgDashboardActive}
                            style={{width: 20, height: 20}}
                        />
                        <Text style={[styles.Text, {color: '#ffffff'}]}>
                            Dashbaord
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={styles.control} 
                        onPress={() => this.props.navigation.navigate('CustomizableUserProfile')} 
                        data-testid="gotosettings"
                    >
                        <Image
                            testID={"imgDashboard"}
                            source={imgSettings}
                            style={{width: 20, height: 20}}
                        />
                        <Text style={[styles.Text, {color: '#717172'}]}>
                            Settings
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.rightSide}>
                    <View style={styles.upgrade}>
                        <TouchableOpacity 
                            data-testid='upgrade-modal' 
                            onPress={() => this.openUpgradeModal()} 
                            style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                        >
                        <Image
                            source={imgUpgrade}
                            style={{width: 20, height: 20}}
                        />
                        <Text style={styles.textUpgrade}>Upgrade to GLANCE PREMIUM</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.search}>
                        <Image
                            source={imgSearch}
                            style={{width: 14, height: 14, marginRight: 9}}
                        />
                        <TextInput
                            testID="txtInputSearch"
       
                            style={[styles.input2]}
                            placeholder="Quick Search..."
                            onChangeText={(value: string) => this.setSearchInput(value)}
                        />
                    </View>
                    
                    <TouchableOpacity style={styles.user} onPress={() => this.openDropdownUser()} data-testid="open-dropdown">
                        {this.state.meAvatar
                            ? <View>
                                <Image
                                // @ts-ignore 
                                    source={this.state.meAvatar ?  { uri: this.state.meAvatar } : imgAvatar}
                                    style={{width: 32, height: 32, marginRight: 9, borderRadius: 50}}
                                />    
                            </View>
                            : <Image
                                source={imgAvatar}
                                style={{width: 32, height: 32, marginRight: 9}}
                            />
                        }
                        <Text style={styles.emailText}>
                            {this.state.meEmail}
                        </Text>
                    </TouchableOpacity>

                </View>
            </View>

            {/* Dropdown search */}
            {(this.state.searchInput.length > 0 && this.state.inputChars > 0) &&
                <DropdownSearch searchResult={this.state.searchInput} navigation={this.props.navigation}/>
            }

            {this.state.openDropdownUser &&
            <DropDownBox>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#1F1F21'}}>
                    <Image source={this.state.meAvatar ?  this.state.meAvatar : imgAvatar} style={{width: 45, height: 45, borderRadius: 50}}/>
                    <View style={{marginLeft: 16, display: 'flex', flexDirection: 'column', marginRight: 'auto'}}>
                        <Text style={{
                            color: 'rgba(255, 255, 255, 0.75)',
                            fontFamily: 'Roboto Condensed',
                            fontSize: 20,
                            fontWeight: '400',
                        }}>Hi User!</Text>
                        <Text
                        style={{
                            color: 'rgba(255, 255, 255, 0.60)',
                            fontFamily: 'Roboto Condensed',
                            fontSize: 14,
                            fontWeight: '400',
                        }}
                        >{this.state.meEmail}</Text>
                    </View>
                    <TouchableOpacity onPress={() => this.closeDropdownUser()}>
                        <Image source={imgCloseWhite} style={{width: 20, height: 20}}/> 
                    </TouchableOpacity>
                </View>
                <View style={{display: 'flex', flexDirection: 'column'}}>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}} 
                        onPress={() => this.props.navigation.navigate('CustomizableUserProfile')} 
                    >
                        <Image source={imgDropdownManage} style={{width: 24, height: 24}}/>
                        <StyledText>Manage your Glance-led Account</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => this.props.navigation.navigate('TermsConditionsDetail')}                         
                    >
                        <Image source={imgDropdownTerms} style={{width: 24, height: 24}}/>
                        <StyledText>Terms and Conditions</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={this.handlePrivacyPolicyNavigation}                         
                    >
                        <Image source={imgDropdownTerms} style={{width: 24, height: 24}}/>
                        <StyledText>Privacy Policy</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => this.props.navigation.navigate('Contactus')}                         
                    >
                        <Image source={imgMail} style={{width: 24, height: 24}}/>
                        <StyledText>Contact Us</StyledText>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}
                        onPress={() => {
                            localStorage.removeItem('authToken')
                            this.props.navigation.navigate('EmailAccountLoginBlock')
                        }}
                        
                    >
                        <Image source={imgDropdownLogout} style={{width: 24, height: 24}}/>
                        <StyledText>LOG OUT</StyledText>
                    </TouchableOpacity>
                </View>
            </DropDownBox>
            }

            {this.state?.step !== 0 ? 
                <ModalStyled data-modal='modal'>
                {/* Modal popup */}
                <View style={styles.modal}>
                    {/* Klikanie bedzie zmieniac stan na 1,2,3 zamkniecie jakiegos modalu zamieni na 0 */}
                    { this.state.step === 1 &&
                        <ModalHeader>
                        <ModalHeaderText>
                            Enter your details
                        </ModalHeaderText>
                        <ModalHeaderCloseButton onClick={() => this.closeMacAddressModal()}>
                                <ModalHeaderCloseIcon src={imgClose} />
                            </ModalHeaderCloseButton>
                        </ModalHeader>
                    }
                    {this.state.step === 2 && 
                        <View style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                            <Text style={[styles.text, styles.title]}>
                                Upgrade to <Text style={[styles.text, styles.title, styles.colorTitle]}>GLANCE Premium</Text>
                            </Text>
                            <TouchableOpacity onPress={() => this.closeModalUpgrade()} data-testid='closeModal'>
                                <Image source={imgClose} style={{width: 20, height: 20}} />
                            </TouchableOpacity>
                        </View>
                    }
                    {this.state.step === 1 && 
                        <Text style={styles.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ante enim, blandit 
                            <Text style={styles.textLink}>link example</Text>.
                        </Text>
                    }
                    {this.state.step === 2  &&
                        <>
                            <Text style={[styles.text, {marginTop: 10, marginBottom: 10}]}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Suspendisse nisi sem, viverra ut sodales eu, lacinia eu lorem. 
                                Phasellus vel vehicula velit. 
                                Etiam eu nunc et augue vehicula venenatis.
                            </Text>
                            <View style={{marginTop: 16}}>
                                <View style={styles.point}>
                                    <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                    <Text style={styles.text}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing.
                                    </Text>
                                </View>
                                <View style={styles.point}>
                                    <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                    <Text style={styles.text}>
                                        Lorem ipsum dolor sit amet.
                                    </Text>
                                </View>
                                <View style={styles.point}>
                                    <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                    <Text style={styles.text}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing.
                                    </Text>
                                </View>
                                <View style={styles.point}>
                                    <Image source={imgCheckedCircle} style={{width: 20, height: 20, marginRight: 8}}/>
                                    <Text style={styles.text}>
                                        Lorem ipsum dolor sit amet.
                                    </Text>
                                </View>
                            </View>
                        </>
                        
                    }
                    {this.state.step === 1 &&
                        <>
                            <Text style={styles.label}>MAC address</Text>
                            <TextInput
                                style={styles.input}
                                placeholder='Enter device MAC address e.g. 11:22:33:44:55:66'
                                placeholderTextColor="#808082"
                                onChangeText={(value) => {
                                    let text = value.replace(/\D/g, ''); // Usuwa wszystko, co nie jest cyfrą
                                                
                                    let formattedText = this.returnFormattedText(text);

                                    this.setMacModal(formattedText.substring(0, 17));
                                }}
                                value={this.state.macAddressModalText}
                                data-testid="mac"
                            />
                            <TouchableOpacity style={styles.button} onPress={() => this.addUserGlance()} data-testid='addMac'>
                                <Text style={[styles.text, styles.buttonText]}>SAVE</Text> 
                            </TouchableOpacity>
                        </>
                    }

                    {this.state.step === 2 &&
                        <View style={styles.buttons}>
                            <TouchableOpacity 
                                style={[styles.button, {backgroundColor: '#313132', marginRight: 12}]}
                                onPress={() => this.closeModalUpgrade()}
                            >
                                <Text style={[styles.text, styles.buttonText, {color: '#838384'}]}>NO THANKS</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button} onPress={() => this.openPaymentModal()} data-testid='open-payment'>
                                <Text style={[styles.text, styles.buttonText]}>UPGRADE</Text>
                            </TouchableOpacity>
                        </View>
                    }

                    {this.state.step === 3 && 
                        <>
                            <View style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                <Text style={[styles.text, styles.title]}>
                                    Upgrade to <Text style={[styles.text, styles.title, styles.colorTitle]}>GLANCE Premium</Text>
                                </Text>
                                <TouchableOpacity onPress={() => this.closeModalUpgrade()} data-testid='close-upgrade'>
                                    <Image source={imgClose} style={{width: 20, height: 20}} />
                                </TouchableOpacity>                               
                            </View>
                            <Text style={[styles.text, {marginTop: 10, marginBottom: 10}]}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Etiam odio magna, tempus mollis porta vel.
                            </Text>
                            
                            <StripeCard showSaveButton={false}/>
                            <View style={styles.buttons}>
                                <TouchableOpacity 
                                    style={[styles.button, {backgroundColor: '#313132', marginRight: 12}]}
                                    onPress={() => this.closeModalUpgrade()}
                                >
                                    <Text style={[styles.text, styles.buttonText, {color: '#838384'}]}>NO THANKS</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.button} onPress={() => this.openPaymentModal()}>
                                    <Text style={[styles.text, styles.buttonText]}>UPGRADE</Text>
                                </TouchableOpacity>
                            </View>
                        </>
                    }
                </View>
            
            <View>

            </View>
                

            </ModalStyled> : null}
            </>
        )
    } 
}

const ModalStyled = styled.View`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`

const StyledText = styled.Text`
color: #FFF;
text-align: center;
font-family: Roboto Condensed;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.64px;
margin-left: 10px;
`
const DropDownBox = MaterialStyled(Box)({
    width: 360,
    height: 300,
    backgroundColor: '#1F1F21',
    padding: 16,
    position: 'absolute',
    right: '40px',
    zIndex: 4,
    borderColor: 'red',
    boxSizing:"border-box"
});

const ModalHeader = MaterialStyled(Box)({
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    paddingBottom:"10px"
});

const ModalHeaderText = MaterialStyled(Typography)({
    color: '#FFFFFFBF',
    fontSize: 24,
    fontFamily: 'Roboto Condensed'
});

const ModalHeaderCloseButton = MaterialStyled("button")({
    border:"none",
    backgroundColor:"transparent",
    cursor:"pointer"
});

const ModalHeaderCloseIcon = MaterialStyled("img")({
    width: 20,
    height: 20
});

const styles = StyleSheet.create({
    modal: {
        width: 486,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: -100,
    },
    title: {
        fontSize: 24,
        fontFamily: 'Roboto Condensed',
        marginBottom: 12,
    },
    colorTitle: {
        color: '#38FF4C',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 16
    },
    textLink: {
        color: 'rgba(196, 237, 200, 1)',
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    label: {
        fontSize: 16,
        color: 'white',
        marginTop: 24,
        marginBottom: 8,
    },
    input: {
        borderColor: '#3f3f41',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#2b2b2e',
        color: 'white',
        height: 40,
        fontSize: 16,
        paddingLeft: 12,
        paddingRight: 12,
    },
    button: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: '#38FF4C',
        width: 'fit-content',
        marginTop: 24,
        marginLeft: 'auto',
        borderRadius: 4
    },
    buttonText: {
        color: '#1F1F21',
        fontFamily: 'Roboto Condensed',
    },
    point: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12
    },
    buttons: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginTop: 10,
    },

    // navigation
    container2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 64,
        backgroundColor: '#131314',
        paddingLeft: 40,
        paddingRight: 40,
      },
      controls: {
        display: 'flex',
        flexDirection: 'row',
      },
      control: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
      },
      Text: {
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        fontWeight: '400',
        marginLeft: 5
      },
      textUpgrade: {
        color: 'rgba(56, 255, 76, 1)',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto Condensed',
      },
      rightSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      upgrade: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      search: {
        borderColor: '#343436',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: 13,
        paddingRight: 13,
        marginLeft: 24,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative'
      },
      input2: {
        color: '#79797a',
      },
      user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 24,
      }, 
      emailText: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 14,
        fontWeight: '500'
      },
})