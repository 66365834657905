import React from "react";

// Customizable Area Start
import PrivacyPolicyController, {
  PrivacyPolicyInterface,
  Props,
  configJSON,
} from "./PrivacyPolicyController.web";
import { Box, Typography, styled } from "@material-ui/core";
import { imgLogo } from "./assets";
// Customizable Area End


export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area Enda

  render() {
    // Customizable Area Start
    return (
      <PrivacyPolicyMainBox>
        <HeaderBox>
          <LogoImage src={imgLogo} />
        </HeaderBox>
        <PrivacyPolicyDetailBox>
          <HeadingText>{configJSON.privacyPolicyText}</HeadingText>
          {
            this.state.privacyPolicies.map((conditionData:PrivacyPolicyInterface) => {
              return (
                <>
                  <ContentText>
                    {conditionData.attributes.content}</ContentText>
                  {conditionData.attributes.paragraphs.data.map(paragraphs => (
                    <ParagraphText>
                      {`${paragraphs.attributes.content}`}
                    </ParagraphText>
                  ))}
                </>
              );
            })}
        </PrivacyPolicyDetailBox>
      </PrivacyPolicyMainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ParagraphText = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.75)',
  marginBottom: "32px",
  fontSize: "16px",
  fontFamily: "Roboto",
  fontWeight: 400
});

const ContentText = styled(Typography)({
  fontFamily: 'Roboto Condensed',
  fontSize: 32,
  color: 'rgba(255, 255, 255, 0.75)', 
  marginBottom: 16
});

const PrivacyPolicyDetailBox = styled(Box)({
  minWidth: '1050px',
  maxWidth: '1050px', 
  marginRight: 'auto',
  marginLeft: 'auto',
  minHeight:"100vh",
  boxSizing:"border-box",
  "@media(max-width:1000px)":{
  width: '100%', 
  height:"100%",
  padding:"10px",
  minWidth: '100%'
  }
});

const PrivacyPolicyMainBox = styled(Box)({
  backgroundColor: "#1F1F21",
  width: "100%", 
  padding: "24px",
  boxSizing:"border-box"
});

const LogoImage = styled("img")({
  width: "155px",
  height: "24px"
});

const HeadingText = styled(Typography)({
  fontFamily: 'Roboto Condensed',
  fontSize: 48,
  fontWeight: 400,
  color: '#fff',
  textAlign: 'left',
  marginBottom: 32
})

const HeaderBox = styled(Box)({
  height: '64px',
  marginLeft: '40px',
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center'
})
// Customizable Area End