Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 1000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.loadingText = "Loading dashboard, please wait..."
exports.copyright = "Copyright © 2023 GLANCE LED. All rights reserved";
exports.noConnection = "Oops! No internet connection";
exports.makeSureConnection = "Make sure wi-fi or cellular data is turned on and then please try again.";
// Customizable Area End