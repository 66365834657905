import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Panel {
  id: string;
  type: string;
  attributes: {
      name: string;
      mac_address: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  upgradeModal: boolean;
  paymentModal: boolean;
  glancePanels: any;

  contactEmailInput: string;
  phoneNumberInput: string;
  fullNameInput: string;
  chosenGlanceId: number | string | unknown;
  chosenGlanceName: string | null;
  showDropdown: boolean;
  messageInput: string;
  sendSuccess: boolean;
  emailFormatError: boolean;
  phoneFormatError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  apiGetUserGlanceList: any;
  getUserProfileDetailsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      upgradeModal: false,
      paymentModal: false,
      glancePanels: [],

      chosenGlanceId: null,
      chosenGlanceName: '',
      showDropdown: false,
      contactEmailInput: '',
      phoneNumberInput: '',
      fullNameInput: '',
      messageInput: '',
      sendSuccess: false,
      emailFormatError: false,
      phoneFormatError: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    console.log('message :::::: ',message)
    console.log('responseJSON::::::: ',message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    ))

    if(message.properties.RestAPIResponceDataMessage === 'sendContactUs') {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log('responseJSON::::::: ',responseJson)

      if(responseJson.hasOwnProperty('errors')) {
        const hasContact = responseJson.errors.some((error: any) => error.hasOwnProperty('contact'));
        if(hasContact) {
          this.setState({
            phoneFormatError: true
          })
        }
      } else {
        this.setState({
          sendSuccess: true,
          phoneFormatError: false,
          emailFormatError: false
        })
      }

      runEngine.debugLog("API Message Recived", message);
    }

    if(this.getUserProfileDetailsApiCallId === apiRequestCallId){
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.hasOwnProperty('errors')){
        const {email, full_phone_number, first_name, last_name} = responseJson.data.attributes;
        this.setState({
          contactEmailInput:email,
          phoneNumberInput:full_phone_number,
          fullNameInput: first_name+ " " + last_name 
        });
      }
    }
    runEngine.debugLog("API Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  setGlances(glances: any) {
    this.setState({
      glancePanels: glances,
      chosenGlanceId: glances[0].id
    })
  }

  changeMessageInput(msg: string) {
    this.setState({
      messageInput: msg
    })
  }

  changeFullNameInput(value: string) {
    this.setState({
      fullNameInput: value
    })
  }

  changePhoneNumberInput(value: string) {
    this.setState({
      phoneNumberInput: value
    })
  }

  changeContactEmailInput(value: string) {
    this.setState({
      contactEmailInput: value
    })
  }

  sendContactUs() {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if(emailRegex.test(this.state.contactEmailInput)) {
      this.setState({
        emailFormatError: false
      })
      const header = {
        "Content-Type": configJSON.contactUsApiContentType, 
        token: localStorage.getItem('authToken') as string,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;
      requestMessage.messageId = 'sendContactUs'

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_contact_us/contacts"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      let data = {
        data: {
          full_name: this.state.fullNameInput,
          last_name: "",
          email: this.state.contactEmailInput,
          phone_number: this.state.phoneNumberInput,
          description: this.state.messageInput,
          panel_id: this.state.chosenGlanceId
        }
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod 
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.setState({
        emailFormatError: true
      })
    }
  }

  closeSuccessModal() {
    this.setState({
      sendSuccess: false
    })
  }

  getBorderColor(percentage: number) {
    if(percentage < 24) {
      return '#80202'
    }

    if(percentage < 39) {
      return '#db6f5'
    }

    if(percentage < 49) {
      return '#e87d5'
    }

    if(percentage < 74) {
      return '#54a85'
    }

    if(percentage < 81) {
      return '#6bb57'
    }

    if(percentage > 81) {
      return '#06780'
    }
  }

  getPercentageColor= (type: string) => {
    switch(type) {
     case 'step1':
       return '#D42CD'
     case 'step2':
       return '#F1E51'
     case 'step3':
       return '#F34D8'
     case 'step4':
       return '#36C95'
     }
   }

   getUserProfileDetails = () => {
    const header = {
      "Content-Type": configJSON.getUserProfileDetailsContentType,
      token: localStorage.getItem('authToken') as string,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileDetailsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserProfileDetailsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectGlanceName = (event: { target: { value: string | unknown } }) => {
    const value = event.target.value;
    this.setState({
      chosenGlanceId:value
    });
  };
  // Customizable Area End
}
