import React from "react";
// Customizable Area Start
import {
  SafeAreaView,
  View,
  Text,
  StyleSheet,
  Image, 
  TouchableOpacity,
  ImageBackground
} from "react-native";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";

// Merge Engine - import assets - Start
import { imgLogo, notConnectedIcon, bgLoading, loader } from "./assets";
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import SplashscreenController, { Props, configJSON } from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Dimensions.addEventListener("change", () => {
    //   MergeEngineUtilities.init(
    //     artBoardHeightOrg,
    //     artBoardWidthOrg,
    //     Dimensions.get("window").height,
    //     Dimensions.get("window").width
    //   );
    //   this.forceUpdate();
    // });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    

    // Merge Engine - render - Start
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ImageBackground source={bgLoading} style={styles.splashScreenView} resizeMode="cover">
          <View style={{
            display: 'flex', 
            width: '100%', 
            justifyContent: 'space-between',
          }}>
            <View style={
              [styles.innerWrapper, {
                height: '92vh',
                marginLeft: 'auto', 
                marginRight: 'auto'
              }]
            }>
              <Image style={styles.imgLogo} source={imgLogo} />
              {!this.state.isDisconnected &&
                <>
                  <View style={{width: '72px', height: '72px'}}></View>
                  <Image style={styles.loading} source={{ uri: loader}} />
                  <Text style={styles.loadingLabel}>{this.state.loadingText}</Text>
                </>
              }
              {this.state.isDisconnected && 
                <>
                  <Image source={notConnectedIcon} style={styles.notConnected} />
                  <View style={{marginTop: '24px', marginBottom: '16px'}}>
                    <Text style={{
                      color: '#fff', 
                      fontWeight: '400', 
                      fontSize: 24, 
                      textAlign: 'center', 
                      fontFamily: 'Roboto Condensed'
                    }}>
                      {configJSON.noConnection}
                    </Text>
                  </View>
                  <Text style={{
                    color: 'rgba(255, 255, 255, 0.75)', 
                    fontWeight: '400', 
                    fontSize: 16, 
                    textAlign: 'center',
                    lineHeight: 24
                  }}>
                    {configJSON.makeSureConnection}
                  </Text>
                  <View style={styles.tryAgainBtn}>
                    <TouchableOpacity
                      onPress={() => this.handleConnectionChange()}
                      accessibilityLabel="Try again"
                      style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                      <Text style={{color: '#1F1F21', fontSize: 16, fontWeight: '500'}}>
                        TRY AGAIN
                      </Text>
                    </TouchableOpacity>
                  </View>
                  
                </>
              }
              
            </View>
            <Text style={styles.copyright}>{this.state.copyright}</Text>
          </View>

        </ImageBackground>
      </SafeAreaView>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  splashScreenView: {
    backgroundColor: "rgba(0, 0, 0, 1)",
    minHeight: '900px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  innerWrapper: {
    width: `${true ? '360px' : '320px'}`,
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
  },
  
  imgLogo: {
    width: '320px',
    height: '48px',
    marginBottom: '13px',
    marginRight: 'auto',
    marginLeft: 'auto'
  },

  loading: {
    width: '48px',
    height: '48px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  loadingLabel: {
    color: 'rgba(255, 255, 255, 0.75)',
    fontSize: 16,
    fontWeight: '400',
    marginTop: '36px',
    textAlign: 'center',
  },

  notConnected: {
    width: '48px',
    height: '48px',
    marginTop: '40px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  tryAgainBtn: {
    borderRadius: 4,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 16.00,
    shadowColor: 'rgba(56, 255, 76, 0.16)',
    width: '360px',
    height: '32px',
    marginTop: '34px',
    backgroundColor: '#38FF4C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  copyright: {
    color: 'rgba(255, 255, 255, 0.40)', 
    fontSize: 16, 
    fontWeight: '400',
    marginLeft: '128px',
    marginBottom: '32px'
  },

  safeAreaView: {
    flex: 1,
    paddingTop: 0,
  }
});
// Customizable Area End
