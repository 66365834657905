import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback, 
} from "react-native";

import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import {imageLogo, arrowLeftIcon} from './assets'
import { Typography } from "@material-ui/core"
import {
  ContainerContent, 
  LeftSide, 
  ContentLeftSide, 
  Logo, 
  Title, 
  Subtitle, 
  InputLabel, 
  InputStyle, 
  InputWrapper,
  ButtonGreen,
  ButtonGreenText,
  BackToSignIn,
  Arrow,
  Copyright,
  DecorImage
} from '../../../components/src/StyleForgotPassword'
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <ContainerContent>
              <LeftSide>
                <ContentLeftSide>
                  <Logo source={imageLogo}/>
                  <View>
                    <Title>
                      Forgot Password?
                    </Title>
                    <Subtitle>
                      No worries, we will send you reset instructions.
                    </Subtitle>
                    
                    <Formik
                      initialValues={{ accountType: "email_account", email: "" }}
                      validationSchema={Yup.object().shape(this.state.emailSchema)}
                      validateOnMount={true}
                      validateOnChange={true}
                      onSubmit={(values, actions) => {
                        this.goToSendEmailPage(values);
                        actions.setSubmitting(false);
                      }}
                      data-testid="forgotPasswordEmailForm"
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        errors,
                        setFieldTouched,
                        touched,
                      }) => (
                          <>
                            <InputLabel>Email:</InputLabel>
                            <InputWrapper>
                              <InputStyle
                                testID={"txtInputEmail"}
                                // autoCompleteType={this.firstInputAutoCompleteType}
                                keyboardType={this.firstInputKeyboardStyle}
                                placeholder={this.firstInputPlaceholder}
                                onChangeText={handleChange("email")}
                              />
                            </InputWrapper>
                            {this.state.accountError && <Typography style={{color: 'red'}}>Account not found</Typography>}
                            <ButtonGreen
                              data-testid="btnGetOtpForEmailButton"
                              onPress={() => handleSubmit()}
                            >
                              <ButtonGreenText>
                                SEND RECOVERY LINK
                              </ButtonGreenText>
                            </ButtonGreen>
                          </>
                      )}
                    </Formik>

                    <BackToSignIn data-testid="backto" onPress={() => navigation.navigate('EmailAccountLoginBlock')}> 
                      <Arrow source={arrowLeftIcon}/>
                      <Text style={{color: 'rgba(255, 255, 255, 0.75)', marginLeft: 5}}>Back to</Text>
                      <Text style={{color: '#38FF4C', marginLeft: 5}}>Sign in</Text>
                    </BackToSignIn>
                  </View>          
                  <Copyright>Copyright © 2023 GLANCE LED. All rights reserved</Copyright>
                </ContentLeftSide>
              </LeftSide>
              <DecorImage></DecorImage>
            </ContainerContent>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  containerWeb: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});