export const imgDecor = require("../assets/ic_image_logo.png");
export const imgDashboard = require("../assets/image_dashboard.png")
export const imgDashboardActive = require("../assets/image_dashboard_active.png")
export const imgSettings = require("../assets/settings.png")
export const imgSettingsActive = require("../assets/settings_active.png")
export const imgUpgrade = require("../assets/image_upgrade.png")
export const imgSearch = require("../assets/image_search.png")
export const imgAvatar = require("../assets/group_avatar.png")
export const imgCheckedCircle = require("../assets/image_checked-circle.png")
export const imgClose = require("../assets/button_popup-close.png")
export const imgThumbnail = require("../assets/image_thumbnail.png")
export const imgAddCircle = require("../assets/add-circle.png")
export const imgAdd = require("../assets/image_add.png")
export const imgLogo = require("../assets/image_logo.png")
export const imgEdit = require("../assets/imagebutton_edit.png")
export const imgCollapse = require("../assets/imagebutton_collapse.png")
export const imgSettingsGreen = require("../assets/settingsIconGreen.png")
export const imgAddGroup = require("../assets/add-group.png")
export const imgCloseWhite = require("../assets/button_close.png")
export const imgDropdownManage = require("../assets/manage.png")
export const imgDropdownTerms = require("../assets/terms.png")
export const imgDropdownLogout = require("../assets/logout.png")
export const imgStock = require("../assets/stock.png")
export const imgSocial = require("../assets/social.png")
export const imgSport = require("../assets/sport.png")
export const imgUtility = require("../assets/utility.png")
export const imgNews = require("../assets/news.png")
export const imgApp = require("../assets/app.png")
export const arrowDown = require("../assets/arrow-down.png")
export const imgRemove = require("../assets/image_remove.png")
export const stockModalImg = require("../assets/stock-modal-img.png")
export const imgAddWhite = require("../assets/add-white.png")
export const imgMail = require("../assets/mail.png")
