import React from "react";
// Customizable Area Start
import { Box, Button, Typography, styled} from "@material-ui/core";
import { Image } from 'react-native'

import { Modal } from "./Modal.web";
import { imgThumbnail, imgAddCircle, imgAdd } from "./assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Modal navigation={this.props.navigation}/> 
      <MainBox>
        <Box style={{padding: '40px', width: '100%', maxWidth: 1440, marginRight: 'auto', marginLeft: 'auto'}}>
          <Box style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
            <Typography style={webStyles.title}>Manage your GLANCE LEDs</Typography>
            <Button  
              style={{
                paddingTop: 8,
                paddingRight: 12,
                paddingBottom: 8, 
                paddingLeft: 8,
                border: '1px solid rgba(236, 236, 238, 0.1)',
                display: 'flex',  
                alignItems: 'center',
                height: 40,
                marginRight: 40
              }}
              onClick={() => this.props.navigation.navigate('AddGlancePage')}
            >
              <Image source={imgAdd} style={{width: 24, height: 24, marginRight: 8}} />
              <Typography style={{color: 'rgba(196, 237, 200, 1)'}}>Add A NEW GLANCE</Typography>  
            </Button>
          </Box>
          {/* @ts-ignore */}
          <Box style={webStyles.glances}>
            {
              this.state.glancePanels.map((element: any) => 
                <button style={webStyles.glanceBox} onClick={() => this.props.navigation.navigate('GlanceDetails', {id: element.id })} key={element.id}>
                  <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                    <Box style={{width: 8, height: 8, borderRadius: '50%', backgroundColor: 'rgba(56, 255, 77, 1)', marginRight: 8}}></Box>
                    <Typography style={{fontSize: 20, color: 'white'}}>{element.attributes.name}</Typography>
                  </Box>
                  <Box style={{
                    width: 292, 
                    height: 146, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundImage: `url(${imgThumbnail})`
                  }}></Box>
                </button>
              )
            }
            {/* @ts-ignore */}
            <Box 
              style={webStyles.boxEmpty}
              onClick={() => this.props.navigation.navigate('AddGlancePage')}
              data-testid='new'
            >
              <Box style={webStyles.boxEmptyIcon}></Box>
            </Box>
          </Box>
          
        </Box>
      </MainBox>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainBox = styled(Box)({
  width: '100%',
  height: 'auto',
  minHeight:"100vh",
  backgroundColor: '#1f1f21',
})

const webStyles = {
  title: {
    fontSize: 32, 
    color: 'rgba(255, 255, 255, 0.75)',
    marginBottom: 40
  },

  glances: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },

  glanceBox: {
    width: 320,
    border: '2px solid rgba(255, 255, 255, 0.04)',
    borderRadius: 8,
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 32,
    backgroundColor: 'rgba(217, 217, 217, 0.06)',
    marginRight: 40,
  },

  boxEmpty: {
    marginRight: 0, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: 292,
    border: '2px solid rgba(255, 255, 255, 0.04)',
    borderRadius: 8,
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    cursor: 'pointer',
    marginBottom: 32,
    backgroundColor: 'rgba(217, 217, 217, 0.06)',
    minHeight: 188,
  },

  boxEmptyIcon: {
    backgroundImage: `url(${imgAddCircle})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 56,
    height: 56,
  },




  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "#000000",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
