import React from 'react'
import {
    Text,
    View,
} from "react-native";
import { 
    ContainerContent, 
    Title, 
    AddGlance, 
    Box, 
    Header, 
    Dot, 
    Form, 
    Img, 
    Logo, 
    FormText, 
    Input, 
    FormTextSecond, 
    // ButtonSubmit, 
    // TextBtn,
    CreateSelectionSetBtn,
    CreateSelectionSetBtnText,
    BtnImg,
} from '../../../components/src/StyleAddGlance';
import { imgAdd, imgEdit, imgCollapse, imgAddGroup, imgSettingsGreen } from './assets';
import NavigationApp from '../../../components/src/NavigationApp';
import DashboardController from './DashboardController.web';
import UpgradeModal from '../../../components/src/UpgradeModal';
import PaymentModal from '../../../components/src/PaymentModal';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SelectionSetSettingsModal } from "../../../components/src/SelectionSetSettingsModal";
import { Typography, styled } from '@material-ui/core';

export default class AddGlancePage extends DashboardController {
    render() {
        return (
            <View style={{
                display: "flex",
                flexDirection: 'column',
                width: '100%',
                minHeight: '900px'
            }}>
                {
                    this.state.upgradeModal && 
                        <UpgradeModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }

                {
                    this.state.paymentModal && 
                        <PaymentModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }
                <ContainerContent>
                    <NavigationApp navigation={this.props.navigation} showUpgradeModal={this.showUpgradeModal} />
                    <Title>
                        Add GLANCE LED
                        <CreateSelectionSetBtn data-testid='add-new-glance'
                            // onPress={() => this.props.navigation.navigate('CustomizableUserProfile')}
                            onPress={this.addNewGlancePanel}
                        >
                            <CreateSelectionSetBtnText>
                                <BtnImg
                                    source={imgAdd}
                                    style={{marginRight: 6}}
                                />
                                Add GLANCE LED
                            </CreateSelectionSetBtnText>
                        </CreateSelectionSetBtn>
                    </Title>
                    <AddGlance>
                        <Box>
                            <Header>
                                <Dot />
                                <Text style={{color: 'rgba(255, 255, 255, 1)', fontSize: 20}}>My GLANCE LED 1</Text>
                            </Header>
                            <Img>
                                <Logo></Logo>
                            </Img>                            
                        </Box>
                        <Form>
                            <FormText>GLANCE Name</FormText>
                            <Input data-testid='glance-name' placeholder='Your GLANCE name' onChangeText={(value: any) => this.setGlanceName(value)}/>
                            <FormTextSecond>MAC address</FormTextSecond>
                            <Input data-testid='glance-mac-address' style={{borderColor : this.state.macAddressValidation ? "red" :"#3f3f41"}} value={this.state.macAddressModalText} placeholder='e.g. 11:22:33:44:55:66' onChangeText={(value: string) => this.setMacModal(value)}/>
                            {this.state.validationError && <ValidationErrorText>{this.state.validationError}</ValidationErrorText>}
                            {/* <ButtonSubmit>
                                <TextBtn>ADD GLANCE & SAVE</TextBtn>
                            </ButtonSubmit> */}
                        </Form>
                    </AddGlance>                    
                </ContainerContent>
            </View>
        )
    }
}

const ValidationErrorText = styled(Typography)({
    color: 'red',
    padding:"5px 0"
})