// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import './App.css';
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import CustomizableUserProfile from "../../components/src/CustomisableUserProfile.web";
import Dashboard from '../../blocks/dashboard/src/Dashboard'
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Cfintegrationdatabaseforglancepanelfrontend from "../../blocks/cfintegrationdatabaseforglancepanelfrontend/src/Cfintegrationdatabaseforglancepanelfrontend";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../components/src/ContactusPage";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfintegrationdatabaseforglancepanelbackend2 from "../../blocks/cfintegrationdatabaseforglancepanelbackend2/src/Cfintegrationdatabaseforglancepanelbackend2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import AddGlancePage from "../../blocks/dashboard/src/AddGlancePage";
import AddSelectionSet from '../../components/src/AddSelectionSet';
import GlanceDetails from '../../components/src/GlanceDetails';
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";

const routeMap = {
AddSelectionSet:{
  component: AddSelectionSet,
  path: '/AddSelectionSet',
},
AddGlancePage:{
  component: AddGlancePage,
  path: '/AddGlancePage'
},
GlanceDetails: {
  component: GlanceDetails,
  path: '/GlanceDetails/:id'
},
CustomizableUserProfile:{
  component:CustomizableUserProfile,
  path:"/UserProfiles"
},
CustomizableUserProfileBilling:{
  component:CustomizableUserProfile,
  path:"/UserProfiles?Billing"
},
Dashboard:{
  component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Cfintegrationdatabaseforglancepanelfrontend:{
 component:Cfintegrationdatabaseforglancepanelfrontend,
path:"/Cfintegrationdatabaseforglancepanelfrontend"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Cfintegrationdatabaseforglancepanelbackend2:{
 component:Cfintegrationdatabaseforglancepanelbackend2,
path:"/Cfintegrationdatabaseforglancepanelbackend2"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
component:Splashscreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  PrivacyPolicy:{
    component:PrivacyPolicy,
    path:"/PrivacyPolicy"
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  state = {
    showGrantModal: true
  }

  render() {

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
