import { createTheme } from "@material-ui/core/styles";
import { imgDecor} from "./assets";

export const themeStyles = createTheme({
    palette: {
        primary: {
        main: "#0000ff",
        contrastText: "#fff",
        },
    },
    overrides: {
        MuiContainer: {
        root: {
            margin: 0,
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            backgroundColor: '#1F1F21',
            fontFamily: "Roboto-Medium"
        },
    },
    MuiTypography: {
        h4: {
            fontSize: '48px',
            fontWeight: 400,
            lineHeight: '56px',
            letterSpacing: '0em',
            color: '#ffffff', 
            marginBottom: '10px',
            fontStretch: 'condensed',
        }
    },
    MuiInputBase: {
        root: {
            backgroundColor: '#2B2B2E',
            borderRadius: '8px',
            padding: '5px 20px',
            maxWidth: '430px',
            '&::placeholder': {
                display: 'none'
            },
        },
        // It change styles for placeholders in inputs
        input: {
            color: 'rgba(255, 255, 255, 0.4)',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
        }
    },
    MuiInput: {
        underline: {
            '&::before': {
                display: 'none'
            },
            '&::after': {
                borderBottom: '0'
            },
        },
    },
    MuiSvgIcon: {
        root: {
            color: 'rgba(255, 255, 255, 0.6)'
        }
    },
    MuiIconButton: {
        root: {
            padding: 0
        }
    },
}
});
  
export const imageStyles = {
    width: '50%',
    backgroundImage: `url(${imgDecor})`, 
    backgroundPosition: 'center', 
    backgroundSize: 'cover',
    '@media screen and (max-width: 1279px)': {
        width: '0',
    }
}
  
export const boxStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: '50%',
    paddingBottom: '40px',
    '@media screen and (max-width: 1279px)': {
        width: '100%',
        padding: '0 20px'
    }
}
  
export const checkboxStyles = {
    color: '#ffffff', fontSize: '16px', fontWeight: '500',
    "& .Mui-checked": {
        "& svg": {
        color: "#38FF4C !important",
        }
    }
}