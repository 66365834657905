import styled from 'styled-components/native';
import { imgSearch } from '../../blocks/dashboard/src/assets';

export const ContainerContent = styled.View`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #1f1f21;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.View`
    margin-left: 40px;
`

export const Title = styled.Text`
    margin-top: 24px;
    font-family: Roboto Condensed;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const SelectionInput = styled.TextInput`
    max-width: 548px;
    width: 100%;
    border: 1px solid rgba(236, 236, 238, 0.1);
    border-radius: 4px;
    background-color: #252528;
    color: rgba(255, 255, 255, 0.4);
    height: 40px;
    margin-top: 24px;
    padding-left: 40px;
`

export const SelectionTab = styled.View`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
`

export const Tab = styled.View`
    padding: 8px;
    border-radius: 4px;
    margin-right:16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
`

export const TextTab = styled.Text`
font-family: Roboto;
font-size: 18px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 0.75);
`

export const ImageTab = styled.Image`
    width: 20px;
    height: 20px;
    margin-right: 4px;
`

export const TabsStockCrypto = styled.View`
    width: 96%;
    height: 40px;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`

export const Elements = styled.View`
    width: 98%;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const Element = styled.View`
    width: 332px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #272729;
    border: 2px solid rgba(255, 255, 255, 0.04);
    border-radius: 4px;
    margin-right: 32px;
    margin-bottom: 32px;
`

export const ElementStock = styled(Element)`
    margin-right: 0;
`

export const ElementText = styled.Text`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 16px;
    color: rgba(255, 255, 255, 0.75);
    text-transform: capitalize;
`

export const ElementImage = styled.Image`
    margin-right: 16px;
    width: 24px;
    height: 24px;
`

export const ElementImageStock = styled(ElementImage)`
    margin-right: 16px;
    width: 24px;
    height: 24px;
`

export const BottomBar = styled.View`
    position: fixed;
    width: 100%;
    height: 80px;
    bottom: 0;
    background: rgba(19, 19, 20, 1);
`

export const BottomBarWrapper = styled.View`
    width: 96%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
`

export const TextSelected = styled.View`
    display: flex;
    flex-direction: row;
`

export const ButtonSelected = styled.TouchableOpacity`
    background-color: rgba(56, 255, 76, 1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
`
