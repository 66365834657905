import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Image
} from "react-native";
import { imgLogo } from "./assets";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import { Box, Typography, styled } from "@material-ui/core";
import { TermsAndConditionInterface } from "./TermsConditionsDetailController";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={{backgroundColor: '#1F1F21', width: '100%',  padding: '24px'}}>
        <View style={{height: '64px', marginLeft: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Image source={imgLogo} style={{width: '155px', height: '24px'}}/>
        </View>
        
        <TermsConditionsDetailBox>
          <HeadingText>{configJSON.termsAndConditionsText}</HeadingText>
          {
            this.state.termsAndConditions.map((conditionData:TermsAndConditionInterface) => {
              return (
                <>
                  <ContentText>
                    {conditionData.attributes.content}</ContentText>
                  {conditionData.attributes.paragraphs.data.map(paragraphs => (
                    <ParagraphText>
                      {`${paragraphs.attributes.content}`}
                    </ParagraphText>
                  ))}
                </>
              )
            })}
        </TermsConditionsDetailBox>
        
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ParagraphText = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.75)',
  marginBottom: "32px",
  fontSize: "16px",
  fontFamily: "Roboto",
  fontWeight: 400
});

const ContentText = styled(Typography)({
  fontFamily: 'Roboto Condensed',
  fontSize: 32,
  color: 'rgba(255, 255, 255, 0.75)', 
  marginBottom: 16
});

const TermsConditionsDetailBox = styled(Box)({
  minWidth: '1050px',
  maxWidth: '1050px', 
  marginRight: 'auto',
  marginLeft: 'auto',
  minHeight:"100vh",
  boxSizing:"border-box",
  "@media(max-width:1000px)":{
  width: '100%', 
  height:"100%",
  padding:"10px",
  minWidth: '100%'
  }
});

const HeadingText = styled(Typography)({
  fontFamily: 'Roboto Condensed',
  fontSize: 48,
  fontWeight: 400,
  color: '#fff',
  textAlign: 'left',
  marginBottom: 32
});
// Customizable Area End
