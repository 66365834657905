import React, {useState} from "react";
import {
  Box,
  Typography,
  styled as MaterialStyled,
  MenuItem,
  Select
} from "@material-ui/core";
import {
  Image,
  TouchableOpacity,
  TextInput,
  View
} from "react-native";
import styled from 'styled-components/native';
import { decorImg, facebookImg, linkedinImg, twitterImg, outlookImg } from "./assets";
import NavigationApp from './NavigationApp';

import ContactusController, { Panel, Props } from '../../blocks/contactus/src/ContactusController'
import UpgradeModal from "./UpgradeModal";
import PaymentModal from "./PaymentModal";
import { imgClose } from "../../blocks/dashboard/src/assets";
import { closeWhite, successSend } from "../../blocks/contactus/src/assets";


export default class ContactusPage extends ContactusController {

    async componentDidMount() {
        const fetchGlances = async () => {
            const domain = window.location.href.includes('.uat.')
            ? 'https://glancestartup-373059-ruby.b373059.uat.eastus.az.svc.builder.ai/'
            : window.location.href.includes('.stage.') 
                ? 'https://glancestartup-373059-ruby.b373059.stage.eastus.az.svc.builder.ai/'
                : 'https://glancestartup-373059-ruby.b373059.dev.eastus.az.svc.builder.cafe/'
                
            const url = `${domain}bx_block_glance/panels`;
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    token: localStorage.getItem('authToken') as string
                }
            });

            const data: any = await response.json()

            this.setGlances(data.data)
        }

        fetchGlances()
        this.getUserProfileDetails();
    }

  render() {  
    const showUpgradeModal = (type: string) => {
        if(type === 'upgrade') {
            this.setState({ 
                upgradeModal: true,
                paymentModal: false,
            })
        }

        if(type === 'payment') {
            this.setState({
                upgradeModal: false,
                paymentModal: true,
            })
        }

    }

    const closeUpgradeModal = () => {
        this.setState({
            upgradeModal: false,
            paymentModal: false,
        })
    }

    const showDropdown = () => {
        if(this.state.showDropdown) {
            this.setState({
                showDropdown: false
            })
        } else {
            this.setState({
                showDropdown: true
            })
        }
    }

    const closeDropdown = () => {
        this.setState({
            showDropdown: false
        })
    }

    const setGlance = (id: number, name: string) => {
        this.setState({
            chosenGlanceId: id,
            chosenGlanceName: name
        })
        closeDropdown()
    }

    const setName = (text: string) => {
        this.setState({ name: text });
    };
    
    const setEmail = (text: string) => {
        this.setState({ email: text });
    };
    
    const setPhoneNumber = (text: string) => {
        this.setState({ phoneNumber: text });
    };
    
    const setComments = (text: string) => {
        this.setState({ comments: text });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: 'column',
                width: '100%',
                minHeight: '900px',
            }}
        >
            {
                this.state.upgradeModal && 
                    <UpgradeModal 
                        showUpgradeModal={showUpgradeModal}
                        closeUpgradeModal={closeUpgradeModal}
                    />
            }

            {
                this.state.paymentModal && 
                    <PaymentModal 
                        showUpgradeModal={showUpgradeModal}
                        closeUpgradeModal={closeUpgradeModal}
                    />
            }

            {
                this.state.sendSuccess &&
                <ModalSuccessStyled data-modal='modal'>
                    {/* Modal popup */}
                    <View style={styles.modal}>
                        <TouchableOpacity onPress={() => this.closeSuccessModal()}>
                            <Image source={closeWhite} style={{marginLeft: 'auto', marginBottom: 40, width: 24, height: 24}} />
                        </TouchableOpacity>
                        
                        <Image source={successSend} style={{width: 107, height: 102, marginLeft: 'auto', marginRight: 'auto'}} />
                        <Typography style={styles.successText}>Your invitation is successfully sent!</Typography>
                    </View>
                </ModalSuccessStyled>
            }

            <Box sx={styles.navigation}>
                <NavigationApp navigation={this.props.navigation} showUpgradeModal={showUpgradeModal}/>
            </Box>
            <Box sx={styles.contactus}>
                <DecorImage>
                    <SocialIcons>
                        <Box sx={styles.boxSocial}>
                            <Image source={facebookImg} style={styles.socialImg} />
                        </Box>
                        <Box sx={styles.boxSocial}>
                            <Image source={linkedinImg} style={styles.socialImg}/>
                        </Box>
                        <Box sx={styles.boxSocial}>
                            <Image source={twitterImg} style={styles.socialImg}/>
                        </Box>
                        <Box sx={styles.boxSocial}>
                            <Image source={outlookImg} style={styles.socialImg}/>
                        </Box>
                    </SocialIcons>
                </DecorImage>
                <RightSide>
                    <Box sx={styles.formWrapper}>
                        <Typography style={styles.title}>Get In Touch</Typography>
                        <Typography style={styles.text1}>How can we help?</Typography>
                        <Typography style={styles.text1}>
                            If your Stock, Crypto, NFT, Mutual Fund, REIT, or Precious Metal is not 
                            supported by our app, please fill out the request form below and we will 
                            add them in less than 24 hrs and contact you directly.
                        </Typography>
                        <Typography style={styles.text2}>
                            If you have any other suggestions on what you would like to 
                            see supported by GLANCE, reach out!
                        </Typography>
                        <Box style={styles.form}>
                            <FirstRow>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '48%'}}>
                                    <Typography 
                                        style={styles.label}
                                        >Contact Email</Typography>
                                    <Input
                                        testID="email"
                                        placeholder='user1@glance-led.com'
                                        value={this.state.contactEmailInput}
                                        onChangeText={(value: string) => this.changeContactEmailInput(value)}
                                        style={{borderColor: this.state.emailFormatError ? 'red' : 'rgba(236, 236, 238, 0.1)'}}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '48%'}}>
                                    <Typography style={styles.label}>Phone Number</Typography>
                                    <Input
                                        testID="phone"
                                        placeholder='+1-212-456-7890'
                                        value={this.state.phoneNumberInput}
                                        onChangeText={(value: string) => this.changePhoneNumberInput(value.replace(/[^0-9+]/g, ''))}
                                        style={{borderColor: this.state.phoneFormatError ? 'red' : 'rgba(236, 236, 238, 0.1)'}}
                                    />
                                </Box>
                                
                            </FirstRow>
                            <SecondRow style={{zIndex: 10}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '48%'}}>
                                    <Typography style={styles.label}>Full Name</Typography>
                                    <Input
                                        testID="name"
                                        placeholder='Your first name & last name'
                                        value={this.state.fullNameInput}
                                        onChangeText={(value: string) => this.changeFullNameInput(value.replace(/[0-9]/g, ''))}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '48%'}}>
                                    <Typography style={styles.label}>Select GLANCE LED Panel</Typography>
                                    <StyledSelect
                                            value={this.state.chosenGlanceId}
                                            onChange={(event: { target: { value: string | unknown} }) => this.selectGlanceName(event)}
                                            data-test-id="selectGlancePanelBox"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                      backgroundColor: '#2b2b2e',
                                                      color: "#fff" 
                                                    },
                                                  },
                                            }}
                                            displayEmpty
                                        >   
                                            {this.state.glancePanels.map((glanceName:Panel) => {
                                                return(
                                                    <StyledMenuItem key={glanceName.id} value={glanceName.id}>
                                                    {glanceName.attributes.name}
                                                    </StyledMenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                </Box>
                                
                            </SecondRow>
                            <SecondRow style={{zIndex: 0}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <Typography style={styles.label}>Message</Typography>
                                    <TextArea
                                        testID="led"
                                        placeholder='Your message'
                                        multiline={true}
                                        value={this.state.messageInput}
                                        onChangeText={(value: string) => this.changeMessageInput(value)}
                                    />
                                </Box>
                                
                            </SecondRow>
                            <Button 
                                onPress={() => this.sendContactUs()} 
                                disabled={
                                    this.state.contactEmailInput === '' || 
                                    this.state.phoneNumberInput === '' || 
                                    this.state.messageInput === '' ||
                                    this.state.fullNameInput === '' 
                                }
                                style={{
                                    cursor: (this.state.contactEmailInput === '' || 
                                    this.state.phoneNumberInput === '' || 
                                    this.state.messageInput === '' ||
                                    this.state.fullNameInput === '' ) ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <Typography style={{fontWeight: 600}}>SUBMIT MESSAGE</Typography>
                            </Button>
                        </Box>
                    </Box>
                </RightSide>
            </Box>
        </Box>
    );
  }
}

// Customizable Area Start
const StyledSelect = MaterialStyled(Select)({
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#2b2b2e",
    border: "1px solid #ECECEE1A",
    borderRadius: "5px",
    padding: "2px 10px",
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    },
    "&&::before":{
        content:"none"
    },
    "&&::after":{
        content:"none"
    },
    "& .MuiSelect-select": {
      backgroundColor: "#2b2b2e",
      display:"flex",
      columnGap:"10px",
    },
    "& .MuiSelect-icon":{
        color:"#ECECEE1A"
    }
});
  
const StyledMenuItem = MaterialStyled(MenuItem)({
    display:"flex",
    alignItems:"center",
    color: "#ffffff",
    backgroundColor: "#2b2b2e !important",
    "&. MuiListItem-root.Mui-selected": {
        backgroundColor: "#2b2b2e",
        display:"flex",
        columnGap:"10px",
    },
    "&. MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#2b2b2e",
    }
});

const styles = {
    modal: {
        width: 640,
        height: 308,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: -100,
    },
    successText: {
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: 24,
        marginTop: 22,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
  navigation: {

  },
  contactus: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  rightSide: {
    
  },
  formWrapper: {
    width: 664,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  title: {
    fontFamily: 'Roboto Condensed',
    color: 'white',
    fontWeight: 400,
    fontSize: 48
  },
  text1: {
    color: 'rgba(255, 255, 255, 0.75)',
    marginBottom: 12
  },
  text2: {
    color: 'rgba(255, 255, 255, 0.75)',
  },
  form: {
    width: '100%',
    marginTop: 40
  },
  label: {
    color: 'white',
    marginBottom: 8
  },
  boxSocial: {
    width: 56,
    height: 56,
    backgroundColor: 'rgba(31, 31, 33, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  socialImg: {
    width: 32,
    height: 32
  }
};

const ModalSuccessStyled = styled.View`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`

const DecorImage = styled.View`
    width: 33%;
    height: 100%;
    min-height: 900px;
    background-size: cover;
    background-position: center;
    background-image: url(${decorImg});
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const RightSide = styled.View`
    background: rgba(31, 31, 33, 1);
    width: 67%;
    padding: 48px 16px 97px 16px;
    min-height: 900px;
`

const FirstRow = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Input = styled.TextInput`
    width: 100%;
    border: 1px solid rgba(236, 236, 238, 0.1);
    padding: 0 12px;
    color: white;
    height: 40px;
    background-color: rgba(43, 43, 46, 1);
    border-radius: 4px;
    :placeholder {
        color: rgba(255, 255, 255, 0.4); 
    }
`


const SelectGlance =  styled.TouchableOpacity`
    background-color: white; 
    width: 100%; 
    height: 50px; 
    display: flex; 
    flex-direction: row;
    padding-left: 15px;
    align-items: center;
    background-color: transparent;
`

const SecondRow = styled(FirstRow)`
    margin-top: 24px;
`

const TextArea = styled(Input)`
    width: 100%;
    height: 160px;
    padding-top: 12px;
`

const Button = styled.TouchableOpacity`
    width: 100%;
    padding: 13px 0;
    text-align: center;
    color: rgba(31, 31, 33, 1);
    background-color: rgba(56, 255, 76, 1);
    margin-top: 32px;
    font-weight: 500;
    border-radius: 4px;
    :hover {
        background-color: rgba(56, 255, 76, 0.8);
    }
`

const SocialIcons = styled.View`
    width: 75%;
    height: 56px;
    margin-bottom: 48px;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
