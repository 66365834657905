import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput} from 'react-native'
import { 
    imgDecor, 
    imgDashboardActive, 
    imgSettings, 
    imgUpgrade,
    imgSearch,
    imgAvatar,
    imgCheckedCircle,
    imgClose,
    // stockModalImg,
    imgCollapse
} from '../../blocks/dashboard/src/assets'
import styled from 'styled-components/native';
import { domainSend } from "./domain";
import { Box, styled as materialStyled } from "@material-ui/core";


interface StockModalProps {
    closeStockModal: () => void;
    activeSection: string;
    activeTab: string;
    fetchedElements: any;
    refresh: any;
    incrementAction: (value: number) => void;
    actions: number;
    selectionSetId: number;
}

export default function StockModal({closeStockModal, activeSection, activeTab, fetchedElements, refresh, incrementAction, actions, selectionSetId}: StockModalProps) {
    const [filteredData, setFilteredData] = useState([]);
    const [input, setInput] = useState('');
    const [selectionId, setSelectionId] = useState('');
    const [selectionType, setSelectionType] = useState('')
    const [selectionKey, setSelectionKey] = useState<string | null>('')
    const [selectionName, setSelectionName] = useState<string | null>('')
    const [selectionSetID, setSelectionSetID] = useState<string | null | undefined | number>('')

    const [activeId, setActiveId] = useState(null);


    const setSelectionElementData = (element: any) => {
        console.log('elemenet : id:  : '+JSON.stringify(element))
        
        setActiveId(element.id)

        if(activeSection !== 'trading') {
            const section = activeSection.slice(0, -1)
            setSelectionType(section)
        } else {
            setSelectionType(activeTab)
        }

        if(element.Ticker) {
            setSelectionKey(element.Ticker)
        } else {
            setSelectionKey(element)
        }

        if(element.Name) {
            setSelectionName(element.Name)

            setActiveId(element.Name)
        } else {
            setSelectionName(null)
            setActiveId(element)
        }

        setSelectionSetID(selectionSetId);

        // console.log(activeSection)
        // // sportS | standingS | teamS

        // console.log(activeTab)
        //  // stock | crypto
    }

    const sendRequest = () => {
        const domain = domainSend();

        const data = {
            selection_type: selectionType,
            key: selectionKey,
            name: selectionName,
            selection_set_id: selectionSetID
        }

        fetch(
            `${domain}bx_block_glance/selection_set_items`,
            {
                method: "POST",
                headers: {
                  token: localStorage.getItem('authToken') as string,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }
          )
          .then(response => response.json())
          .then(data => {
            // fetchResult()
            incrementAction(actions+1)
          })
    }

    const fetchResult = () => {
            const domain = domainSend();
    
            const url = window.location.href;
            const lastSegment = url?.split("/").pop();
            
            fetch(
                `${domain}bx_block_glance/selection_set_items/${lastSegment}`,
                {
                    method: "GET",
                    headers: {
                        token: localStorage.getItem('authToken') as string,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then(response => response.json())
            .then(data => {
                // window.location.href = window.location.href;
            })
        }

    const setInputFunc = (value: string) => {
        console.log('fetchedElements ===> ', fetchedElements)
        setInput(value)
        const filter = activeSection === 'trading' 
            ? fetchedElements?.filter((element: any) => element.Name.toLowerCase().includes(value.toLowerCase()))
            : fetchedElements?.filter((element: any) => element.toLowerCase().includes(value.toLowerCase()))
        setFilteredData(filter)
    }

    return (
        <>
   
                <ModalStyled data-modal='modal'>
                    {/* Modal popup */}
                    <View style={styles.modal}>
                            <>
                                <View style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                    <Text style={[styles.text, styles.title]}>
                                    Add {activeSection !== 'trading'
                                            ? activeSection == "newss" ? "News" : `${activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}` 
                                            : `${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}`
                                        }
                                    </Text>
                                    <TouchableOpacity onPress={closeStockModal}>
                                        <Image source={imgClose} style={{width: 20, height: 20}} />
                                    </TouchableOpacity>                               
                                </View>

                                <Separator></Separator>
                                
                                {/* <Image source={stockModalImg} style={{width: '100%', height: 220}}/> */}
                                <Text style={[styles.text, {marginTop: 10, marginBottom: 10}]}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Etiam odio magna, tempus mollis porta vel.
                                </Text>

                                <View style={{
                                    width: '100%', 
                                    height: '40px', 
                                    borderWidth: 1, 
                                    borderColor: 'rgba(236, 236, 238, 0.1)', 
                                    borderRadius: 4,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    backgroundColor: '#252528',
                                }}>
                                    <TextInput 
                                        style={{height: '100%', width: '100%', color: 'white'}}
                                        placeholder="Search.." 
                                        onChangeText={(value: any) => setInputFunc(value)} 
                                    />
                                </View>

                                <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Text style={{
                                        fontFamily: 'Roboto Condensed',
                                        fontSize: 24,
                                        fontWeight: '400',
                                        lineHeight: 28,
                                        letterSpacing: 0,
                                        color: 'rgba(196, 237, 200, 1)',
                                        marginTop: 14
                                    }}>{activeSection !== 'trading'
                                            ? activeSection == "newss" ? "News Selection" :`${activeSection.charAt(0).toUpperCase() + activeSection.slice(1)} Selection`
                                            : `${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Selection`
                                        }
                                    </Text>
                                    <TouchableOpacity>
                                        <Image source={imgCollapse}  style={{width: 24, height: 24, marginTop: 17}}/>
                                    </TouchableOpacity>
                                </View>
                                
                                    {/* @ts-ignore */}
                                <View style={{
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    height: '300px',
                                    marginTop: 12,
                                    overflow: 'auto',
                                }}>
                                    {true && <>
                                        {input.length < 1 &&
                                            fetchedElements?.map((element: any) => (
                                                <TouchableOpacity onPress={() => setSelectionElementData(element)}>
                                                    <View style={{width: '95%', paddingBottom: 10, paddingTop: 10, paddingLeft: 10,
                                                        backgroundColor: 
                                                            activeSection === 'trading' 
                                                            ?   activeId === element.Name 
                                                                    ? '#38FF4C' 
                                                                    : '#1f1f21'
                                                            :   activeId === element
                                                                    ? '#38FF4C' 
                                                                    : '#1f1f21'

                                                    }}>
                                                        <Text style={{
                                                            color: activeSection === 'trading' 
                                                                ? activeId === element.Name 
                                                                    ? '#232323' : 'rgba(255, 255, 255, 0.4)'
                                                                : activeId === element
                                                                ? '#232323' : 'rgba(255, 255, 255, 0.4)'}
                                                        }>
                                                            {activeSection === 'trading' ? element.Name : element}
                                                        </Text>
                                                    </View>
                                                </TouchableOpacity>
                                            ))
                                        }
                                        {input.length > 0 && filteredData?.map((element: any) => (
                                                <TouchableOpacity onPress={() => setSelectionElementData(element)}>
                                                    <SelectedItemBox style={{
                                                        backgroundColor: 
                                                            activeSection === "trading" 
                                                            ?   activeId === element.Name 
                                                                    ? "#38FF4C" 
                                                                    : "#1f1f21"
                                                            :   activeId === element
                                                                    ? "#38FF4C" 
                                                                    : "#1f1f21"
                                                    }}>
                                                        <Text style={{
                                                            color: activeSection === "trading" 
                                                                ? activeId === element.Name 
                                                                    ? "#232323" : "#FFFFFF68"
                                                                : activeId === element
                                                                ? "#232323" : "#FFFFFF68"}
                                                        }>
                                                            {activeSection === "trading" ? element.Name : element}
                                                        </Text>
                                                    </SelectedItemBox>
                                                </TouchableOpacity>
                                            ))
                                        }
                                </>}
                                </View>
                                
                                <View style={styles.buttons}>
                                    <TouchableOpacity 
                                        style={[styles.button, {backgroundColor: '#313132', marginRight: 12}]}
                                        onPress={closeStockModal}
                                    >
                                        <Text style={[styles.text, styles.buttonText, {color: '#838384'}]}>BACK TO SELECTIONS</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.button} onPress={() => sendRequest()}>
                                        <Text style={[styles.text, styles.buttonText]}>ADD SELECTION</Text>
                                    </TouchableOpacity>
                                </View>
                            </>
                    </View>
                </ModalStyled>
            
        </>
    )
}

const ModalStyled = styled.View`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`

const Separator = styled.View`
    width: 100%;
    height: 1px;
    border: 0.5px solid rgba(255, 255, 255, 0.08);
    margin-bottom: 16px;
`

const SelectedItemBox = materialStyled(Box)({
    width: "95%",
    paddingBottom: 10, 
    paddingTop: 10, 
    paddingLeft: 10,
});

const styles = StyleSheet.create({
    modal: {
        width: 486,
        backgroundColor: '#1f1f21',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        zIndex: 2,
        marginTop: 0,
    },
    title: {
        fontSize: 24,
        fontFamily: 'Roboto Condensed',
        marginBottom: 16,
    },
    separator: {
        borderColor: 'rgba(255, 255, 255, 0.08)',
        borderWidth: 1,
        width: '100%',
        height: 1
    },
    colorTitle: {
        color: '#38FF4C',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 16,
        width: '100%',
    },
    textLink: {
        color: 'rgba(196, 237, 200, 1)',
        textDecorationLine: 'underline',
        marginLeft: 5,
    },
    label: {
        fontSize: 16,
        color: 'white',
        marginTop: 24,
        marginBottom: 8,
    },
    input: {
        borderColor: '#3f3f41',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#2b2b2e',
        color: '#808082',
        height: 40,
        fontSize: 16,
        paddingLeft: 12,
        paddingRight: 12,
    },
    button: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: '#38FF4C',
        width: 'fit-content',
        marginTop: 24,
        marginLeft: 'auto',
        borderRadius: 4
    },
    buttonText: {
        color: '#1F1F21',
        fontFamily: 'Roboto Condensed',
    },
    point: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12
    },
    buttons: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginTop: 10,
    },

    // navigation
    container2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 64,
        backgroundColor: '#131314',
        paddingLeft: 40,
        paddingRight: 40,
      },
      controls: {
        display: 'flex',
        flexDirection: 'row',
      },
      control: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 32,
      },
      Text: {
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        fontWeight: '400',
        marginLeft: 5
      },
      textUpgrade: {
        color: 'rgba(56, 255, 76, 1)',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto Condensed',
      },
      rightSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      upgrade: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      search: {
        borderColor: '#343436',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: 13,
        paddingRight: 13,
        marginLeft: 24,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      input2: {
        color: '#79797a',
      },
      user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 24,
      },
      emailText: {
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 14,
        fontWeight: '500'
      },
})
