export const returnDomain = () => {
    if(window.location.href.includes('.uat.')) {
        return 'https://glancestartup-373059-react.b373059.uat.eastus.az.svc.builder.ai/'
    }

    if(window.location.href.includes('.dev.')) {
        return 'https://glancestartup-373059-react.b373059.dev.eastus.az.svc.builder.cafe/'   
    }

    if(window.location.href.includes('.stage.')) {
        return 'https://glancestartup-373059-react.b373059.stage.eastus.az.svc.builder.ai/'   
    }
    
    return "https://localhost:3000/";
}