export const decorImg = require('./decor-contactus.png')
export const facebookImg = require('./Facebook.png')
export const twitterImg = require('./Twitter.png')
export const outlookImg = require('./Oulook.png')
export const linkedinImg = require('./LinkedIn.png')
export const selectionSetSettingsCurrency = require('./selection-set-currency.png');
export const selectionSetSettingsLight = require('./selection-set-light.png');
export const selectionSetSettingsTimer = require('./selection-set-timer.png');
export const selectionSetSettingsUnits = require('./selection-set-units.png');
export const imgCalendar = require('./calendar.png');
export const imageUpload = require('./image_upload.png')
export const imageDeleteFile = require('./image_delete-file.png')
export const imgExpand = require("./arrowExpand.png")
export const playIcon = require("../../blocks/dashboard/assets/playIcon.png");