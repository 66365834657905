import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Avatar,
  Typography,
} from "@material-ui/core";
import {
  Image,
  TouchableOpacity,
  TextInput,
  View
} from "react-native";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import styled from 'styled-components';
import { avatar, edit, usFlag, arrowDownIcon } from '../../blocks/customisableuserprofiles/src/assets';
import NavigationApp from "./NavigationApp";
import { errorIcon } from "../../blocks/customisableuserprofiles/src/assets";
import UpgradeModal from "./UpgradeModal";
import { StripeCard } from "./StripeCard";
import { closeWhite, successSend } from "../../blocks/contactus/src/assets";
import "react-datepicker/dist/react-datepicker.css";
import { domainSend } from './domain';
import PhoneInput from "react-phone-input-2";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField,
  CountryCodeObj
} from "../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import PaymentModal from "./PaymentModal";
import Flags from "./Flags";
import FlagsBilling from './FlagsBilling'
import { boolean } from "yup";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    if(window.location.search.includes('?Billing')) {
    this.setActiveTab('glance')
    }

    const domain = domainSend();
    
    await fetch(
        `${domain}account_block/me`,
        {
            method: "POST",
            headers: {
                token: localStorage.getItem('authToken') as string
            },
        }
    )
    .then(response => response.json())
    .then(data => {
        this.setFirstName(data.data.attributes.first_name)
        this.setLastName(data.data.attributes.last_name)
        this.setEmail(data.data.attributes.email)
        this.setCountryCode(data.data.attributes.zip_code)
        if(data.data.attributes.country === null) {
          this.setCountry('United States')
        } else {
          this.setCountry(data.data.attributes.country)
        }
        this.setPhone(data.data.attributes.full_phone_number)
        this.setAvatar(data.data.attributes.user_image)
        this.setFile(data.data.attributes.user_image)
        this.setCountryCodeDigits(data.data.attributes.country_code);
        this.fetchingCountryCodes();
        this.setPasswordDigits(data.data.attributes.password_digest);
    })



    await fetch(
        `${domain}bx_block_settings/billing_details`,
        {
            method: "GET",
            headers: {
                token: localStorage.getItem('authToken') as string
            },
        }
    )
    .then(response => response.json())
    .then(data => {
        if(data.data.length === 0) {
          this.setBillingAddress('')
          this.setBillingName('')
          this.setBillingLastName('')
          this.setBillingPhone('')
          this.setCountryBilling("United States")
        } else {
          this.setCountryBilling(data.data.attributes.country)
        }

        

        this.setBillingAddress(data.data.attributes.address)
        this.setBillingName(data.data.attributes.first_name)
        this.setBillingLastName(data.data.attributes.last_name)
        this.setBillingPhone(data.data.attributes.full_phone_number)
        
    })
    .then(() => {
    })
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    const showUpgradeModal = (type: string) => {
      if(type === 'upgrade') {
          this.setState({ 
              upgradeModal: true,
              paymentModal: false,
          })
      }

      if(type === 'payment') {
          this.setState({
              upgradeModal: false,
              paymentModal: true,
          })
      }

    }

    const closeUpgradeModal = () => {
      this.setState({
          upgradeModal: false,
          paymentModal: false,
      })
    } 


    const closeSuccessModal = () => {
      this.setState({
        successModal: false
      })
    }

    // Customizable Area Start
    return (
      <>
        {this.state.showRemoveModal &&
          <RemoveModal>
            <Popup>
              <PopupText data-test-id="modalHeadingText">
                Are you sure you want to delete your account?
              </PopupText>
              <PopupButtons>
                <PopupButton onClick={() => this.deleteAccount()} data-test-id="deleteAccountYesBtn">
                  YES
                </PopupButton>
                <PopupButtonSecond onClick={() => this.hideRemoveModal()} data-test-id="hideId">
                  NO
                </PopupButtonSecond>
              </PopupButtons>
            </Popup>
          </RemoveModal>
        }

        {
          this.state.upgradeModal && 
              <UpgradeModal 
                  data-test-id= "closeUpgradeId"
                  showUpgradeModal={showUpgradeModal}
                  closeUpgradeModal={closeUpgradeModal}
              />
        }

        {
          this.state.paymentModal && 
          <PaymentModal 
              showUpgradeModal={showUpgradeModal}
              closeUpgradeModal={closeUpgradeModal}
              data-test-id= "closeUpgradeId"
          />
        }

        {
            this.state.successModal &&
            <ModalSuccessStyled data-modal='modal' style={{zIndex: 99}}>
                {/* Modal popup */}
                <View style={[styles.modal, {marginTop: 200, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#232323'}]}>
                    <TouchableOpacity onPress={() => this.setSuccessModal(false)} data-test-id="successModalCloseBtn">
                        <Image source={closeWhite} style={{marginLeft: 'auto', marginBottom: 40, width: 24, height: 24}} />
                    </TouchableOpacity>
                    
                    <Image source={successSend} style={{width: 107, height: 102, marginLeft: 'auto', marginRight: 'auto'}} />
                    <Typography style={styles.successText}>Your operation is successfully done!</Typography>
                </View>
            </ModalSuccessStyled>
        }


        <Wrapper>
          <NavigationApp 
            navigation={this.props.navigation} 
            showUpgradeModal={showUpgradeModal} 
            backgroundUrl={this.state.backgroundUrl}
            activeSettings={true}
          />
          <UserProfile>
            <Title>
              Settings
            </Title>
            <Tabs>
              <Tab 
                style={{
                  color: this.state.tab === 'edit' ? 'white' : 'rgba(255, 255, 255, 0.75)',
                  borderColor: this.state.tab === 'edit' ? 'rgba(56, 255, 76, 1)' : 'transparent',
                }}
                onClick={() => this.setActiveTab('edit')}
                data-test-id="editTab"
              >
                User Profile
              </Tab>
              <Tab 
                style={{
                  color: this.state.tab === 'glance' ? 'white' : 'rgba(255, 255, 255, 0.75)',
                  borderColor: this.state.tab === 'glance' ? 'rgba(56, 255, 76, 1)' : 'transparent',
                }} 
                onClick={() => this.setActiveTab('glance')}
                data-test-id="glanceTab"
              >
                Billing
              </Tab>
            </Tabs>
            <Content>

              {this.state.tab === 'edit' &&
              <YourAvatar>
                {this.state.file 
                  ? <AvatarWrapperWithImage style={{backgroundImage: `url(${this.state.backgroundUrl})`}}>
                      <InputFile type="file" name="file" id="file" className="inputfile" onChange={(event: any) => this.setFile(event?.target.files[0])} />
                      <EditIcon src={edit} />
                    </AvatarWrapperWithImage>
                  : <AvatarWrapper>
                      <InputFile type="file" name="file" id="file" className="inputfile" onChange={(event: any) => this.setFile(event?.target.files[0])} />
                      <Img src={avatar} />
                      <EditIcon src={edit} />
                    </AvatarWrapper>
                }

                <TextWrapper>
                  <FirstRow data-test-id="yourAvatarText">
                    Your Avatar
                  </FirstRow>
                  <SecondRow>
                    Min 200x200px .PNG or .JPEG
                  </SecondRow>
                </TextWrapper>
              </YourAvatar>
              }
                 
              {this.state.tab === 'edit' &&
                <>
                  <FormFirst>
                    <Left>
                      <Label>First Name</Label>
                      <Input 
                        value={this.state.firstName === "null" ? "" : this.state.firstName} 
                        placeholder="Your first name" 
                        onChange={(event: any) => this.setFirstName(event?.target.value.replace(/[0-9]/g, ''))}
                      />
                    </Left>
                    <Right>
                      <Label>Last Name</Label>
                      <Input 
                        value={this.state.lastName === "null" ? "" : this.state.lastName} 
                        placeholder="Your last name" 
                        onChange={(event: any) => this.setLastName(event?.target.value.replace(/[0-9]/g, ''))}
                      />
                    </Right>
                  </FormFirst>
   
                  <FormSecond>
                    <Label>Email</Label>
                    
                    <Input value={this.state.email} //onChange={(event: any) => this.setEmail(event?.target.value)}
                    />
                  </FormSecond>

                  <FormThird>
                    <Label>Password</Label>
                   
                    <InputPassword value={this.state.passwordDigest} type="password" disabled/>
                    <ChangePasswordBtn onClick={() => this.props.navigation.navigate("ForgotPassword")}>CHANGE PASSWORD</ChangePasswordBtn>
                  </FormThird>

                  <FormSecond>
                    <Label>Phone Number</Label>
                    <ContactInput
                    data-test-id="contactNumberTestId"
                    country={"us"}
                    inputStyle={{borderColor:!this.state.phoneNumError ? "red" : "#ECECEE1A" }}
                    value={this.state.phoneNumber}
                    placeholder={"e.g. +1-212-456-7890"}
                    onChange={(event: string, country: CountryCodeObj) => { this.handleContactNumberChange(event, country) }}
                    inputProps={{
                      required: true
                    }}
                  />
                  </FormSecond>

                  <FormFirst>
                    <Left>
                      <Label>Zip Code</Label>
                      <Input 
                        value={this.state.countryCode === "null" ? "" : this.state.countryCode} 
                        placeholder="XXXX" 
                        onChange={(event: any) => this.setCountryCode(event?.target.value.replace(/\D/g, ''))}
                      />
                    </Left>
                    <Right style={{position: 'relative'}}>
                      <Label>Country</Label>
                      <Flags 
                        setCountry={this.setCountry} 
                        country={this.state.country} 
                      />

                    </Right>            
                  </FormFirst>

                  <SaveChangesBtn data-test-id="saveChangesButton" onClick={() => this.updateUserProfile()}>
                    SAVE CHANGES
                  </SaveChangesBtn>
                  <DeleteUserBtn onClick={() => this.showRemoveModal()} data-test-id="deleteAccountBtn">
                    DELETE MY ACCOUNT
                  </DeleteUserBtn>
                </>
              }

              
              
            </Content>
            {this.state.tab === 'glance' &&
                <BillingWrapper>
                  <Block>
                    <BlockTitle>
                      Payment
                    </BlockTitle>
                    <BlockContent id='payment-data'>

                      <BlockLabel>
                          Cardholder's Name
                      </BlockLabel>
                      <BlockInput 
                        placeholder="John Doe"
                        data-test-id="cardholderName"
                        value={this.state.billingCardholderName}
                        onChange={(event: any) => this.setBillingCardholderName(event.target.value && event.target.value.replace(/[0-9]/g, ''))}  
                        style={{
                          border: this.state.billingCardholderNameError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                        }}
                      />

                      <BlockLabel>
                        Credit Card Number
                      </BlockLabel>
                      <BlockInput 
                        data-test-id="cardNumberInput"
                        placeholder="XXXX XXXX XXXX XXXX"
                        value={this.state.billingCreditCard && this.state.billingCreditCard
                          .replace(/\D/g, '') 
                          .replace(/(.{4})/g, '$1 ').trim()
                        }
                        onChange={(event: any) => {
                          let formattedValue = event.target.value
                          // Ograniczamy do 16 znakow plus 3 spacje
                          if (formattedValue.length > 19) {
                            formattedValue = formattedValue.substring(0, 19);
                          }
                          
                          this.setCreditCardNumber(formattedValue)
                        }}      
                        style={{
                          border: this.state.billingCreditError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                        }}
                      />

                      <RowTwoInputs>

                        <WrapperOnInput>
                          <BlockLabel>
                            Expiry Date
                          </BlockLabel>
                          <BlockInput 
                            data-test-id="expiryId"
                            placeholder="MM / YY" 
                            value={this.state.billingExpiry && this.state.billingExpiry.replace(
                              /[^0-9]/g, '' // Usuwamy wszystko co nie jest cyfra
                            ).replace(
                              /^([1-9]\/|[2-9])$/g, '0$1 / ' // Dodajemy 0 na pocztek je[li jest to pojedyncza cyfra nie bedaca 1
                            ).replace(
                              /^(1{1})([3-9]{1})$/g, '0$1 / $2' // Dodajemy 0 je[li pierwsza cyfra jest 1 a druga jest wieksza ni| 2
                            ).replace(
                              /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1 / $2' // Formatujemy MM / YY
                            )} 
                            onChange={(event: any) => {
                              let formattedValue = event.target.value
                              // Ograniczamy do MM / YY
                              if (formattedValue.length > 4) {
                                formattedValue = formattedValue.substring(0, 7);
                              }
                          
                              this.setBillingExpiryDate(formattedValue)
                            }}
                            style={{
                              border: this.state.billingExpiryError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            }}
                          />
                        </WrapperOnInput>

                        <WrapperOnInput>
                          <BlockLabel>
                            CVV
                          </BlockLabel>
                          <BlockInput 
                            data-test-id="cvvInputBox"
                            placeholder="XXX"
                            value={this.state.billingCvv}
                            onChange={(event: any) => {
                              let formattedValue = event.target.value && event.target.value.replace(/[a-zA-Z]/g, '');
                              // Ograniczamy do 3 znakow
                              if (formattedValue.length > 3) {
                                formattedValue = formattedValue.substring(0, 3)
                              }

                              this.setBillingCvv(formattedValue)
                            }}
                            style={{
                              border: this.state.billingCvvError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            }}
                          />
                        </WrapperOnInput>
                      </RowTwoInputs>




                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockTitle data-test-id="billingAddressText">
                      Billing Address
                    </BlockTitle>
                    <BlockContent>
                      <RowTwoInputs>

                        <WrapperOnInput>
                          <BlockLabel>
                            First Name
                          </BlockLabel>
                          <BlockInput 
                            data-test-id="billingAddressFirstName"
                            placeholder="John"
                            value={this.state.billingName}
                            onChange={(event: any) => this.setBillingName(event.target.value && event.target.value.replace(/[0-9]/g, ''))}
                            style={{
                              border: this.state.billingNameError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            }}
                          />
                        </WrapperOnInput>

                        <WrapperOnInput>
                          <BlockLabel>
                            Last Name
                          </BlockLabel>
                          <BlockInput 
                            placeholder="Doe"
                            value={this.state.billingLastName}
                            onChange={(event: any) => this.setBillingLastName(event.target.value && event.target.value.replace(/[0-9]/g, ''))}
                            style={{
                              border: this.state.billingLastNameError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            }}
                          />
                        </WrapperOnInput>
                      </RowTwoInputs>
                      <RowTwoInputs>
                        <WrapperOnInput style={{position: 'relative'}}>
                          <BlockLabel>
                            Country
                          </BlockLabel>
                          <FlagsBilling 
                            setCountryBilling={this.setCountryBilling}
                            countryBilling={this.state?.countryBilling === '' ? 'United States' : this.state.countryBilling}
                          />
                          
                        </WrapperOnInput>

                        <WrapperOnInput>
                          <BlockLabel>
                          Phone Number
                          </BlockLabel>
                          <BlockInput 
                            placeholder="e.g. +1-212-456-7890"
                            value={this.state.billingPhone}
                            onChange={(event: any) => this.setBillingPhone(event.target.value && event.target.value.replace(/[^0-9+]/g, ''))}
                            style={{
                              border: this.state.billingPhoneError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                            }}
                          />
                        </WrapperOnInput>
                      </RowTwoInputs>

                      <BlockLabel>
                      Address
                      </BlockLabel>
                      <BlockInput 
                        placeholder="e.g 123 First Street"
                        value={this.state.billingAddress}
                        onChange={(event: any) => this.setBillingAddress(event.target.value && event.target.value)}
                        style={{
                          border: this.state.billingAddressError ? '1px solid red' : '1px solid rgba(236, 236, 238, 0.1)',
                        }}
                      />

                    </BlockContent>
                  </Block>
                  <Block>
                    <BlockTitle>
                      Current Plan
                    </BlockTitle>
                    <BlockContent style={{width: '100%'}}>
                        <SubscriptionCard>
                          <SubscriptionCardHeader>
                            <span>{true ? 'Default' : 'GLANCE Premium'}</span>
                            <span>{true ? 'FREE' : '$15 / mo'}</span>  
                          </SubscriptionCardHeader>
                          <span
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 300,
                              lineHeight: '21px',
                              letterSpacing: '0em',
                              color: 'rgba(255, 255, 255, 0.75)',
                              padding: '0 16px',
                              marginTop: '16px',
                              marginBottom: '52px'
                            }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam odio magna, tempus mollis porta vel.
                          </span>
                          <PlanButton onClick={() => this.showUpgradeModal('upgrade')} data-test-id="upgradebuttonId">
                            UPGRADE TO GLANCE PREMIUM  
                          </PlanButton>
                        </SubscriptionCard>
                    </BlockContent>

                  </Block>
                  {(
                    this.state.billingAddressError || 
                    this.state.billingCardholderNameError || 
                    this.state.billingCreditError || 
                    this.state.billingCvvError ||
                    this.state.billingExpiryError ||
                    this.state.billingLastNameError ||
                    this.state.billingPhoneError
                  ) &&
                    <span style={{
                      color: 'rgba(255, 255, 255, 0.75)',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: 400,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center' 
                    }}><img src={errorIcon} style={{width: '20px', height: '20px', marginRight: '8px'}} />Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span>
                  }
                </BillingWrapper>
            }
          </UserProfile>
          
          {this.state.tab === 'glance' &&
            <div style={{
                width: '100%', 
                height: '64px', 
                position: 'fixed', 
                background: 'rgba(19, 19, 20, 1)', 
                bottom: 0, 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <button style={{
                borderRadius: '4px', 
                width: '340px', 
                height: '32px',
                fontFamily: 'Roboto Condensed',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '19px',
                letterSpacing: '0.04em',
                textAlign: 'center',
                background: 'rgba(56, 255, 76, 1)',
                outline: 0,
                border: 0
              }}  
              data-test-id="billingExpId"
              onClick={() => {
                if(this.state.billingExpiry.length === 7) {
                  this.setBillingExpiryError(false)
                } else {
                  this.setBillingExpiryError(true)
                }

                if(this.state.billingAddress.length > 0) {
                  this.setBillingAddressError(false)
                } else {
                  this.setBillingAddressError(true)
                }

                if(this.state.billingCardholderName.length > 0) {
                  this.setBillingCardHolderError(false)
                } else {
                  this.setBillingCardHolderError(true)
                }

                if(this.state.billingCreditCard.length === 19) {
                  this.setBillingCreditCardError(false)
                } else {
                  this.setBillingCreditCardError(true)
                }

                if(this.state.billingCvv.length === 3) {
                  this.setBillingCvvError(false)
                } else {
                  this.setBillingCvvError(true)
                }

                if(this.state.billingLastName.length > 0) {
                  this.setBillingLastNameError(false)
                } else {
                  this.setBillingLastNameError(true)
                }

                if(this.state.billingName.length > 0) {
                  this.setBillingNameError(false)
                } else {
                  this.setBillingNameError(true)
                }

                if(this.state.billingPhone) {
                  this.setBillingPhoneError(false)
                } else {
                  this.setBillingPhoneError(true)
                }

                if(
                  !this.state.billingExpiryError && 
                  !this.state.billingAddressError &&
                  !this.state.billingCardholderNameError &&
                  !this.state.billingCreditError &&
                  !this.state.billingCvvError &&
                  !this.state.billingLastNameError &&
                  !this.state.billingNameError &&
                  !this.state.billingPhoneError 
                ) {
                  this.saveCard();
                  this.saveBilling();
                }
              }}>
                SAVE & UPDATE
              </button>
            </div>
          }
        </Wrapper>
      </>
      
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const RemoveModal = styled.div`
width: 100%;
height: 100%; 
background-color: rgba(0,0,0,0.5);
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
z-index: 1;
position: fixed;
`
const ModalSuccessStyled = styled.div`
width: 100%;
height: 100%; 
backgroundColor: rgba(0,0,0,0.5);
display: flex;
flexDirection: row;
justifyContent: center;
alignItems: center;
zIndex: 1;
position: fixed;
`


const Popup = styled.div`
width: 486px;
background-color: #1f1f21;
padding-top: 0;
padding-bottom: 12px;
padding-left: 16px;
padding-right: 16px;
z-index: 2;
margin-top: 200px;
border-radius: 8px;

`

const PopupText = styled.p`
  color: white;
  font-size: 24px;
  margin-right: 50px;
  margin-left: 50px;
  font-family: Roboto;
  text-align: center;
`

const PopupButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const PopupButton = styled.button`
  border: 0;
  outline: none;
  border-radius: 8px;
  padding: 10px 16px;
  color: rgba(56, 255, 76, 1);
  background-color: #313132;
  margin-right: 10px;
  cursor: pointer;
`

const PopupButtonSecond = styled(PopupButton)`
  color: rgba(0, 0, 0, 1);
  background-color: rgba(56, 255, 76, 1);
  margin-right: 0;
  margin-left: 10px;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(31, 31, 33, 1);
  position: relative;
`

const UserProfile = styled.div`
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  min-height: 900px;
  background-color: rgba(31, 31, 33, 1);
  padding-top: 24px;
`

const Title = styled.span`
  font-size: 32px;
  font-family: Roboto Condensed;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
`

const Content = styled.div`
  max-width: 432px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`

const YourAvatar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AvatarWrapper = styled.div`
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background-color: rgba(43, 43, 46, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const AvatarWrapperWithImage = styled(AvatarWrapper)`
  background-size: cover;
  background-position: center;
`

const InputFile = styled.input`
  position: absolute;
  background: transparent;
  border: 2px solid red;
  color: transparent;
  width: 116px;
  height: 116px;
  opacity: 0;
`

const Img = styled.img`
  width: 58px;
  height: 58px;
`

const EditIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 83px;
  left: 89px;
`

const TextWrapper = styled.div`
  margin-left: 28px;
  display: flex;
  flex-direction: column;
`

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 32px;
  cursor: pointer;
  border-bottom: 1px solid #303032;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
`

const Tab = styled.div`
  width: fit-content;
  color: white;
  font-family: Roboto;
  text-align: center;
  border-bottom: 2px solid rgba(56, 255, 76, 1);
  padding: 10px 20px;
`

const FirstRow = styled.span`
  font-family: Roboto Condensed;
  font-size: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
`

const SecondRow = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 8px;
`

const FormFirst = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`

const Left = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`

const Right = styled.div`
  width: 46%;
  display: flex;
  flex-direction: column;
`
const Label = styled.label`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: white;
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  background-color: rgba(43, 43, 46, 1);
  border: 1px solid rgba(236, 236, 238, 0.1);
  border-radius: 4px;
  color: white;
  padding-left: 12px;
  :placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`

const FormSecond = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

const FormThird = styled(FormSecond)`
  position: relative;
`

const ChangePasswordBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  color: rgba(196, 237, 200, 1);
  font-family: Roboto Condensed;
  font-size: 14px;
  bottom: 10px;
  right: 12px;
`

const InputPassword = styled(Input)`
  padding-right: 150px;
`

const SaveChangesBtn = styled.button`
  font-family: Roboto Condensed;
  font-size: 20px;
  font-weight: 500;
  color: rgba(31, 31, 33, 1);
  text-align: center;
  height: 48px;
  background-color: rgba(56, 255, 76, 1);
  border: 0;
  border-radius: 4px;
  margin-top: 56px;
  cursor: pointer;
  &:active {
    background-color: rgba(56, 255, 76, 0.4);
  }
`

const DeleteUserBtn = styled.button`
  border: 0;
  background-color: transparent;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: center;
  margin-top: 38px;
  cursor: pointer;
  color: rgba(196, 237, 200, 1);
  margin-bottom: 40px;
`

const BillingWrapper = styled.form`
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Block = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
`

const BlockTitle = styled.span`
  font-family: Roboto Condensed;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`

const BlockContent = styled.div`
  width: 90%;
  margin-top: 24px;
`

const BlockLabel = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 8px;
`

const BlockInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(236, 236, 238, 0.1);
  border-radius: 4px;
  background-color: #2b2b2e;
  color: white;;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px; 
  :placeholder {
    color: #808082;
  }
`

const RowTwoInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const WrapperOnInput = styled.div`
  width: 48%;
`

const SubscriptionCard = styled.div`
  width: 100%;
  height: 233px;
  border-radius: 8px;
  background: #28282A;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SubscriptionCardHeader = styled.div`
  height: 64px;
  width: 93%;
  background-color: rgba(56, 255, 76, 1);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(31, 31, 33, 1);
  padding: 0 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const PlanButton = styled.div`
  width: 93%;
  border-radius: 4px;
  background: #39393B;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: center;
  color: rgba(56, 255, 76, 1);
  margin-right: auto;
  margin-left: auto;
  outline: none;
  border: 0;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const styles = {
  modal: {
      width: 640,
      height: 308,
      backgroundColor: '#1f1f21',
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 16,
      paddingRight: 16,
      zIndex: 2,
      marginTop: -100,
  },
  successText: {
      color: '#FFF',
      fontFamily: 'Roboto',
      fontSize: 24,
      marginTop: 22,
      marginLeft: 'auto',
      marginRight: 'auto'
  },
navigation: {

},
contactus: {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
},
rightSide: {
  
},
formWrapper: {
  width: 664,
  marginRight: 'auto',
  marginLeft: 'auto',
},
title: {
  fontFamily: 'Roboto Condensed',
  color: 'white',
  fontWeight: 400,
  fontSize: 48
},
text1: {
  color: 'rgba(255, 255, 255, 0.75)',
  marginBottom: 12
},
text2: {
  color: 'rgba(255, 255, 255, 0.75)',
},
form: {
  width: '100%',
  marginTop: 40
},
label: {
  color: 'white',
  marginBottom: 8
},
boxSocial: {
  width: 56,
  height: 56,
  backgroundColor: 'rgba(31, 31, 33, 1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
},
socialImg: {
  width: 32,
  height: 32
}
};

const ContactInput = styled(PhoneInput)({
  "&.react-tel-input .form-control": {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(43, 43, 46, 1)",
    border: "1px solid rgba(236, 236, 238, 0.1)",
    borderRadius: "4px",
    color: "#fff",
    paddingLeft: "12px",
  },
  "&.react-tel-input .flag-dropdown": {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  "& .flag-dropdown.open .selected-flag:hover": {
    backgroundColor: "transparent",
    display:"none"
  },
  ".special-label":{
    display:"none"
  },
  "&:placeholder": {
    color: "rgba(255, 255, 255, 0.4)"
  }
}
);
// Customizable Area End
