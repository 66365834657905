import styled from 'styled-components/native';
import { imgLogo } from '../../blocks/dashboard/src/assets';

export const ContainerContent = styled.View`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #1f1f21;
  display: flex;
  flex-direction: column;
`

export const Title = styled.Text`
    margin-top: 24px;
    font-family: Roboto Condensed;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 40px;
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const AddGlance = styled.View`
    height: 210px;
    width: 90%;
    display: flex;
    margin-left: 40px;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
`

export const Box = styled.View`
    width: 316px;
    padding: 16px 12px;
    border: 2px solid rgba(236, 236, 238, 0.1);
    border-radius: 4px;
    background: rgba(217, 217, 217, 0.04);
`

export const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
`

export const Dot = styled.View`
    width: 8px;
    height: 8px;
    background-color: rgba(56, 255, 77, 1);
    border-radius: 50%;
    margin-right: 8px;
`

export const Img = styled.View`
    width: 100%;
    height: 146px;
    background-color: rgba(94, 94, 94, 1);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Logo = styled.View`
    background-image: url(${imgLogo});
    width: 68px;
    height: 40px;
`

export const Form = styled.View`
    width: 316px;
    height: 100%;
    displat: flex;
    flex-direction: column;
    margin-left: 32px;
`

export const FormText = styled.Text`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-bottom: 8px;
`

export const FormTextSecond = styled(FormText)`
    margin-top: 24px;
    margin-bottom: 8px;
`

export const Input = styled.TextInput`
    border: 1px solid #3f3f41;
    border-radius: 4px;
    background-color: #2b2b2e;
    color: white;
    height: 40px;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    :placeholder {
        color: rgba(255, 255, 255, 0.4);
    }
`

export const ButtonSubmit = styled.TouchableOpacity`
    width: 100%;
    background-color: rgba(56, 255, 76, 1);
    border-radius: 4px;
    height: 32px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const TextBtn = styled.Text`
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.04em;
    text-align: center;
`

export const CreateSelectionSetBtn = styled.TouchableOpacity`
    padding: 8px 12px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(236, 236, 238, 0.1);
    border-radius: 4px;
    width: fit-content;
    margin-right: 40px;
`

export const CreateSelectionSetBtnText = styled.Text`
    color: rgba(196, 237, 200, 1);
    font-family: Roboto Condensed;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const ContentSelectionSection = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const BtnImg = styled.Image`
    width: 24px;
    height: 24px;
`

export const DefaultSelectionSet = styled.View`
    margin-top: 18px;
    margin-left: 40px;
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const DefaultSelectionSetText = styled.Text`
    color: white;
    font-size: 20px;
`

export const Icons = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const EditIcon = styled.Image`
    width: 20px;
    height: 20px;
`

export const DropdownIcon = styled.Image`
    width: 24px;
    height: 24px;
    margin-left: 16px;
    transform: rotate(0deg);
`

export const DropdownIconRotate = styled.Image`
    width: 24px;
    height: 24px;
    margin-left: 16px;  
    transform: rotate(180deg);
` 

export const BoxStyle = styled.View`
    width: 316px;
    height: 218px;
    border: 1px solid rgba(217, 217, 217, 0.04);
    border-radius: 4px;
    background: rgba(217, 217, 217, 0.04);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-bottom: 20px;
`

export const BoxStyleSection = styled.View`
    margin-top: 26px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    flex-wrap: wrap;
    width: 100vw;
`

export const BoxStyleAdd = styled.Image`
    width: 32px;
    height: 32px;
    margin-left: 6px;
`

