import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingText: string;
  copyright: string;
  noConnection: string;
  makeSureConnection: string;
  isDisconnected: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            });
            setTimeout(() => {
              this.goToHome();
            }, configJSON.timeout);
          }).catch(() => this.setState({ isDisconnected: true }) )
        }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      loadingText: configJSON.loadingText,
      copyright: configJSON.copyright,
      noConnection: configJSON.noConnection,
      makeSureConnection: configJSON.makeSureConnection,
      isDisconnected: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    this.handleConnectionChange();
    window.addEventListener('offline', this.handleConnectionChange);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  // Customizable Area End
}
