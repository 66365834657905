import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { imgLogo, errorImage } from "./assets";
import { themeStyles, imageStyles, boxStyles, checkboxStyles } from "./theme";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={themeStyles}>
        <Container maxWidth={false} style={{display: 'flex', minHeight: '900px', height: '100vh'}}>
          <Box sx={boxStyles}>
            <Box
              sx={{
                maxWidth: '480px',
              }}
            >
              <img
                src={imgLogo}
                alt={this.state.logoAlt}
                style={{margin: '64px 0 120px 0', width: '262px', height: '40px'}}
              />
              <Box>
                <Typography variant="h4" component="h2">
                  {this.labelTitle}
                </Typography>
                <Box sx={{fontSize: '16px', fontWeight: '400', color: 'rgba(255, 255, 255, 0.75)', marginBottom: '20px'}}>
                  <Typography variant="subtitle1" component="div">
                    {this.state.labelHeader} {/*UI Engine::From Sketch*/}
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: '430px', width: '100%', paddingTop: "20px" }}>
                  <Box sx={{display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{fontSize: '16px', fontWeight: '500', color: '#ffffff', marginBottom: '5px'}}>
                      <Typography variant="subtitle1" component="div">
                        {this.state.placeHolderEmail}
                      </Typography>
                    </Box>
                    <Input
                      data-test-id="txtInputEmail"
                      placeholder={"Enter your email"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)}
                      style={{border: `1px solid ${this.state.showEmailError ? '#ED1C24' : 'rgba(236, 236, 238, 0.1)'} `}}
                      endAdornment={ this.state.showEmailError &&
                        <InputAdornment position="end">
                          <img src={errorImage} />
                        </InputAdornment>
                      }
                    />
                    {this.state.showEmailError && 
                      <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '12px'}}>
                        <img src={errorImage} style={{marginRight: '8px'}}/>
                        <Typography 
                          data-test-id="emailErrorMessage"
                          component="div"
                          style={{
                            color: 'rgba(255, 255, 255, 0.75)',                        
                            fontSize: '16px',
                            fontWeight: 400,
                          }}  
                        >
                          {this.state.errorEmailNotValid}
                        </Typography>
                      </Box>
                    }
                  </Box>
                <Box sx={{ padding: "20px 0px", display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{fontSize: '16px', fontWeight: '500', color: '#ffffff', marginBottom: '10px'}}>
                    <Typography variant="subtitle1" component="div">
                      {this.state.placeHolderPassword}
                    </Typography>
                  </Box>
                  <Input
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Enter your password"}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    style={{border: `1px solid ${this.state.showPasswordError ? '#ED1C24' : 'rgba(236, 236, 238, 0.1)'} `}}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                          style={{marginRight: '2px'}}
                        >
                          {this.state.enablePasswordField ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {this.state.showPasswordError && 
                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '12px'}}>
                      <img src={errorImage} style={{marginRight: '8px'}}/>
                      <Typography 
                        component="div"
                        style={{
                          color: 'rgba(255, 255, 255, 0.75)',                        
                          fontSize: '16px',
                          fontWeight: 400,
                        }}  
                      >
                        {this.state.errorPasswordNotValid}
                      </Typography>
                    </Box>
                  }
                </Box>
                <Box sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',
                  maxWidth: '430px',
                  marginBottom: '22px'
                }}>
                  <Box sx={checkboxStyles} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      checked={this.state.checkedRememberMe}
                      style={{padding: 0}}
                    />{" "}
                    <Box style={{padding: 0, fontFamily: "Roboto", marginLeft: 10}}> 
                      Keep me logged in
                    </Box>
                  </Box>
                  <Box
                    data-test-id={"btnForgotPassword"}
                    sx={{color: 'rgba(255, 255, 255, 0.40)', fontSize: '16px', fontWeight: '500'}}
                    onClick={() => this.goToForgotPassword()}
                    style={{cursor: 'pointer', fontFamily: "Roboto"}}
                  >
                    Forgot password
                  </Box>
                </Box>
                
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5px 0px",
                  }}
                >
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    style={{
                      width: '100%', 
                      borderRadius: '4px', 
                      backgroundColor: '#38FF4C',
                      boxShadow: '0px 0px 16px 0px rgba(56, 255, 76, 0.16)',
                      fontSize: '20px',
                      color: '#1F1F21',
                      fontWeight: 500
                    }}
                    onClick={this.showLoginValidation}
                  >
                    Sign In {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', margin: '16px 0 144px 0'}}>
                  <Typography 
                    component="div"
                    style={{
                      color: '#ffffff',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      marginRight: '10px'
                    }}  
                  >
                    Don't have an account? 
                  </Typography>
                  <Typography 
                    component="div" 
                    style={{
                      color: '#38FF4C',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      cursor: 'pointer'
                    }}
                    onClick={() => this.goToSignup()}
                  >
                    Sign up
                  </Typography>
                </Box> 
                
              </Box>
              </Box>
              </Box>
              <Typography 
                    component="div" 
                    style={{
                      color: 'rgba(255, 255, 255, 0.40)',
                      fontSize: '16px',
                      fontWeight: 400,
                      marginBottom: '32px',
                      marginRight: '27px' 
                    }}
                  >
                    Copyright © 2023 GLANCE LED. All rights reserved
                  </Typography>
            
          </Box>
          <Box sx={imageStyles}></Box>
        </Container>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
