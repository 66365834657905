import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  CheckBox,
  ImageBackground
} from "react-native";
import { imageDecor, logo, imageTip, imageError, imageCheck } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            <View style={styles.root}>
              <View style={styles.leftSide}>
                <View style={styles.contentLeftSide}>
                  <Image
                    source={logo}
                    style={{margin: '64px 0 120px 0', width: '262px', height: '40px'}}
                  />
                  <View style={styles.form}>
                    <Text style={{color: '#fff', fontSize: 48, fontFamily: 'Roboto Condensed'}}>Get Started Now</Text>
                    <Text style={{
                      color: 'rgba(255, 255, 255, 0.75)', 
                      fontSize: 15, 
                      width: 470, 
                      letterSpacing: -1, 
                      fontWeight: '400'
                    }}>Welcome to GLANCE LED, create account to start your experience.</Text>
                    <Text style={{marginTop: 48, color: '#fff', fontSize: 16}}>Email</Text>
                    <TextInput
                      testID={"txtInputEmail"}
                      style={[
                        styles.inputWeb, 
                        //@ts-ignore
                        {borderColor: this.state.emailBorderColor}
                      ]}
                      placeholder='example@gmail.com'
                      {...this.txtInputEmailWebPrpos} //Merge Engine::From BDS - {...this.testIDProps}
                    />
                    {this.state.correctEmailFormat === 'correct' &&
                      <Image
                        testID={"imgEnablePasswordField"}
                        source={imageCheck}
                        style={{width: '20px', height: '20px', marginTop: '-30px', marginBottom: '10px', marginLeft: '328px'}}
                      />
                    }
                    {this.state.correctEmailFormat === 'notCorrect' &&
                      <View style={{display: 'flex', flexDirection: 'row', marginTop: '5px', alignItems: 'center'}}>
                        <Image
                          testID={"imgEnablePasswordField"}
                          source={imageError}
                          style={{width: '20px', height: '20px', marginRight: '8px'}}
                        />
                        <Text style={{color: 'rgba(255, 255, 255, 0.75)'}}>Invalid email</Text>
                      </View>
                      
                    }                  
                    <Text style={{marginTop: 16, color: '#fff', fontSize: 16}}>Password</Text>
                    <View style={[
                      styles.inputWeb, 
                      styles.inputWebPassword, 
                      {borderColor: this.state.pBorderColor}
                    ]}>  
                      <TextInput
                        testID={"txtInputPassword"}
                        placeholder='Enter your password'
                        // @ts-ignore
                        style={[styles.inputWebPassword]}
                        onFocus={() => this.onFocusInput()}
                        {...this.txtInputPasswordProps}
                      />

                      <TouchableOpacity
                        testID={"btnPasswordShowHide"}
                        style={styles.passwordShowHide}
                        {...this.btnPasswordShowHideProps}
                      >
                        <Image
                          testID={"imgEnablePasswordField"}
                          style={styles.imgPasswordShowhide}
                          {...this.imgEnablePasswordFieldProps}
                        />
                      </TouchableOpacity>
                    </View>

                    <Text>{this.state.passwordHelperText}</Text>
                    <Text style={{marginTop: 16, color: '#fff', fontSize: 16}}>Repeat Password</Text>
                    <View style={[
                      styles.inputWeb, 
                      styles.inputWebPassword, 
                      {borderColor: this.state.pConfirmBorderColor}
                    ]}>
                      <TextInput
                        testID={"txtInputConfirmPassword"}
                        // @ts-ignore
                        style={[styles.inputWebPassword]}
                        placeholder='Repeat your password'
                        {...this.txtInputConfirmPasswordProps}
                      />

                      <TouchableOpacity
                        testID={"btnConfirmPasswordShowHide"}
                        style={styles.passwordShowHide}
                        {...this.btnConfirmPasswordShowHideProps}
                      >
                        <Image
                          testID={"imgEnableRePasswordField"}
                          style={styles.imgPasswordShowhide}
                          {...this.imgEnableRePasswordFieldProps}
                        />
                      </TouchableOpacity>
                    </View>

                    {(
                      this.state.showPasswordTip && 
                      !this.state.showPasswordErrors && 
                      this.state.correctPassword === '' && 
                      this.state.correctConfirmPassword === '' 
                    ) &&
                      <View style={styles.passwordContain}>
                        <Text style={{color: 'rgba(255, 255, 255, 0.4)', fontSize: 18}}>Your password must contain</Text>
                        <Text style={styles.tip}>
                          <Image source={imageTip} style={styles.imageTip}/>
                          At least one capital and lowercase letter
                        </Text>
                        <Text style={styles.tip}>
                          <Image source={imageTip} style={styles.imageTip}/>
                          At least one number
                        </Text>
                        <Text style={styles.tip}>
                          <Image source={imageTip} style={styles.imageTip}/>
                          Minimum character length is 8 characters
                        </Text>
                      </View>
                    }
                    
                    {this.state.showPasswordErrors &&
                      <View style={[styles.passwordContain, styles.passwordErrors]}>
                        <Text style={styles.tip}>
                          <Image source={imageError} style={styles.imageTip}/>
                          Passwords doest not match.
                        </Text>
                        <Text style={styles.tip}>
                          <Image source={imageError} style={styles.imageTip}/>
                          At least one capital and lowercase letter.
                        </Text>
                        <Text style={styles.tip}>
                          <Image source={imageError} style={styles.imageTip}/>
                          At least one number.
                        </Text>
                        <Text style={styles.tip}>
                          <Image source={imageError} style={styles.imageTip}/>
                          Minimum character length is 8 characters
                        </Text>
                      </View>
                    }
                    
                    {
                      ((
                          (this.state.correctEmailFormat !== 'notCorrect' && this.state.correctEmailFormat !== '') &&
                          (this.state.correctConfirmPassword !== 'notCorrect' && this.state.correctConfirmPassword !== '') &&
                          (this.state.correctPassword !== 'notCorrect' && this.state.correctPassword !== '') 
                        ) &&
                        !this.state.checkedAgree
                      ) && 
                      <View style={styles.agreeError}>
                        <Text style={styles.tip}>
                          <Image source={imageError} style={[styles.imageTip, {marginTop: '-10px'}]}/>
                          You have to agree with Privacy Policy and Terms and Conditions to Sign up.
                        </Text>
                      </View>
                    }
                    
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 16,
                        marginBottom: 30,
                        width: 360,
                        maxWidth: '360px'
                      }}
                    >
                      <CheckBox
                        value={this.state.checkedAgree}
                        onValueChange={() => this.setAgree()}
                        style={{width: '20px', height: '20px', marginRight: '8px'}}
                        testID={"checkboxAgree"}
                      />
                      <View style={{width: '330px'}}>
                        <Text style={{color: 'rgba(255, 255, 255, 0.75)', fontWeight: '400'}}>
                          I have read and agree with 
                          <Text
                            testID={"btnLegalPrivacyPolicy"}
                            style={[styles.btnLegalPrivacyPolicy, {color: '#fff', fontWeight: '600', marginLeft: '5px', marginRight: '5px'}]}
                            {...this.btnLegalPrivacyPolicyProps}
                          >
                            {this.labelLegalPrivacyPolicy}
                          </Text> 
                            and 
                            <Text
                              testID={"btnLegalTermsAndCondition"}
                              style={[styles.btnLegalTermsAndCondition, {color: '#fff', fontWeight: '600', marginLeft: '5px'}]}
                              {...this.btnLegalTermsAndConditionProps}
                            >
                              {this.labelLegalTermCondition}
                            </Text>
                          </Text>
                      </View>
                    </View>

                    <TouchableOpacity
                      testID="btnSignUp"
                      style={{
                        backgroundColor: '#38FF4C', 
                        height: '48px', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        borderRadius: 4,
                        //@ts-ignore
                        cursor: 
                          (!this.state.checkedAgree || 
                          this.state.showPasswordErrors || 
                          this.state.correctEmailFormat === 'notCorrect' ||
                          this.state.password === '' ||
                          this.state.reTypePassword === '' ||
                          this.state.email === '') 
                            ? 'not-allowed' 
                            : 'pointer'
                      }}
                      disabled={
                        !this.state.checkedAgree || 
                        this.state.showPasswordErrors || 
                        this.state.correctEmailFormat === 'notCorrect' ||
                        this.state.password === '' ||
                        this.state.reTypePassword === '' ||
                        this.state.email === ''
                      }
                      onPress={() => this.createAccount()}
                    >
                      <Text style={{color: '#1F1F21', fontWeight: '500', fontSize: 20}}>SIGN UP</Text>
                    </TouchableOpacity>

                    <Text style={{color: 'rgba(255, 255, 255, 0.75)', marginTop: 16}}>
                      Already have an account? 
                      <Text 
                        style={{color: '#38FF4C', marginLeft: '5px'}} 
                        onPress={() => this.goToSignin()}
                        testID={"redirectToSignin"}
                      >Sign in</Text>
                    </Text>
                  </View>
                  <Text style={[
                    {color: 'rgba(255, 255, 255, 0.4)', marginTop: (
                      this.state.showPasswordTip && 
                      !this.state.showPasswordErrors && 
                      this.state.correctPassword === '' && 
                      this.state.correctConfirmPassword === '') || this.state.showPasswordErrors ? 0 : '125px'}
                  ]}>Copyright © 2023 GLANCE LED. All rights reserved</Text>
                </View>
                
              </View>
              <ImageBackground source={imageDecor} resizeMode="cover" style={styles.rightSide}></ImageBackground>
            </View>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    width: '100%',
  },

  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '900px',
    height: '100vh',
    fontFamily: 'Roboto'
  },

  leftSide: {
    width: '50%',
    backgroundColor: '#1F1F21',
    fontFamily: "Roboto-Medium",
    paddingTop: 64,
    paddingBottom: 32
  },

  contentLeftSide: {
    maxWidth: '360px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  form: {
    paddingTop: 56,
    paddingBottom: 9,
    color: '#fff',
    fontSize: 16
  },

  inputWeb: {
    width: '100%',
    borderWidth: 1,
    backgroundColor: '#2B2B2E',
    height: 40,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 4,
    marginTop: 8,
    color: '#fff',
  },

  inputWebPassword: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#fff',
  },

  passwordContain: {
    marginTop: 16,
    marginBottom: 16,
    color: 'rgba(255, 255, 255, 0.75)'
  },

  tip: {
    display: 'flex',
    marginTop: '8px',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.75)'
  },

  imageTip: {
    width: '20px',
    height: '20px',
    marginRight: '8px'
  },

  passwordErrors: {
    justifyContent: 'center'
  },

  agreeError: {
    display: 'flex',
    marginTop: '8px',
    color: 'rgba(255, 255, 255, 0.75)',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  rightSide: {
    width: '50%',
  },

  tipsShowCopyright: {
    marginTop: 0
  },

  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 20, width: 20 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});
