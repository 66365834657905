import React from 'react'
import {
    Text,
    View,
    Image,
    TouchableOpacity
} from "react-native";
import { 
    ContainerContent, 
    Title, 
    SelectionInput,
    Wrapper,
    SelectionTab,
    Tab,
    ImageTab,
    TextTab,
    TabsStockCrypto,
    Elements,
    Element,
    ElementText,
    ElementImage,
    ElementStock,
    ElementImageStock,
    BottomBar,
    BottomBarWrapper,
    ButtonSelected,
    TextSelected,
} from './StyleAddSelectionSet';
import { imgApp, imgNews, imgSearch, imgSocial, imgSport, imgStock, imgClose, imgRemove, imgAddWhite } from '../../blocks/dashboard/src/assets';
import NavigationApp from './NavigationApp';
import DashboardController, { SearchedLanguage, SelectionSetItem } from '../../blocks/dashboard/src/DashboardController.web';
import UpgradeModal from './UpgradeModal'
import PaymentModal from './PaymentModal'
import StockModal from './StockModal';
import { domainSend } from './domain';
import styled from 'styled-components/native';
import { BulkUploadModal } from './BulkUploadModal';
import { Box, Modal, Typography, styled as materialStyled } from '@material-ui/core';


let dataTest: any = []
const dummyData = [
    {name: 'Dashboard', url: 'Dashboard', key: 0},
    {name: 'Add Glance', url: 'AddGlancePage', key: 1},
    {name: 'Settings', url: 'CustomizableUserProfile', key: 2},
    {name: 'User Profile', url: 'CustomizableUserProfile', key: 3},
    {name: 'Billing ', url: 'CustomizableUserProfileBilling', key: 4},
    {name: 'Manage your Glance-Led account', url: 'CustomizableUserProfile', key: 5},
    {name: 'Terms and conditions', url: 'TermsConditionsDetail', key: 6},
    {name: 'Privacy policy ', url: 'TermsConditionsDetail', key: 7},
    {name: 'Contact us', url: 'Contactus', key: 8}
  ]

export default class AddSelectionSet extends DashboardController {
    async componentDidMount(): Promise<void> {
        this.fetchTradingStock();
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.actions !== prevState.actions) {
          this.fetchElements();
        } else if(this.state.setSearchAction !== prevState.setSearchAction && this.state.selectionsetSearchVal.length > 2){
            this.selectionSetSearch(this.state.selectionsetSearchVal);
        }
    }

    fetchTradingStock() {
        this.changeStockCrpyto('stock')

        const domain = domainSend();
        
        fetch(
            `${domain}/bx_block_cfintegrationdatabaseforglancepanelbackend2/Stocks`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(data.stock_data)
            this.setTradingStockElements(data.stock_data)
            dataTest = data.stock_data
            this.closeStockModal()
        })
    }

    fetchTradingCrypto() {
        this.changeStockCrpyto('crypto')

        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_cfintegrationdatabaseforglancepanelbackend2/Crypto?search_query=rv`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            this.setTradingStockElements(data.crypto_data)
            dataTest = data.crypto_data
        })
    }

    fetchStandings() {
        this.changeGroup('standings')

        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_cfintegrationdatabaseforglancepanelbackend2/Standings`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(data)
            this.setStandings(data.standings_data)
            dataTest = data
            this.closeStockModal()
        })
    }

    fetchGames() {
        this.changeGroup('games')

        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_cfintegrationdatabaseforglancepanelbackend2/Games`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(data)
            this.setGames(data.sport_data)
            dataTest = data
            this.closeStockModal()
        })
    }

    fetchTeams() {
        this.changeGroup('teams')

        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_cfintegrationdatabaseforglancepanelbackend2/Teams`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            this.setTeams(data.team_data)
            dataTest = data
            this.closeStockModal()
        })
    }

    filterQuery = (value: string) => {
        const searchQuery = value.toLowerCase();
        const result = dummyData.filter((element: any) => element.name.toLowerCase().includes(searchQuery));
        console.log('RESULT:::::::: !!!!!: ' +result)
        // this.setFilteredData(result)
        // this.setInputLength(value.length)
    }

    fetchApps() {
        this.changeGroup('apps')

        const domain = domainSend();
        
        fetch(
            `${domain}bx_block_bulk_uploading/attachments`,
            {
                method: "GET",
                headers: {
                    token: localStorage.getItem('authToken') as string
                },
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(JSON.stringify(data))
            const fileUrls: any[] = [];

            // Iterujemy przez każdy obiekt w tablicy data
            // data.forEach((item: any) => {
            // // Iterujemy przez każdy plik w obiekcie attributes.files
            //     item.data?.attributes?.files.forEach((file: any) => {
            //         // Dodajemy file_url do tablicy fileUrls
            //         fileUrls.push(domain+file.file_url);
            //     });
            // });

        
            data.forEach((item: any) => {
                if (item.file_url) {
                            fileUrls.push(item.file_url);
                }
            });
            this.setImagesUrls(fileUrls);
        })
    }

    removeItem = (id: number) => {
        const domain = domainSend();

        fetch(
            `${domain}bx_block_glance/selection_set_items/${id}`,
            {
                method: "DELETE",
                headers: {
                  token: localStorage.getItem('authToken') as string,
                  "Content-Type": "application/json",
                },
                // body: JSON.stringify(data)
            }
          )
          .then(response => response.json())
          .then(data => {
            // fetchResult()
            this.setActions(this.state.actions+1)
            this.setSearchAction();
          })
    }

    fetchFreeText() {
        this.changeGroup('text')

        // const domain = domainSend();
        // const url = window.location.href;
        // const lastSegment = url?.split("/").pop();
        
        // fetch(
        //     `${domain}bx_block_glance/selection_set_items/${lastSegment}`,
        //     {
        //         method: "GET",
        //         headers: {
        //             token: localStorage.getItem('authToken') as string,
        //             "Content-Type": "application/json",
        //         },
        //     }
        // )
        // .then(response => response.json())
        // .then(data => {
        //     console.log("Wybrane  elementyyyy:" +JSON.stringify(data.data))
        //     // this.setChoosenElements(data.data)
        //     // dataTest = data.stock_data
        //     // this.closeStockModal()
        // })
    }
    
    saveFreeText() {
        const domain = domainSend();
        const lastSegment = this.state.selectionSetId

        const data = {
            selection_type: 'freetext',
            key: this.state.freeTextColor[0],
            name: this.state.freeText,
            selection_set_id: lastSegment
        }

        fetch(
            `${domain}bx_block_glance/selection_set_items`,
            {
                method: "POST",
                headers: {
                  token: localStorage.getItem('authToken') as string,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }
          )
          .then(response => response.json())
          .then(data => {
            this.setActions(this.state.actions +1);
            this.setFreeText("");
          })
    }

    fetchSocial() {
        this.changeGroup('social')

        // const domain = domainSend();
        
        // fetch(
        //     `${domain}/bx_block_cfintegrationdatabaseforglancepanelbackend2/team_search?search_query=nfl`,
        //     {
        //         method: "GET",
        //         headers: {
        //             token: localStorage.getItem('authToken') as string
        //         },
        //     }
        // )
        // .then(response => response.json())
        // .then(data => {
        //     this.setTeams(data.team_data)
        //     dataTest = data
        // })
    }

    saveCountdown() {
        const domain = domainSend();
        const lastSegment = this.state.selectionSetId

        const data = {
            selection_type: "countdown",
            key: this.state.countdownColor[0],
            name: this.state.countdownDate+this.state.countdownName,
            selection_set_id: lastSegment 
        }
        
        fetch(
            `${domain}bx_block_glance/selection_set_items`,
            {
                method: "POST",
                headers: {
                    token: localStorage.getItem('authToken') as string,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }
            )
            .then(response => response.json())
            .then(()=>{
                this.setActions(this.state.actions +1);
                this.setCountdownDate("");
                this.setCountdownName("");
            })
    }

    render() {
        return (
            <View style={{
                display: "flex",
                flexDirection: 'column',
                width: '100%',
                minHeight: '900px'
            }}>
                {
                    this.state.upgradeModal && 
                        <UpgradeModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }

                {
                    this.state.paymentModal && 
                        <PaymentModal 
                            showUpgradeModal={this.showUpgradeModal}
                            closeUpgradeModal={this.closeUpgradeModal}
                        />
                }

                {
                    this.state.stockModal &&
                    <StockModal 
                        closeStockModal={this.closeStockModal} 
                        activeSection={this.state.group}
                        activeTab={this.state.tab}
                        fetchedElements={this.fetchedElementsForStockModal()}
                        refresh={this.fetchElements}
                        incrementAction={this.setActions}
                        actions={this.state.actions}
                        selectionSetId={this.state.selectionSetId}
                    />
                }
                <ContainerContent>
                    <NavigationApp navigation={this.props.navigation} showUpgradeModal={this.showUpgradeModal} />
                    <Wrapper>
                        <Title data-test-id="chooseSelectionTypeText">
                            Choose Selection Type
                        </Title>

                        <View style={{position: 'relative'}}>
                            <SelectionInput placeholder="Selection Search.." value={this.state.selectionsetSearchVal} onChangeText={this.selectionSetSearchOnChange} data-test-id="selectionSearchTestID"/>
                            <Image
                                style={{position: 'absolute', width: 14, height: 14, top: 37, left: 16}}
                                source={imgSearch}
                            />
                        </View>
                        <SelectionTab>
                            <Tab 
                                onClick={() => this.changeGroup('trading')} 
                                style={{
                                    backgroundColor: this.state.group === 'trading' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'trading' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgStock}
                                />
                                <TextTab
                                    style={{
                                        color: this.state.group === 'trading' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                    }}
                                >Trading</TextTab>
                            </Tab>
                            <Tab
                                onClick={() => this.fetchStandings()} 
                                style={{
                                    backgroundColor: this.state.group === 'standings' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'standings' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgSport}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'standings' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Standings</TextTab>
                            </Tab>
                            <Tab
                                onClick={() => this.fetchGames()} 
                                style={{
                                    backgroundColor: this.state.group === 'games' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'games' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'games' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Games</TextTab>
                                
                            </Tab>
                            <Tab
                                onClick={() => this.fetchTeams()} 
                                style={{
                                    backgroundColor: this.state.group === 'teams' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'teams' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgNews}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'teams' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Teams</TextTab>
                                
                            </Tab>
                            <Tab
                                data-test-id="socialTabId"
                                onClick={() => this.changeGroup('social')} 
                                style={{
                                    backgroundColor: this.state.group === 'social' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'social' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgSocial}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'social' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Social</TextTab>
                                
                            </Tab>
                            <Tab
                                onClick={() => this.fetchApps()} 
                                style={{
                                    backgroundColor: this.state.group === 'apps' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'apps' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'apps' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Applications</TextTab>
                                
                            </Tab>

                            <Tab
                                onClick={() => this.fetchFreeText()} 
                                style={{
                                    backgroundColor: this.state.group === 'text' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'text' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'text' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Free Text</TextTab>
                                
                            </Tab>

                            <Tab
                                onClick={() => this.changeGroup('countdown')} 
                                style={{
                                    backgroundColor: this.state.group === 'countdown' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'countdown' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'countdown' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Countdown</TextTab>
                                
                            </Tab>

                            <Tab
                                data-test-id="languageTabButton"
                                onClick={() => this.changeGroup("language")}
                                style={{
                                    backgroundColor: this.state.group === 'language' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'language' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab

                                    source={imgApp}
                                />
                                <TextTab 
                                 style={{
                                    color: this.state.group === 'language' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Languages</TextTab>
                            </Tab>
                            <Tab
                                data-test-id="beachTabButton"
                                onClick={() => this.getBeaches()}
                                style={{
                                    backgroundColor: this.state.group === 'beachs' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'beachs' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'beachs' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>Beaches</TextTab>
                            </Tab>
                            <Tab
                                onClick={() => this.changeGroup('newss')}
                                style={{
                                    backgroundColor: this.state.group === 'newss' ? '#28282a' : 'transparent',
                                    color: this.state.group === 'newss' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}
                            >
                                <ImageTab
                                    source={imgApp}
                                />
                                <TextTab style={{
                                    color: this.state.group === 'newss' ? 'white' : 'rgba(255, 255, 255, 0.75)'
                                }}>News</TextTab>
                            </Tab>

                        </SelectionTab>

                        {this.state.group === 'trading' && <>
                            <TabsStockCrypto style={{borderBottomColor: 'rgba(255, 255, 255, 0.08)', borderBottomWidth: 1}}>

                                <StyledCursor style={{cursor: 'pointer', paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, 
                                    color: this.state.tab === 'stock' ? 'white' : 'rgba(255, 255, 255, 0.75)',
                                    borderBottomColor: this.state.tab === 'stock' ? 'rgba(56, 255, 76, 1)' : 'transparent',
                                    borderBottomWidth: 1
                                }}
                                    onClick={() => this.fetchTradingStock()}
                                >Stock</StyledCursor>
                                <StyledCursor style={{cursor: 'pointer', paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, 
                                    color: this.state.tab === 'crypto' ? 'white' : 'rgba(255, 255, 255, 0.75)',
                                    borderBottomColor: this.state.tab === 'crypto' ? 'rgba(56, 255, 76, 1)' : 'transparent',
                                    borderBottomWidth: 1
                                }}
                                    onClick={() => this.fetchTradingCrypto()}
                                >Crypto</StyledCursor>
                            </TabsStockCrypto>
                        </>
                        }

                      
                        {/* Content of sections */}
                        <Elements>
                            {this.checkRenderValues() &&
                                <SelectionSetOuterBox>
                                    {this.state?.selctionSetSearch?.map((element: SelectionSetItem) => {
                                        return (
                                            <Element key={element.id}>
                                                <SelectionSetNameText data-test-id={`selctionSetSearch_${element.id}`}>
                                                    {element.attributes.name}
                                                </SelectionSetNameText>
                                                <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                    <ElementImage source={imgRemove} />
                                                </TouchableOpacity>
                                            </Element>
                                        )
                                    })}
                                </SelectionSetOuterBox>
                            }
                            
                            {this.renderStocks() && <>
                                {this.state?.choosenElements?.map((element: any) => {
                                    if(element.attributes.selection_type === 'stock') {
                                        return <Element>
                                                    <ElementText>
                                                        {element.attributes.name}
                                                    </ElementText>
                                                    <TouchableOpacity onPress={() => this.removeItem(element.id)}>
                                                        <ElementImage source={imgRemove}/>
                                                    </TouchableOpacity>
                                                </Element>
                                    }
                                     
                                })}
                                    
                                </> 
                            }

                            {this.renderCrypto() && <>
                                {this.state?.choosenElements?.map((element: any) => {
                                    if(element.attributes.selection_type === 'crypto') {
                                        return <Element>
                                                    <ElementText>
                                                    {element.attributes.name}
                                                    </ElementText>
                                                    <TouchableOpacity onPress={() => this.removeItem(element.id)}>
                                                        <ElementImage source={imgRemove}/>
                                                    </TouchableOpacity>
                                                </Element>
                                    }
                                })}
                                    
                                </>
                            }

                            {this.state.group === 'standings' && <>
                                {this.state?.choosenElements?.map((element: any) => {
                                        if(element.attributes.selection_type === 'standing') {
                                            return <Element>
                                                        <ElementText>
                                                        {element.attributes.key}
                                                        </ElementText>
                                                        <TouchableOpacity onPress={() => this.removeItem(element.id)}>
                                                            <ElementImage source={imgRemove}/>
                                                        </TouchableOpacity>
                                                    </Element>
                                        }
                                    })}
                                    
                                </>
                            }

                            {this.state.group === 'games' && <>
                                {this.state?.choosenElements?.map((element: any) => {
                                        if(element.attributes.selection_type === 'game') {
                                            return <Element>
                                                        <ElementText>
                                                        {element.attributes.key}
                                                        </ElementText>
                                                        <TouchableOpacity onPress={() => this.removeItem(element.id)}>
                                                            <ElementImage source={imgRemove}/>
                                                        </TouchableOpacity>
                                                    </Element>
                                        }
                                    })}
                                    
                                </>
                            }

                            {this.state.group === 'teams' && <>
                                {this.state?.choosenElements?.map((element: any) => {
                                        if(element.attributes.selection_type === 'team') {
                                            return <Element>
                                                        <ElementText>
                                                        {element.attributes.key}
                                                        </ElementText>
                                                        <TouchableOpacity onPress={() => this.removeItem(element.id)}>
                                                            <ElementImage source={imgRemove}/>
                                                        </TouchableOpacity>
                                                        
                                                    </Element>
                                        }
                                    })}
                                    
                                </>
                            }
                            
                            {this.state.group === "language" && <>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'learn new lang' &&
                                        <Element>
                                            <ElementText>
                                                {element.attributes.key}
                                            </ElementText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </>
                            }

                            {this.state.group === "newss" && <>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'news' &&
                                        <Element>
                                            <ElementText>
                                                {element.attributes.key}
                                            </ElementText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </>
                            }

                            {this.state.group === "beachs" && <>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'beach' &&
                                         <Element>
                                            <ElementText>
                                                {element.attributes.key}
                                            </ElementText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </>
                            }

                            {this.state.group === "text" && <SelectionSetOuterBox>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'freetext' &&
                                        <Element>
                                            <SelectionSetNameText>
                                                {element.attributes.name}
                                            </SelectionSetNameText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </SelectionSetOuterBox>
                            }

                            {this.state.group === "social" && <SelectionSetOuterBox>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'social' &&
                                        <Element>
                                            <SelectionSetNameText>
                                                {element.attributes.name}
                                            </SelectionSetNameText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </SelectionSetOuterBox>
                            }
                    
                            {this.state.group === "countdown" && <SelectionSetOuterBox>
                                {this.state?.choosenElements?.map((element: SelectionSetItem) => {
                                    return element.attributes.selection_type === 'countdown' &&
                                        <Element>
                                            <SelectionSetNameText>
                                                {element.attributes.name?.substring(10,element.attributes.name.length)}
                                            </SelectionSetNameText>
                                            <TouchableOpacity onPress={() => this.removeItem(+element.id)}>
                                                <ElementImage source={imgRemove} />
                                            </TouchableOpacity>
                                        </Element>
                                })}
                            </SelectionSetOuterBox>
                            }
    
                            {(this.state.group !== 'social' && this.state.group !== 'utils' && this.state.group !== 'apps'
                                && this.state.group !== 'text' && this.state.group !== 'countdown' && this.state.group !== "language"
                            ) &&
                                <ElementStock 
                                        style={{cursor: 'pointer'}} 
                                        onClick={() => this.showStockModal()}
                                    >
                                        <ElementText data-test-id="selectTextHeading">
                                            Select {this.state.group == "trading" ? this.state.tab : this.state.group == "newss" ? "News" : this.state.group == "beachs" ?"Beach" :this.state.group}
                                        </ElementText>
                                        <ElementImageStock source={imgAddWhite}/>
                                </ElementStock>
                            }

                            {(this.state.group === "language") &&
                                <ElementStock
                                    data-test-id="selectLanguageButton"
                                    onClick={() => this.getLanguages()}
                                >
                                    <ElementText data-test-id="selectLanguageText">
                                        Select Language
                                    </ElementText>
                                    <ElementImageStock source={imgAddWhite} />
                                </ElementStock>
                            }

                            {this.state.group === 'apps' &&
                                <>
                                    {this.state.imagesUrls?.map((element: any) => 
                                   
                                        <View>
                                           <Image
                                                key={element}
                                                style={{ width: 100, height: 100, marginRight: 20, marginBottom: 20 }} // Dostosuj wymiary obrazka do swoich potrzeb
                                                source={{ uri: element }} // Źródło obrazka z adresu URL
                                            />         
                                        </View>
                                    )}
                                    <BulkUploadModal 
                                        fetchApps={() => this.fetchApps()}
                                    />
                                </>
                                
                            }

                            {(this.state.group === 'social') &&
                                <div>
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}
                                    data-test-id="enterYoutubelinkText"
                                    >Enter Youtube link:</Text>
                                    <input 
                                        data-test-id="youtubeId"
                                        maxLength={200} value={this.state.socialDataYt} onChange={(event) => this.setSocialYt(event.target.value)}
                                        style={{
                                            width: '100%', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10, marginBottom: 20
                                        }}
                                    />
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter Tik-Tok link:</Text>
                                    <input 
                                        data-test-id="tiktokButtonId"
                                        maxLength={200} value={this.state.socialDataTt} onChange={(event) => this.setSocialTt(event.target.value)}
                                        style={{
                                            width: '100%', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10, marginBottom: 20
                                        }}
                                    />
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter Instagram link:</Text>
                                    <input 
                                      data-test-id="instagramButtonId"
                                      maxLength={200} value={this.state.socialDataIn} onChange={(event) => this.setSocialIn(event.target.value)}
                                        style={{
                                            width: '100%', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10, marginBottom: 20
                                        }}
                                    />
                                    <SaveButton 
                                        data-test-id="saveSocailButton"
                                        onClick={() => this.saveSocailButton()}
                                    >
                                        SAVE
                                    </SaveButton>
                            </div>
                            }

                            {(this.state.group === 'text') &&
                                <div>
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter your FreeText:</Text>
                                    <input 
                                        maxLength={200} value={this.state.freeText} onChange={(event) => this.setFreeText(event.target.value)}
                                        style={{
                                            width: '100%', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10
                                        }}
                                    />
                                    <div>
                                        <select style={{marginTop: 20}} onChange={(event) => this.setFreeTextColor(event.target.value)} value={this.state.freeTextColor}>
                                            <option value="red">Red</option>
                                            <option value="green">Green</option>
                                            <option value="yellow">Yellow</option>
                                            <option value="cyan">Cyan</option>
                                            <option value="white">White</option>
                                            <option value="blue">Blue</option>
                                        </select>
                                    </div>
                                    <SaveButton 
                                        disabled={this.state.freeText.length <= 0}
                                        onClick={() => this.saveFreeText()}
                                    >
                                        SAVE
                                    </SaveButton>
                                </div>
                                
                            }

                            {(this.state.group === 'countdown') &&
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter Countdown text:</Text>
                                    <input 
                                        maxLength={200} value={this.state.countdownName} onChange={(event) => this.setCountdownName(event.target.value)}
                                        style={{
                                            width: '370px', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10, marginBottom: 20
                                        }}
                                    />
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter Countdown date:</Text>
                                    <input 
                                        type="date"
                                        value={this.state.countdownDate} onChange={(event) => this.setCountdownDate(event.target.value)}
                                        style={{
                                            width: '370px', border: '1px solid #3f3f41', height: 40, borderRadius: '4px', background: '#2b2b2e', 
                                            color: 'white', paddingLeft: 16, paddingRight: 16, marginTop: 10, marginBottom: 20
                                        }}
                                    />
                                    <Text style={{marginTop: 20, color: 'white', fontFamily: 'Roboto'}}>Enter Countdown color:</Text>
                                    <div>
                                        <select style={{marginTop: 20}} onChange={(event) => this.setCountdownColor(event.target.value)} value={this.state.countdownColor}>
                                            <option value="red">Red</option>
                                            <option value="green">Green</option>
                                            <option value="yellow">Yellow</option>
                                            <option value="cyan">Cyan</option>
                                            <option value="white">White</option>
                                            <option value="blue">Blue</option>
                                        </select>
                                    </div>
                                    <SaveButton 
                                        disabled={this.state.countdownName.length <= 0}
                                        onClick={() => this.saveCountdown()}
                                    >
                                        SAVE
                                    </SaveButton>
                            </div>
                            }
                        </Elements>

                    </Wrapper>

                    {/* <BottomBar>
                        <BottomBarWrapper>
                            <TextSelected>
                                {['ATVI', 'AAL', 'TSLA'].map(element => <Text style={{color: 'rgba(196, 237, 200, 1)', marginRight: 4, fontSize: 18}}>{element},</Text>)}
                            </TextSelected>
                            <ButtonSelected>
                                <Text style={{color: 'rgba(31, 31, 33, 1)', fontFamily: 'Roboto Condensed'}}>ADD SELECTED 3 SELECTIONS</Text>
                            </ButtonSelected>
                        </BottomBarWrapper>
                    </BottomBar> */}                    
                    <CustomLanguageModal
                        open={this.state.languageModal}
                        onClose={() => this.closeLanguageModal()}
                    >
                        <LanguageModalContent>
                            <LanguageModalHeader>
                                <Box>
                                    <LanguageModalHeaderText>Choose </LanguageModalHeaderText>
                                    <LanguageModalHeaderHighlightedText data-test-id="learnNewLanguageTest">Learn New Language</LanguageModalHeaderHighlightedText>
                                </Box>
                                <LanguageModalCloseButton onClick={() => this.closeLanguageModal()}>
                                    <LanguageModalCloseIcon src={imgClose} />
                                </LanguageModalCloseButton>
                            </LanguageModalHeader>
                            <LanguageDataContent>
                                <SearchLanguageInput placeholder="Search..." value={this.state.searchLanguageValue} onChange={this.searchLanguage} data-test-id="languageSearchBox" />
                                {
                                    this.state.searchLanguageValue.length > 0 ?
                                        this.state.searchedLanguages.map((languageData: SearchedLanguage) => {
                                            const { language, languageIndex } = languageData;
                                            return (
                                                <SelectLanguageBox
                                                    style={{ backgroundColor: this.state.selectedLanguageId === languageIndex ? "#38FF4C" : "transparent" }}
                                                    onClick={() => this.selectLanguageOnClick(languageIndex)}>
                                                    <SelectLanguageText
                                                        style={{ color: this.state.selectedLanguageId === languageIndex ? "#232323" : "#FFFFFF68" }}
                                                        data-test-id={`searchedLanguageText_${language}`}>{language}</SelectLanguageText>
                                                </SelectLanguageBox>
                                            )
                                        })
                                        :
                                        this.state.languages.map((language: string, languageIndex: number) => {
                                            return (
                                                <SelectLanguageBox style={{ backgroundColor: this.state.selectedLanguageId - 1 === languageIndex ? "#38FF4C" : "transparent" }}
                                                    data-test-id={`selectLanuageButton_${languageIndex}`} onClick={() => this.selectLanguageOnClick(languageIndex + 1)}>
                                                    <SelectLanguageText style={{ color: this.state.selectedLanguageId - 1 === languageIndex ? "#232323" : "#FFFFFF68" }}>
                                                        {language}
                                                    </SelectLanguageText>
                                                </SelectLanguageBox>
                                            )
                                        })
                                }
                            </LanguageDataContent>
                            <LanguageModalFooter>
                                <LanguageModalFooterCloseButton onClick={this.closeLanguageModal} data-test-id="backToSelectionsButton">BACK TO SELECTIONS</LanguageModalFooterCloseButton>
                                <SaveSelectionButton onClick={this.setLearnLanguage} data-test-id="addLanuageSelectionSetButton">ADD SELECTION</SaveSelectionButton>
                            </LanguageModalFooter>
                        </LanguageModalContent>
                    </CustomLanguageModal>
                </ContainerContent>
            </View>
        )
    }
}

const StyledText = styled.Text`
color: #FFF;
text-align: center;
font-family: Roboto Condensed;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.64px;
margin-left: 10px;
`

const StyledCursor = styled.Text`
    cursor: pointer;
`

const StyledOverflow = styled.View`
    overflowY: auto;
`

const CustomLanguageModal = materialStyled(Modal)({
    height:"100%",
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
});

const LanguageModalContent = materialStyled(Box)({
    height:"382px",
    width:"432px",
    backgroundColor:"#1e1e20",
    padding:"10px"
});

const LanguageModalHeader = materialStyled(Box)({
    height:"70px",
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderBottom:"1px solid #FFFFFF14"
});

const LanguageModalHeaderText = materialStyled("span")({
    fontFamily: "Roboto Condensed",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "28.13px",
    color:"#c7c7c7"
});

const LanguageModalHeaderHighlightedText = materialStyled("span")({
    fontFamily: "Roboto Condensed",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "28.13px",
    color:"#cbeaca"
});

const LanguageModalCloseButton = materialStyled("button")({
    cursor:"pointer",
    border:"none",
    height:"25px",
    width:"25px",
    backgroundColor:"transparent"
});

const LanguageModalCloseIcon = materialStyled("img")({
    height:"100%",
    width:"100%"
});

const LanguageModalFooter = materialStyled(Box)({
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end",
    borderTop:"1px solid #FFFFFF14",
    padding:"15px 0",
    columnGap:"15px",
    boxSizing:"border-box"
});

const LanguageModalFooterCloseButton = materialStyled("button")({
    backgroundColor:"#FFFFFF14",
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18.75px",
    letterSpacing: "0.04em",
    color: "#FFFFFF66",
    border:"none",
    width: "202px",
    height: "35px",
    padding: "8px 24px 8px 24px",
    borderRadius: "4px",
    cursor:"pointer"
});

const SaveSelectionButton = materialStyled("button")({
    width: "160px",
    height: "35px",
    padding: "8px 24px 8px 24px",
    borderRadius: "4px",
    backgroundColor: "#38FF4C",
    border: "none",
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18.75px",
    color: "#1F1F21",
    cursor:"pointer"
});

const SearchLanguageInput = materialStyled("input")({
    width:"100%",
    color:"#ffffff66",
    backgroundColor:"transparent",
    border: "1px solid #ECECEE1A",
    margin:"10px 0",
    padding:"10px",
    boxSizing:"border-box",
    borderRadius:"4px",
    "& :placeholder":{
        color:"#ffffff66"
    }
});

const LanguageDataContent =  materialStyled(Box)({
    height:"250px",
    width:"100%",
    overflowY:"scroll"
});

const SelectLanguageBox = materialStyled(Box)({
    margin:"5px 0",
    width:"100%",
    padding:"10px",
    boxSizing:"border-box",
    cursor:"pointer"
});

const SelectLanguageText = materialStyled(Typography)({
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
});  

const SelectionSetOuterBox = materialStyled(Box)({
    display:"flex",
    width:"100%",
    flexWrap:"wrap"
});

const SelectionSetNameText = materialStyled(Typography)({
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "0em",
    marginLeft: "16px",
    color: "rgba(255, 255, 255, 0.75)",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
});

const SaveButton = materialStyled("button")({
        marginTop: 20, 
        background: '#38ff4c', 
        color: 'black',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        borderRadius: 4, 
        border: "none", 
        padding: '10px 20px', 
        fontWeight: 500, 
        fontFamily: 'Roboto',
        cursor:"pointer",
        '&:disabled': {
            backgroundColor: '#38FF4C'
        }
}); 
