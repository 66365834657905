import React from "react";

// Customizable Area Start
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  Image,
  KeyboardAvoidingView,
} from "react-native";
import { Formik } from "formik";
import {imageLogo, imageTip, errorIcon} from './assets'
import {
  ContainerContent, 
  LeftSide, 
  ContentLeftSide, 
  LogoNewPassword,
  Title, 
  SubtitleNewPassword, 
  PasswordShowHide,
  ImgPasswordShowhide,
  InputLabel, 
  InputStyle, 
  ButtonGreenNewPassword,
  ButtonGreenText,
  Copyright,
  DecorImage
} from '../../../components/src/StyleForgotPassword'

import * as Yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          <ContainerContent>
              <LeftSide>
                <ContentLeftSide>
                  <LogoNewPassword source={imageLogo}/>
                  <View>
                    <Title>
                      New Password
                    </Title>
                    <SubtitleNewPassword>
                      Enter a new password for email {sessionStorage.getItem('emailForgotPassword')}
                    </SubtitleNewPassword>
                   
                    <Formik
                      initialValues={{ password: "", confirmPassword: "" }}
                      validationSchema={Yup.object().shape(this.state.passwordSchema)}
                      validateOnMount={true}
                      validateOnChange={true}
                      onSubmit={(values, actions) => {
                        this.submitNewPassword(values);
                        actions.setSubmitting(false);
                      }}
                      data-testid="changePasswordForm"
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        errors,
                        setFieldTouched,
                        touched,
                      }) => (
                        <View>
                          <View>
                            <InputLabel>New Password</InputLabel>
                            <InputStyle
                              testID={"txtInputPassword"}
                              style={{borderColor: this.state.pBorderColor}}
                              placeholder={this.placeholderIsPassword}
                              onChangeText={handleChange("password")}
                              onBlur={
                                () => {
                                  setFieldTouched("password")
                                  this.setBorderColor('password', errors.password ? 'error' : 'correct') 
                                }
                              }
                              secureTextEntry={
                                !!this.state.enablePasswordField
                              }
                            />

                            <PasswordShowHide
                              onPress={() => {
                                this.setState({
                                  enablePasswordField:
                                    !this.state.enablePasswordField,
                                });
                              }}
                              testID="passwordShowHideIcon"
                            >
                              <ImgPasswordShowhide
                                source={
                                  this.state.enablePasswordField
                                    ? this.imgPasswordVisible
                                    : this.imgPasswordInVisible
                                }
                              />
                            </PasswordShowHide>
                          </View>
                          <View>
                            {(touched.password && errors.password) && (
                              <Text style={[styles.tip, styles.err]}>
                                <Image source={errorIcon} style={styles.imageTip}/>
                                {errors.password}
                              </Text>
                            )}
                            <Text>
                              {this.state.passwordRules}
                            </Text>
                          </View>

                          <View
                            style={{marginTop: 30}}
                          >
                            <InputLabel>Confirm New Password</InputLabel>
                            <InputStyle
                              testID={"txtInputConfirmPassword"}
                              style={{borderColor: this.state.pConfirmBorderColor}}
                              placeholder={this.placeholderIsReTypePassword}
                              onChangeText={handleChange("confirmPassword")}
                              onBlur={
                                () => {
                                  setFieldTouched("confirmPassword")
                                  this.setBorderColor('confirm', errors.confirmPassword ? 'error' : 'correct') 
                                }
                              }
                              secureTextEntry={
                                !!this.state.btnConfirmPasswordShowHide
                              }
                            />

                            <PasswordShowHide
                              onPress={() => {
                                this.setState({
                                  btnConfirmPasswordShowHide:
                                    !this.state.btnConfirmPasswordShowHide,
                                });
                              }}
                              testID="confirmPasswordShowHide"
                            >
                              <ImgPasswordShowhide
                                source={
                                  this.state.btnConfirmPasswordShowHide
                                    ? this.imgPasswordVisible
                                    : this.imgPasswordInVisible
                                }
                              />
                            </PasswordShowHide>
                          </View>
                          <View>
                            {(touched.confirmPassword && errors.confirmPassword) && (
                              <Text style={[styles.tip, styles.err]}>
                                <Image source={errorIcon} style={styles.imageTip}/>
                                {errors.confirmPassword}
                              </Text>
                            )}
                          </View>

                          <View style={styles.passwordContain}>
                            <Text style={{color: 'rgba(255, 255, 255, 0.4)', fontSize: 18}}>Your password must contain</Text>
                            <Text style={styles.tip}>
                              <Image source={imageTip} style={styles.imageTip}/>
                              At least one capital and lowercase letter
                            </Text>
                            <Text style={styles.tip}>
                              <Image source={imageTip} style={styles.imageTip}/>
                              At least one number
                            </Text>
                            <Text style={styles.tip}>
                              <Image source={imageTip} style={styles.imageTip}/>
                              Minimum character length is 8 characters
                            </Text>
                          </View>
                          <ButtonGreenNewPassword
                            testID={"btnGetOtpForEmailButtonSuccess"}
                            onPress={() => handleSubmit()}
                          >
                            <ButtonGreenText>
                              SET NEW PASSWORD
                            </ButtonGreenText>
                          </ButtonGreenNewPassword>
                        </View>
                      )}
                    </Formik>
                  </View>          
                  <Copyright>Copyright © 2023 GLANCE LED. All rights reserved</Copyright>
                </ContentLeftSide>
              </LeftSide>
              <DecorImage></DecorImage>
            </ContainerContent>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  containerWeb: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },



  passwordContain: {
    marginTop: 36,
    marginBottom: 36,
    color: 'rgba(255, 255, 255, 0.75)'
  },

  tip: {
    display: 'flex',
    marginTop: 8,
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.75)'
  },

  err: {
    marginTop: 20,
  },

  imageTip: {
    width: 20,
    height: 20,
    marginRight: 8
  },

  inputWrapper: {
    
  },
});
// Customizable Area End
